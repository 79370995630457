import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import Logo from '../../assets/images/AZ_Logo_negative.svg';
import Button from '../../components/Button';
import { ANMELDEN_URL, PROFILE_URL, ROOT_URL } from '../../constants';
import { userActions } from '../../store/actions';
import './GlobalHeader.scss';

export const GlobalHeader = ({ actions }) => {
  const history = useHistory();
  const location = useLocation();
  const [t] = useTranslation();

  const redirectToHomePage = () => {
    if (location.pathname !== PROFILE_URL) {
      history.push(ROOT_URL);
    }
  };

  const signOut = async () => {
    try {
      localStorage.clear();
      await actions.logout();
      const newWindow = window.open(
        process.env.REACT_APP_REDIRECT_LOGOUT_PAGE,
        '_self',
      );
      newWindow.opener = null; // Prevents tabnabbing (https://cheatsheetseries.owasp.org/cheatsheets/HTML5_Security_Cheat_Sheet.html#tabnabbing)
    } catch (error) {
      localStorage.clear();
    }
  };

  const isTextVisible =
    location.pathname !== ANMELDEN_URL && location.pathname !== PROFILE_URL;
  const isButtonVisible = location.pathname !== ANMELDEN_URL;
  return (
    <div className="global-header">
      <div className="global-header-wrapper">
        <img
          className="global-header-logo"
          alt="logo"
          src={Logo}
          onClick={redirectToHomePage}
        />
        {isTextVisible && (
          <div className={'global-header-title'}>{t('global.header.text')}</div>
        )}
        {isButtonVisible && (
          <Button onClick={signOut} color="white" type="outline">
            {t('global.header.button')}
          </Button>
        )}
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        ...userActions,
      },
      dispatch,
    ),
  },
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalHeader);
