import * as Sentry from '@sentry/react';
import {
  doc,
  setDoc,
  collection,
  addDoc,
  onSnapshot,
  query,
} from 'firebase/firestore';

import { db } from '../firebaseConfig';

const COLLECTION_NAME = 'questionnaireApp';
const SUBCOLLECTION_NAME = 'bookings';

/**
 * @method pushBooking
 * @param {object} booking -booking
 * @param {string} uid - user id
 * @summary save user booking
 * @returns nothing
 */

// eslint-disable-next-line import/prefer-default-export
export const pushBooking = async (booking, uid) => {
  try {
    if (booking.phone) {
      const questionnaireAppRef = doc(db, COLLECTION_NAME, uid);
      await setDoc(
        questionnaireAppRef,
        {
          phone: booking.phone,
        },
        { merge: true },
      );
    }
    delete booking.phone;
    const bookingsRef = collection(
      db,
      `${COLLECTION_NAME}/${uid}/${SUBCOLLECTION_NAME}`,
    );
    await addDoc(bookingsRef, booking);
  } catch (err) {
    Sentry.captureException(err, {
      method: 'pushBooking',
      booking,
      userId: uid,
    });
    throw err;
  }
};

export const onQuestionnaireDocSnapshot = (uid, callback) => {
  const questionnaireAppRef = doc(db, COLLECTION_NAME, uid);
  const q = query(questionnaireAppRef);
  return onSnapshot(q, callback);
};
