import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import Actions from '../../../components/Actions';
import SelectAgileCoach from '../../../components/SelectAgileCoach';
import useSelectAgileCoach from './UseSelectAgileCoach';

const Page3 = ({ onBack, onNext, selectedCoachId }) => {
  // TODO: Inspect why hard-coded 456
  const selectAgileCoachProps = useSelectAgileCoach(456, selectedCoachId);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="HeadlineH4" component="h1" gutterBottom>
        Bitte geben Sie an, mit welchen Agile Master oder Agile Coach der
        Allianz Sie den Workshop durchführen werden.
      </Typography>
      <Typography>
        Hinweis: Bitte klären Sie im Vorfeld ab, mit welchem Agile Master oder
        Coach Sie und Ihr Team den Workshop durchführen werden und wählen Sie
        die entsprechende Person dann hier aus.
      </Typography>
      <SelectAgileCoach
        onResult={onNext}
        sx={{ width: 600, marginTop: 4, maxHeight: 450 }}
        {...selectAgileCoachProps}
      />
      <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
        <Actions hasBackButton onBackButtonClick={() => onBack()} />
      </Box>
    </Box>
  );
};

Page3.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  selectedCoachId: PropTypes.string,
};

export default Page3;
