/* eslint-disable import/prefer-default-export */
import { commonTypes } from '../actionTypes';

export const setLocale = (locale) => ({
  type: commonTypes.SET_LOCALE,
  payload: locale,
});

export const setLocaleFulfilled = (locale) => ({
  type: commonTypes.SET_LOCALE_FULFILLED,
  payload: locale,
});
