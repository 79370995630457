import { Box, Typography, Stack } from '@mui/material';
import format from 'date-fns/format';
import de from 'date-fns/locale/de';
import addHours from 'date-fns/setHours';
import PropTypes from 'prop-types';
import React from 'react';

import Actions from '../../../components/Actions';
import BookingSummary from '../../../components/BookingSummary';
import { FORMAT_ONLINE } from '../Page4';

export const Page6 = ({ data, onBack, onNext }) => {
  return (
    <>
      <Stack sx={{ width: '100%' }}>
        <Typography
          component="h2"
          variant="h2HeadlineGrey"
          sx={{
            marginBottom: 2,
          }}
        >
          Bitte prüfen Sie Ihre Reservierung
        </Typography>
        <BookingSummary
          data={[
            {
              label: 'Workshop',
              value: data.name,
            },
            {
              label: 'Sparte',
              value: data.branch,
              onEdit: () => onBack(1),
            },
            {
              label: 'Ort',
              value: data.location.name,
              onEdit: () => onBack(2),
            },
            {
              label: 'Agile Coach / Agile Master',
              value: `${data.coach.lastname}, ${data.coach.firstname}`,
              onEdit: () => onBack(3),
            },
            {
              label: 'Termin',
              value: format(
                addHours(new Date(data.date), data.time),
                "eeee, d. MMMM yyyy 'um' HH:mm 'Uhr'",
                {
                  locale: de,
                },
              ),
              onEdit: () => onBack(4),
            },
            {
              label: 'Format des Workshops',
              value:
                data.format.value === FORMAT_ONLINE
                  ? data.format.label
                  : `${data.format.label}, Raum ${data.room}`,
              onEdit: () => onBack(4),
            },
            {
              label: 'Teilnehmer',
              value: data.participants.length,
              onEdit: () => onBack(5),
            },
          ]}
        />
      </Stack>
      <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
        <Actions
          hasBackButton
          onBackButtonClick={() => onBack(5)}
          hasConfirmButton
          onConfirmButtonClick={onNext}
        />
      </Box>
    </>
  );
};

Page6.pageProps = {
  data: PropTypes.array.isRequired,
  onBack: PropTypes.func,
  onNext: PropTypes.func.isRequired,
};

export default Page6;
