import React from 'react';

import Section1 from './Components/Section1/Section1';
import Section2 from './Components/Section2/Section2';
import Section3 from './Components/Section3/Section3';
import Section4 from './Components/Section4/Section4';
import Section5 from './Components/Section5/Section5';
import './Exploitation.scss';
import Subtitle from './Subtitle';

const Exploitation = () => {
  return (
    <div className="wrapper-exploitation">
      <div className="exploitation">
        <Section1 />
        <Subtitle
          h2="Unser Why"
          span="Unser Umfeld verändert sich und wir wollen uns weiterentwickeln"
        />
        <Section2 />
        <Subtitle h2="Unsere Ziele" span="Anpassungsfähigkeit durch Agilität" />
        <Section3 />
        <Subtitle
          h2="Die agilen Sechs"
          span="Wie werden wir anpassungsfähiger?"
        />
        <Section4 />
        <Subtitle h2="Prozess" span="Unterstützungsangebote auf drei Ebenen" />
        <Section5 />
      </div>
    </div>
  );
};
export default Exploitation;
