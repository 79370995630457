import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

import { getUserSalutationId } from '../../../../utils';
import EmailInput from './ParticipantInput/EmailInput';
import FirstNameInput from './ParticipantInput/FirstNameInput';
import LastNameInput from './ParticipantInput/LastNameInput';
import ParticipantDataProvider from './ParticipantInput/ParticipantDataProvider';
import SalutationInput from './ParticipantInput/SalutationInput';
import { ParticipantsListHeader } from './participantsList.components';

export const emptyParticipantData = {
  salutationId: '',
  firstName: '',
  lastName: '',
  email: '',
};

const ParticipantsList = ({ form }) => {
  const theme = useTheme();
  const [t] = useTranslation();

  const participantsFieldArray = useFieldArray({
    name: 'participants',
    control: form.control,
  });

  return (
    <FormProvider {...form}>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: 'auto 1fr 1fr 1fr auto',
          gridAutoRows: '50px',
          // Last 5 items make a whole row
          '& > *:nth-last-child(-n+5)': {
            opacity: '0.6',
          },
          '& > *[aria-invalid="true"]': {
            background: theme.palette.common.invalid,
          },
          '& > *[aria-invalid="false"]': {
            background: 'none',
          },
        }}
      >
        <ParticipantsListHeader pl={2}>
          {t('form.salutation')}
        </ParticipantsListHeader>
        <ParticipantsListHeader>{t('form.firstName')}</ParticipantsListHeader>
        <ParticipantsListHeader>{t('form.lastName')}</ParticipantsListHeader>
        <ParticipantsListHeader>{t('form.email')}</ParticipantsListHeader>
        <ParticipantsListHeader />
        {participantsFieldArray.fields.map((field, index) => {
          return (
            <ParticipantDataProvider
              key={field.id}
              index={index}
              participantsFieldArray={participantsFieldArray}
            >
              <SalutationInput />
              <FirstNameInput />
              <LastNameInput />
              <EmailInput />
              {participantsFieldArray.fields.length - 1 !== index ? (
                <Typography
                  id={`participants.${index}.remove`}
                  aria-invalid={
                    !!form.formState.errors['participants']?.[index]
                  }
                  as={'button'}
                  data-participant-id={index}
                  typography={'smallBoldParagraph'}
                  onClick={() => {
                    participantsFieldArray.remove(index);
                  }}
                  sx={{
                    border: 'unset',
                    fontSize: '14px',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    cursor: 'pointer',
                    borderBottom: '1px solid #D9D9D9',
                    color: theme.palette.blue.primary,
                    background: !!form.formState.errors['participants']?.[index]
                      ? theme.palette.common.invalid
                      : 'none',
                  }}
                  px={2}
                >
                  {t('form.remove')}
                </Typography>
              ) : (
                <Box
                  sx={{
                    borderBottom: '1px solid #D9D9D9',
                  }}
                />
              )}
            </ParticipantDataProvider>
          );
        })}
      </Box>
    </FormProvider>
  );
};

export const useParticipantsListForm = (participants, onSubmit = undefined) => {
  const form = useForm({
    defaultValues: {
      participants: [...participants, { ...emptyParticipantData }],
    },
    resolver: yupResolver(participantsListSchema),
  });

  return [
    React.createElement(ParticipantsList, { form }),
    {
      form,
      isError: form.formState.errors?.length > 0,
      onSubmit: form.handleSubmit(onSubmit),
    },
    form.watch('participants'),
  ];
};

export const participantSchema = yup.object({
  salutationId: yup.string().required(), // TODO: add validation if in the correct range of values
  firstName: yup.string().required(),
  lastName: yup.string().required(),
  email: yup.string().email().required(),
});

const participantsListSchema = yup.object({
  participants: yup
    .array()
    // Delete the last element that is always empty
    .transform((value) => {
      const _participants = [...value];
      _participants.pop();
      return _participants;
    })
    .of(participantSchema)
    .min(1)
    .required(),
});

/**
 * @param solutionsUser
 * @return {{firstName: string, lastName: string, salutationId: string, email: string}|null}
 */
export const mapSolutionsUserToParticipant = (solutionsUser) => {
  const _participant = {
    salutationId: getUserSalutationId(solutionsUser.sex),
    firstName: solutionsUser.firstname,
    lastName: solutionsUser.lastname,
    email: solutionsUser.email,
  };

  try {
    if (participantSchema.validateSync(_participant)) {
      return _participant;
    }
  } catch (e) {
    return null;
  }
  return null;
};

export default ParticipantsList;
