export const ROOT_URL = '/';
export const PROFILE_URL = '/profile';

export const ANMELDEN_URL = '/anmelden';
export const INITIATIVE_LEAD_URL = '/initiative-lead';
export const SHIFT_URL = `${INITIATIVE_LEAD_URL}/der-shift`;
export const PRIORITIES_URL = `${INITIATIVE_LEAD_URL}/die-schwerpunkte`;
export const ALL_OFFERS = `${INITIATIVE_LEAD_URL}/alle-angebote`;

export const EXPLOITATION_URL = '/agile-at-betrieb-und-schaden';
export const EXPLOITATION_OFFERS_URL = `${EXPLOITATION_URL}/alle-angebote`;
export const AGILEMASTERCOACHOFFERS_URL = `${EXPLOITATION_URL}/alle-angebote-mit-agile-master-coach`;
