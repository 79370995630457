import { Stack } from '@mui/material';

import SupportOfferImage from '../../../../assets/images/exploitation/support-offer-image.jpg';
import Column from './Column';
import './Section5.scss';

const Section5 = () => {
  return (
    <Stack className="section5" spacing={4}>
      <Column />
      <img
        src={SupportOfferImage}
        alt="Zwei Personen, von denen eine einen großen Würfel hoch wirft."
      />
    </Stack>
  );
};

export default Section5;
