import { bookingTypes } from '../actionTypes';

const initialState = {
  isLoading: false,
  isRequestFinished: false,
  error: null,
};

function bookingReducer(state = initialState, { type, payload }) {
  switch (type) {
    case bookingTypes.SET_REQUEST_START:
      return {
        ...state,
        isLoading: true,
      };
    case bookingTypes.SET_REQUEST_RESET:
      return {
        ...state,
        isRequestFinished: false,
        error: null,
      };
    case bookingTypes.SET_REQUEST_SUCCESS:
      return {
        ...state,
        isRequestFinished: true,
        error: null,
        isLoading: false,
      };
    case bookingTypes.SET_REQUEST_ERROR:
      return {
        ...state,
        isRequestFinished: true,
        error: payload,
        isLoading: false,
      };
    default:
      return state;
  }
}

export default bookingReducer;
