import { Box, Typography } from '@mui/material';

import { useGetLocationsQuery } from '../../../../../api';
import group from '../../../../../assets/images/BookingWizard/Group.svg';
import SelectLocation from '../../../components/SelectLocation';
import StatusWrapper from '../../../components/StatusWrapper/StatusWrapper';

/**
 * @param onNext {(location: TLocation) => void}
 * @return {JSX.Element}
 * @constructor
 */
const Page1 = ({ resolve }) => {
  const getLocationsQuery = useGetLocationsQuery();

  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        variant="HeadlineH4"
        component="h1"
        align="center"
        gutterBottom
      >
        Bitte wählen Sie den Allianz-Standort, an dem der Workshop stattfinden
        soll.
      </Typography>
      <Box
        sx={{
          display: 'grid',
          gridTemplateColumns: '1fr auto',
          marginTop: 6,
          gridColumnGap: '50px',
        }}
      >
        <StatusWrapper status={getLocationsQuery.status}>
          <SelectLocation
            onResult={resolve}
            locations={getLocationsQuery.data?.sort((location1, location2) => {
              return location1.name < location2.name
                ? -1
                : location1.name === location2.name
                ? 0
                : 1;
            })}
            allowCustomLocation
          />
        </StatusWrapper>
        <Box
          component="img"
          src={group}
          alt=""
          sx={{
            minWidth: '185px',
            width: '348px',
            height: '471px',
          }}
        />
      </Box>
    </Box>
  );
};

export default Page1;
