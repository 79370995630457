/** All Allianz Units */
export const AZUnits = {
  AllianzRealEstateGmbH: 'Allianz Real Estate GmbH',
  AllianzRe: 'Allianz Re',
  GAGlobalAutomotive: 'GA Global Automotive',
  AllianzSEReinsuranceLHMunich: 'Allianz SE Reinsurance LH Munich',
  AllianzSEReinsurancePCMunich: 'Allianz SE Reinsurance PC Munich',
  AllianzSEReinsurance: 'Allianz SE (Reinsurance)',
  AllianzSE: 'Allianz SE',
  AllianzConsultingAndSalesUnit: 'Allianz Beratungs- und Vertriebs-AG',
  AllianzDeutschlandAG: 'Allianz Deutschland AG',
  AllianzKundeundMarktGmbH: 'Allianz Kunde und Markt GmbH',
  AllianzLebensversicherungsAG: 'Allianz Lebensversicherungs-AG',
  AllianzPensPartnGmbH: 'Allianz Pens. Partn.GmbH',
  AllianzPensionConsultGmbH: 'Allianz Pension Consult GmbH',
  AllianzPrivateKrankenversicherungsAG:
    'Allianz Private Krankenversicherungs-AG',
  AllianzServiceCenterGmbH: 'Allianz Service Center GmbH',
  AllianzVersicherungsAG: 'Allianz Versicherungs-AG',
  AZONEBusinessSolutionsGmbH: 'AZ ONE - Business Solutions GmbH',
  GAVersicherungsserviceGmbH: 'GA Versicherungsservice GmbH',
  KaiserXLabsGmbH: 'Kaiser X Labs GmbH',
  KVMServicePlusGmbH: 'KVM Service Plus GmbH',
  Projektteam: 'Projektteam',
  VLSVersLogistikGmbH: 'VLS Vers.logistik GmbH',
};

export const GlobalUnits = [
  AZUnits.AllianzRealEstateGmbH,
  AZUnits.AllianzRe,
  AZUnits.GAGlobalAutomotive,
  AZUnits.AllianzSEReinsuranceLHMunich,
  AZUnits.AllianzSEReinsurancePCMunich,
  AZUnits.AllianzSEReinsurance,
  AZUnits.AllianzSE,
];

export const AllianzConsultingAndSalesUnit =
  AZUnits.AllianzConsultingAndSalesUnit;

export const GermanyUnits = [
  AZUnits.AllianzConsultingAndSalesUnit,
  AZUnits.AllianzDeutschlandAG,
  AZUnits.AllianzKundeundMarktGmbH,
  AZUnits.AllianzLebensversicherungsAG,
  AZUnits.AllianzPensPartnGmbH,
  AZUnits.AllianzPensionConsultGmbH,
  AZUnits.AllianzPrivateKrankenversicherungsAG,
  AZUnits.AllianzServiceCenterGmbH,
  AZUnits.AllianzVersicherungsAG,
  AZUnits.AZONEBusinessSolutionsGmbH,
  AZUnits.GAVersicherungsserviceGmbH,
  AZUnits.KaiserXLabsGmbH,
  AZUnits.KVMServicePlusGmbH,
  AZUnits.Projektteam,
  AZUnits.VLSVersLogistikGmbH,
];

export const AllPossibleUnits = [...GermanyUnits, ...GlobalUnits];
