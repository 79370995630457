/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react';
import i18n from 'i18next';

/**
 * @param start {number|string|Date}
 * @param end {number|string|Date}
 * @return {*|string}
 */
export const workshopTimestampsToString = (start, end) => {
  try {
    const _start = typeof start === 'number' ? new Date(start) : start;
    const _end = typeof end === 'number' ? new Date(end) : end;

    if (_end instanceof Date && _start instanceof Date) {
      const startHaveTime =
        _start.getHours() + _start.getTimezoneOffset() / 60 > 0;
      const endHaveTime = _end.getHours() + _end.getTimezoneOffset() / 60 > 0;
      if (!startHaveTime && endHaveTime) {
        return i18n.t('intlStartDateEndTime', {
          start: _start,
          end: _end,
          formatParams: {
            start: {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            },
            end: {
              minute: 'numeric',
              hour: 'numeric',
            },
          },
        });
      }
      if (!endHaveTime && startHaveTime) {
        return i18n.t('intlStartDateTime', {
          start: _start,
          formatParams: {
            start: {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              minute: 'numeric',
              hour: 'numeric',
            },
          },
        });
      }
      if (!startHaveTime && !endHaveTime) {
        return i18n.t('intlDate', {
          start: _start,
          formatParams: {
            start: {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
            },
          },
        });
      }
      return i18n.t('intlDateTime', {
        start: _start,
        end: _end,
        formatParams: {
          start: {
            day: 'numeric',
            month: 'numeric',
            year: 'numeric',
            minute: 'numeric',
            hour: 'numeric',
          },
          end: {
            minute: 'numeric',
            hour: 'numeric',
          },
        },
      });
    }

    if (typeof _start === 'string' || typeof _end === 'string') {
      if (!_end && !_start) {
        return 'tba';
      }
      if (!_end) {
        return `tba, ${_start.slice(0, -3)} Uhr (CET/CEST)`;
      }
      if (!_start) {
        return `tba, tba - ${_end.slice(0, -3)} Uhr (CET/CEST)`;
      }
      return `tba, ${_start.slice(0, -3)} - ${_end.slice(
        0,
        -3,
      )} Uhr (CET/CEST)`;
    }
    throw new Error('Invalid parameters provided');
  } catch (e) {
    Sentry.captureException(e, {
      start,
      end,
    });
  }
};
