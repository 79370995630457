import * as Sentry from '@sentry/react';
import format from 'date-fns/format';
import { collection, doc, addDoc, updateDoc } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import uniqBy from 'lodash/uniqBy';
import { useQuery } from 'react-query';

import { functions, db } from '../firebaseConfig';
import { getCurrentUser } from './';

/**
 * Uploads a booking for the current user to Firestore for further processing by the Cloud Functions backend.
 *
 * @param {*} booking
 * @param {Object} options
 * @param {Function} options.onSuccess – Called when the booking has successfully been pushed.
 * @param {Function} options.onError – Called when an error happened. Errors are tracked to Senty by defauklt. This parameter is helpful if ui elements should be rendered on error.
 * TODO: Change to react-query mutation and remove `onError` and `onSuccess` props. This process will globalize capturing exceptions as it is done globally with react-query
 */
export const pushWorkshopBooking = async (booking, { onError, onSuccess }) => {
  const { uid } = getCurrentUser();
  try {
    await addDoc(collection(db, 'questionnaireApp', uid, 'bookings'), booking);
    onSuccess?.();
  } catch (err) {
    Sentry.captureException(err, {
      method: 'pushWorkshopBooking',
      booking,
      userId: uid,
    });
    onError?.(err);
  }
};

export const pushPhoneNumber = async (phone, id) => {
  const questionnaireAppRef = doc(db, 'questionnaireApp', id);
  await updateDoc(questionnaireAppRef, { phone });
};

export const getDateAndTimeForWorkshop = async (workshopId) => {
  const getDateAndTimeForWorkshop = httpsCallable(
    functions,
    'getDateAndTimeForWorkshop',
  );
  const { data } = await getDateAndTimeForWorkshop(workshopId);
  return uniqBy(data, (e) => {
    if (typeof e.start !== 'number') {
      return e;
    }
    return `${e.start}${e.end}`;
  });
};

/**
 * An object representing a time slot for workshop.
 * @typedef {Object} TimeSlot
 * @property {string} id - The ID of the time slot.
 * @property {string} workshopId - The id of the workshop.
 * @property {string} startDate - The startDate of the workshop.
 * @property {string} date - The date of the time slot in the format 'YYYY-MM-DD HH:MM'.
 * @property {string} value - The hour of time slot in the format 'HH:mm'.
 */

/**
 * @param workshopDateEntry {Pick<Workshop, 'id' | 'startDate'>}
 * @param options {any}
 * @return {import('react-query').UseQueryResult<TimeSlot[], any>}
 * */
export const useGetDateAndTimeForWorkshop = (
  workshopDateEntry,
  options = undefined,
) => {
  return useQuery(
    ['getDateAndTimeForWorkshop', workshopDateEntry],
    async (context) => {
      if (!context.queryKey[1]?.id) {
        return null;
      }
      const { id: workshopId, startDate } = context.queryKey[1];
      const response = await getDateAndTimeForWorkshop(workshopId);
      return response.map((itemRes) => {
        const _startDate =
          typeof itemRes.start === 'number'
            ? new Date(itemRes.start)
            : itemRes.start;

        return {
          id: `${workshopId}-${itemRes.start}-${itemRes.end}`,
          workshopId: workshopId,
          startDate: startDate,
          date: `${format(new Date(itemRes.start), 'yyyy-MM-dd')} ${format(
            _startDate,
            'HH:mm',
          )}`,
          value: format(_startDate, 'HH:mm'),
        };
      });
    },
    options,
  );
};

/**
 * An object representing a workshop.
 * @typedef {Object} Workshop
 * @property {string} id - The ID of the workshop.
 * @property {string} name - The name of the workshop.
 * @property {string} location - The location of the workshop.
 * @property {string} startDate - The start date of the workshop in the format 'YYYY-MM-DD'.
 * @property {string} endDate - The end date of the workshop in the format 'YYYY-MM-DD'.
 */

/**
 * @param categoryId {number}
 * @param categoryName {string | undefined}
 * @return {Promise<Workshop[]>}
 */
export const getWorkshopsData = async ({ categoryId, categoryName }) => {
  const getWorkshops = httpsCallable(functions, 'getWorkshops');
  const { data } = await getWorkshops({ categoryId, categoryName });
  return data;
};

/**
 * @param payload {{ categoryId: number } | { categoryName: string }}
 * @param options {any}
 * @return {import('react-query').UseQueryResult<Workshop[], any>}
 */
export const useGetWorkshopsData = (payload, options = undefined) => {
  return useQuery(
    ['getWorkshopsData', payload],
    async (context) => {
      const { categoryId, categoryName } = context.queryKey[1];
      const workshopData = await getWorkshopsData({ categoryId, categoryName });
      if (workshopData?.length > 0) {
        return workshopData;
      }
      return [];
    },
    options,
  );
};
