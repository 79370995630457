/* eslint-disable import/prefer-default-export */
import { USER_GENDERS } from '../constants';

export const getUserSalutationId = (salutationId) => {
  switch (salutationId) {
    case USER_GENDERS.M:
      return '1';
    case USER_GENDERS.F:
      return '2';
    case USER_GENDERS.OTHER:
      return '3';
    default:
      throw new Error('A valid gender is required');
  }
};
