import { Box, Modal } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useCallback, useMemo } from 'react';

import closeIcon from '../../assets/icons/X.png';
import './BookingWizard.scss';
import WizardErrorBoundary from './components/WizardErrorBoundary';
import wizards from './wizards';

/**
 * @param open {boolean}
 * @param onClose {() => void}
 * @param wizard {Wizards}
 * @param props {any}
 * @return {JSX.Element}
 * @constructor
 */
export const BookingWizard = ({ open, onClose, wizard, ...props }) => {
  const onFinish = useCallback(onClose, [onClose]);

  const {
    wizard: Wizard,
    image,
    ...wizardProps
  } = useMemo(() => {
    /**
     * @type WizardOption<WizardNode>
     * @private
     */
    const _wizard = wizards[wizard];
    if (!_wizard) {
      throw new Error(
        `Can't find wizard '${wizard}' in [${Object.keys(wizards).join(
          ', ',
        )}'].`,
      );
    }
    return _wizard;
  }, [wizard]);

  return (
    <Modal
      open={open}
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        border: 'none',
        '&:focus': {
          outline: 'none',
        },
      }}
    >
      <Box
        sx={{
          width: '1120px',
          height: '720px',
          display: 'flex',
          position: 'relative',
          backgroundColor: '#FFFFFF',
          border: '1px solid #cecece',
        }}
      >
        <Box
          component="img"
          src={image}
          alt=""
          sx={{
            width: '256px',
            height: '720px',
          }}
        />
        <Box
          onClick={onFinish}
          sx={{
            position: 'absolute',
            width: '32px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: '-1px',
            right: '-1px',
            cursor: 'pointer',
            border: '1px solid #cecece',
          }}
        >
          <Box
            component="img"
            alt="Close Icon"
            src={closeIcon}
            id="close-icon"
          />
        </Box>
        <Box
          sx={{
            display: 'flex',
            width: '800px',
            margin: 4,
          }}
        >
          <WizardErrorBoundary>
            <Wizard {...props} {...wizardProps} onFinish={onFinish} />
          </WizardErrorBoundary>
        </Box>
      </Box>
    </Modal>
  );
};

BookingWizard.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  wizard: PropTypes.oneOf(Object.keys(wizards)).isRequired,
};

export default BookingWizard;
