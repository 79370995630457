import { userTypes } from '../actionTypes';

export const fetchUserFulfilled = (user) => ({
  type: userTypes.FETCH_USER_DATA_FULFILLED,
  payload: {
    user,
  },
});

export const fetchUserData = (uid) => ({
  type: userTypes.FETCH_USER_DATA,
  payload: {
    uid,
  },
});

export const fetchSolutionsUserFulfilled = (user) => ({
  type: userTypes.FETCH_SOLUTIONS_USER_DATA_FULFILLED,
  payload: {
    user,
  },
});

export const fetchSolutionsUserData = (uid) => ({
  type: userTypes.FETCH_SOLUTIONS_USER_DATA,
  payload: {
    uid,
  },
});

export const fetchUserDataForCoache = (uid) => ({
  type: userTypes.FETCH_USER_DATA_FOR_COACHE,
  payload: {
    uid,
  },
});

export const getUserFulfilled = (user) => ({
  type: userTypes.GET_USER_DATA_FULFILLED,
  payload: {
    user,
  },
});

export const registerToOffer = (offers, uid) => ({
  type: userTypes.REGISTER_TO_OFFERS,
  payload: {
    offers,
    uid,
  },
});

export const runWatchUserData = () => ({
  type: userTypes.RUN_WATCH_USER_DATA,
});

export const registerUser = (userData, uid) => ({
  type: userTypes.REGISTER_USER,
  payload: {
    userData,
    uid,
  },
});

export const logout = () => ({
  type: userTypes.LOGOUT,
});

export const setEmptyUserData = () => ({
  type: userTypes.SET_EMPTY_USER_DATA,
});

export const unsetEmptyUserData = () => ({
  type: userTypes.UNSET_EMPTY_USER_DATA,
});
