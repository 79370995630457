import { Box, Typography } from '@mui/material';

import SelectOption from '../../../components/SelectOption';

const branchOptions = [
  'AZ Vers Sach Schaden',
  'AZ Vers Sach Firmen',
  'AZ Vers Sach Privat',
  'AZ ONE Operations',
  'APKV Operations',
  'AZ Leben Operations',
].map((option) => ({ label: option, value: option }));

/**
 * @param onNext {(branch: string) => void}
 * @return {JSX.Element}
 * @constructor
 */
const Page1 = ({ onNext }) => {
  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        variant="HeadlineH4"
        component="h1"
        align="center"
        gutterBottom
      >
        Welcher Sparte gehören Sie an?
      </Typography>

      <SelectOption
        sx={{ margin: 'auto', marginTop: 16, width: 600 }}
        onResult={(option) => onNext(option.value)}
        options={branchOptions}
      />
    </Box>
  );
};

export default Page1;
