import { Typography } from '@mui/material';
import { string, number } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { OFFER_TYPES } from '../../../../constants';

const OfferModalHeader = ({ title, offerType, price }) => {
  const { t } = useTranslation();

  const renderSubTitle = () => {
    if (offerType === OFFER_TYPES.WORKSHOP_CULTURIZER) {
      return 'Folgende Daten werden von Ihnen an die Culturizer GmbH für die Anmeldung übermittelt:';
    } else {
      return 'Folgende Daten werden von Ihnen an uns für die Anmeldung übermittelt:';
    }
  };

  const renderPrice = () => {
    if (price) {
      return `Die Kosten für den Workshop betragen ${t('intlCurrency', {
        val: price,
      })}.`;
    }
  };

  return (
    <>
      <Typography variant="h2HeadlineGrey">{title}</Typography>
      <Typography>
        {renderPrice()} {renderSubTitle()}
      </Typography>
    </>
  );
};

OfferModalHeader.propTypes = {
  offerType: string.isRequired,
  title: string.isRequired,
  price: number,
};

export default OfferModalHeader;
