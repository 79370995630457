import { Box, Typography } from '@mui/material';
import { format, getDate } from 'date-fns';
import { de } from 'date-fns/locale';

import DayEntryProvider from './DayEntryProvider';

/**
 * @param children
 * @param date {string}
 * @return {JSX.Element}
 * @constructor
 */
const DayEntry = ({ date, children }) => {
  const _date = new Date(date);

  return (
    <>
      <Box sx={{ boxShadow: 'inset 0px 1px 0px #D9D9D9' }}>
        <Box
          sx={{
            display: 'flex',
            position: 'relative',
            margin: '8.5px 0 8.5px 0',
          }}
        >
          <Typography
            component="h1"
            variant="h1Bold"
            sx={{
              minWidth: '58px',
              position: 'relative',
              margin: 0,
              alignSelf: 'center',
            }}
          >
            {getDate(_date)}
          </Typography>
          <Box
            sx={{
              padding: 0,
              display: 'flex',
              position: 'relative',
              flexDirection: 'column',
              alignContent: 'center',
              width: '100%',
            }}
          >
            <Box
              variant="body1"
              sx={{
                paddingLeft: '30px',
                justifySelf: 'center',
                display: 'flex',
                paddingTop: '5px',
                justifyContent: 'left',
              }}
            >
              {format(_date, 'MMMM yyyy', { locale: de })}
            </Box>
            <Typography
              variant="body1"
              sx={{
                paddingLeft: '30px',
                justifySelf: 'center',
                display: 'flex',
                paddingTop: '5px',
                justifyContent: 'left',
              }}
            >
              {format(_date, 'EEEE', { locale: de })}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box sx={{ boxShadow: 'inset 0px 1px 0px #D9D9D9' }}>
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            flexDirection: 'row-reverse',
            overflowX: 'auto',
            height: '100%',
            '&>*[role="progressbar"]:not(:first-of-type)': {
              display: 'none',
            },
          }}
        >
          <DayEntryProvider>{children}</DayEntryProvider>
        </Box>
      </Box>
    </>
  );
};

export default DayEntry;
