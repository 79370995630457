import * as Sentry from '@sentry/react';
import { addHours, addMinutes } from 'date-fns';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import slugify from 'slugify';
import useLocalStorageState from 'use-local-storage-state';

import { pushWorkshopBooking } from '../../../../api';
import { OFFER_TYPES } from '../../../../constants';
import FullPageMessage from '../../components/FullPageMessage';
import { mapSolutionsUserToParticipant } from '../../components/ParticipantsList/ParticipantsList';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';

/**
 * @constant
 * @type {{Location: 0, DateAndType: 1, Participants: 2, Summary: 3, Success: 4, Error: 'error'}}
 */
export const Page = {
  Location: 0,
  DateAndType: 1,
  Participants: 2,
  Summary: 3,
  Success: 4,
  Error: 'error',
};

/**
 * @param solutionsUser {{sex: string, firstname: string, lastname: string, email: string}}
 * @return {[{firstName: string, lastName: string, salutationId: string, email: string}]}
 * @private
 */
const _defaultValueParticipants = (solutionsUser) => {
  const _currentUserAsParticipant =
    mapSolutionsUserToParticipant(solutionsUser);
  if (_currentUserAsParticipant) {
    return [_currentUserAsParticipant];
  }
  return [];
};

/**
 * @param name {string}
 * @param durationInHours {number}
 * @param onFinish {() => void}
 * @param solutionsUser
 * @return {JSX.Element}
 * @constructor
 */
export const WizardLead = ({
  name,
  durationInHours,
  onFinish,
  solutionsUser,
  fixedFormat = undefined,
}) => {
  const [state, setState, { removeItem }] = useLocalStorageState(
    `WizardLead-${slugify(name)}`,
    {
      defaultValue: {
        currentPage: Page.Location,
        name,
        participants: _defaultValueParticipants(solutionsUser),
      },
    },
  );

  /**
   * @param name {string}
   * @param branch {string}
   * @param format {{ label: string, value: string }}
   * @param room {string}
   * @param location {{ id: string, name: string }}
   * @param participants {any[]}
   * @param date {Date}
   * @param time {string}
   * @return {Promise<void>}
   */
  const submitWizard = async ({
    name,
    format,
    room,
    location,
    participants,
    date,
    time,
  }) => {
    // We convert start and end date/time of the workshop into proper date objects for easier handling
    const start = addHours(new Date(date), Number(time));
    const end = addMinutes(start, durationInHours * 60); // We add minutes so we can work with durations in decimal hours (like 3.5 hours)

    // We construct the booking object based on data we need at the backend
    const bookingObject = {
      offerType: OFFER_TYPES.WORKSHOP_NEW,
      semcoDepartmentId: 2,
      name,
      format,
      room,
      location,
      participants,
      start,
      end,
    };

    // We push everything to Firestore
    await pushWorkshopBooking(bookingObject, {
      // And display a success message ...
      onSuccess: () => {
        setState((state) => ({ ...state, currentPage: Page.Success }));
      },

      // ... or an error message
      onError: (e) => {
        throw new Error(e);
      },
    });
  };

  Sentry.addBreadcrumb({
    category: `WizardLead-${slugify(name)}`,
    message: `Navigated to Page ${state.currentPage}`,
    level: 'info',
    data: { state },
  });

  switch (state.currentPage) {
    case Page.Location: {
      return (
        <Page1
          resolve={(location) => {
            setState((state) => ({
              ...state,
              location,
              currentPage: Page.DateAndType,
            }));
          }}
        />
      );
    }
    case Page.DateAndType: {
      return (
        <Page2
          data={state}
          fixedFormat={fixedFormat}
          onNext={(newState) => {
            setState((state) => ({
              ...state,
              ...newState,
              currentPage: Page.Participants,
            }));
          }}
          onBack={() => {
            setState((state) => ({ ...state, currentPage: Page.Location }));
          }}
        />
      );
    }
    case Page.Participants: {
      return (
        <Page3
          participants={state.participants}
          onNext={(participants) => {
            setState((state) => ({
              ...state,
              participants,
              currentPage: Page.Summary,
            }));
          }}
          onBack={() => {
            setState((state) => ({ ...state, currentPage: Page.DateAndType }));
          }}
        />
      );
    }
    case Page.Summary: {
      return (
        <Page4
          data={state}
          onNext={() => {
            submitWizard(state);
          }}
          onBack={(page = Page.Participants) => {
            setState((state) => ({
              ...state,
              currentPage: page,
            }));
          }}
        />
      );
    }
    case Page.Success: {
      return (
        <FullPageMessage
          title="Ihre Anmeldung wurde erfolgreich übermittelt."
          message="Sie bekommen in Kürze eine E-Mail von uns."
          onClick={() => {
            removeItem();
            onFinish();
          }}
        />
      );
    }
    default:
    case Page.Error:
      throw new Error(`Invalid page specified: ${state.currentPage}`);
  }
};

WizardLead.propTypes = {
  name: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  solutionsUser: state.userStore.solutionsUser,
});
export default connect(mapStateToProps)(WizardLead);
