import React from 'react';

import titleImage from '../../../../assets/images/exploitation/sections/section1/title-image.png';
import { staticSection1 } from '../../static/static';
import './Section1.scss';

const Section1 = () => {
  return (
    <div className="section1">
      <img src={titleImage} alt="title" />
      <h1>{staticSection1.title}</h1>
      <span>{staticSection1.content}</span>
    </div>
  );
};

export default Section1;
