import { NativeSelect, FormControl, CircularProgress } from '@mui/material';
import React from 'react';

const BookingsWorkshopDates = ({
  workshopDates,
  classes,
  onChange,
  date,
  isLoading,
}) => {
  return (
    <>
      {isLoading && <CircularProgress />}
      {workshopDates?.length ? (
        <>
          <div className="offer-modal-content-user-data">
            <div className="offer-modal-content-user-data-item">
              <FormControl className={classes.formControl} variant="standard">
                <NativeSelect value={date} onChange={onChange} name="date">
                  <option hidden value="" disabled>
                    Bitte Termin wählen
                  </option>
                  {workshopDates.map((date, i) => {
                    return (
                      <option key={i} value={date}>
                        {date}
                      </option>
                    );
                  })}
                </NativeSelect>
              </FormControl>
            </div>
          </div>
          <div className="offer-modal-content-statement paragraph bold">
            Bitte stellen Sie sicher, dass Sie an dem Termin teilnehmen können.
          </div>
        </>
      ) : null}
      {!isLoading && !workshopDates?.length && (
        <div className="offer-modal-content-statement paragraph bold">
          Aktuell sind keine freien Termine verfügbar.
        </div>
      )}
    </>
  );
};

export default BookingsWorkshopDates;
