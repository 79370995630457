import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useAddEmptyParticipantOnTouched,
  useParticipantData,
} from './ParticipantDataProvider';
import { TableCellWithError, TextInput } from './participantInput.components';

const FirstNameInput = () => {
  const [t] = useTranslation();
  const { index } = useParticipantData();
  const formContext = useFormContext();

  const firstNameController = useController({
    name: `participants.${index}.firstName`,
    control: formContext.control,
  });

  useAddEmptyParticipantOnTouched(firstNameController.fieldState.isTouched);

  return (
    <TableCellWithError
      id={`participants.${index}.firstName`}
      {...firstNameController.fieldState}
      invalid={!!formContext.formState.errors['participants']?.[index]}
    >
      <TextInput
        {...firstNameController.field}
        aria-invalid={firstNameController.fieldState.invalid}
        value={firstNameController.field.value ?? ''}
        placeholder={t('form.firstName')}
      />
    </TableCellWithError>
  );
};

export default FirstNameInput;
