import uniqBy from 'lodash/uniqBy';
import { createContext, useReducer } from 'react';

export const DayContext = createContext({
  dayState: {},
  dayDispatch: () => {},
});

const dayReducer = (prevState, action) => {
  switch (action.type) {
    case 'addHours': {
      const _newHours = [...action.hours, ...prevState].sort((a, b) => {
        const hourA = Number(a.value.slice(0, 2));
        const hourB = Number(b.value.slice(0, 2));
        if (hourA > hourB) {
          return -1;
        }
        if (hourA < hourB) {
          return 1;
        }
        const minutesA = Number(a.value.slice(3, 5));
        const minutesB = Number(b.value.slice(3, 5));
        if (minutesA > minutesB) {
          return -1;
        }
        if (minutesA < minutesB) {
          return 1;
        }
        return 0;
      });
      return uniqBy(_newHours, 'id');
    }
    default: {
      return prevState;
    }
  }
};

/**
 * @param children
 * @return {JSX.Element}
 * @constructor
 */
const DayEntryProvider = ({ children }) => {
  const [dayState, dayDispatch] = useReducer(dayReducer, []);
  return (
    <DayContext.Provider value={{ dayState, dayDispatch }}>
      {children}
    </DayContext.Provider>
  );
};

export default DayEntryProvider;
