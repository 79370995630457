import { Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserInfoText from '../../components/UserInfoText';
import useFetchSolutionsUser from '../../effects/useFetchSolutionsUser';
import { userActions, modalActions } from '../../store/actions';
import { ALL_OFFERS_DATA } from './AllOffersData';
import AllOffersList from './AllOffersList';
import './index.scss';

const AllOffers = ({ actions, authUser, solutionsUser, storeUser }) => {
  useFetchSolutionsUser({
    ...actions,
    solutionsUser,
    authUser,
  });

  return (
    <div className="journey-recommendations-container themself-recomendations">
      <Typography component="h1" variant="h1Bold" sx={{ marginBottom: 6 }}>
        Die Learning Angebote im Überblick
      </Typography>
      <Typography variant="body1" gutterBottom>
        Hier sehen Sie die komplette Auswahl an Weiterbildungsangeboten, die
        Ihnen im Allianz Deutschland Verbund unter #lead angeboten werden.{' '}
        <UserInfoText
          unit={storeUser.unit}
          cluster={storeUser.cluster}
          leadingLeaders={storeUser.leadingLeaders}
        />
      </Typography>
      <Typography>
        <strong>Bitte beachten Sie:</strong> Nach Durchführung der Workshops
        werden diese automatisch (einmal im Monat) in Ihre Lernhistorie auf der
        AllianzU Lernplattform Degreed übertragen. Die Lernzeiten werden
        entsprechend Ihrem Leadership Passport gutgeschrieben.
      </Typography>
      <div className="journey-recommendations-all">
        <AllOffersList list={ALL_OFFERS_DATA} />
      </div>
    </div>
  );
};

AllOffers.propTypes = {
  actions: PropTypes.object.isRequired,
  authUser: PropTypes.object.isRequired,
  solutionsUser: PropTypes.object.isRequired,
  storeUser: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => ({
  authUser: state.authStore.user,
  solutionsUser: state.userStore.solutionsUser,
  storeUser: state.userStore.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        ...modalActions,
        ...userActions,
      },
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(AllOffers);
