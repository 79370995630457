import { commonTypes } from '../actionTypes';

const initialState = {
  locale: null,
};

function commonReducer(state = initialState, { type, payload }) {
  switch (type) {
    case commonTypes.SET_LOCALE_FULFILLED:
      return {
        ...state,
        locale: payload,
      };
    default:
      return state;
  }
}

export default commonReducer;
