import { companyTypes } from '../actionTypes';

export const fetchUserCompanyFulfilled = (company) => {
  return {
    type: companyTypes.FETCH_COMPANY_FULFILLED,
    payload: {
      company,
    },
  };
};

export const fetchUserCompany = (uid) => ({
  type: companyTypes.FETCH_COMPANY,
  payload: {
    uid,
  },
});
