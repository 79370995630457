import { Box, Stack, Typography, Link } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import React from 'react';

import theme from '../../../../../theme/index';
import Actions from '../../../components/Actions/Actions';
import { useParticipantsListForm } from '../../../components/ParticipantsList/ParticipantsList';

const __SHOW_TOOLTIP_BELOW_PARTICIPANTS__ = 4;

const getParticipantsFromLocalStorage = () => {
  const participants = JSON.parse(localStorage.getItem('workShop'))?.[2];
  if (typeof participants?.length === 'number' && participants.length > 0) {
    return participants;
  }
  const currentUser = {
    ...JSON.parse(localStorage.getItem('currentUser')),
    id: Date.now(),
  };
  return [currentUser];
};

const Page3 = ({ onBack, onNext }) => {
  const handleFormSubmit = (payload) => {
    const currentLocalStore = JSON.parse(localStorage.getItem('workShop'));
    currentLocalStore[2] = payload.participants;
    localStorage.setItem('workShop', JSON.stringify(currentLocalStore));

    onNext();
  };

  const [ParticipantsList, { onSubmit }, _participants] =
    useParticipantsListForm(
      getParticipantsFromLocalStorage(),
      handleFormSubmit,
    );

  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Typography
          component="h2"
          variant="h2HeadlineGrey"
          sx={{
            marginBottom: 3,
          }}
        >
          Bitte nennen Sie uns nun, wer an dem Workshop teilnehmen soll.
        </Typography>
        <Typography component="span" variant="subtitle">
          Bitte fügen Sie nun unten alle Teilnehmenden des Workshops hinzu. Für
          die Gutschrift der Lernpunkte benötigen wir die Namen und
          E-Mail-Adressen aller Teilnehmender. Sollte sich bezüglich Ihrer
          Buchung im Nachhinein etwas ändern (Teilnehmende, Raumnummer, etc.),
          schicken Sie bitte eine Nachricht an{' '}
          <Link href="mailto:booking@fgi-mail.com">booking@fgi-mail.com</Link>.
        </Typography>
        <Box sx={{ overflowY: 'auto' }}>{ParticipantsList}</Box>
        <Box
          sx={{
            position: 'absolute',
            bottom: 0,
            right: 0,
          }}
        >
          <Actions
            hasNextButton
            hasBackButton
            onNextButtonClick={() => onSubmit()}
            tooltipText={
              typeof _participants?.length === 'number' &&
              _participants.length - 1 < __SHOW_TOOLTIP_BELOW_PARTICIPANTS__
                ? `Sie haben nur ${
                    _participants.length - 1
                  } Teilnehmer angegeben. Ist das wirklich korrekt?`
                : null
            }
            onBackButtonClick={() => onBack()}
          />
        </Box>
      </Stack>
    </ThemeProvider>
  );
};

export default Page3;
