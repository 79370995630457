import { signInWithCustomToken } from '../api';
import { GlobalUnits } from '../constants/Units';

/**
 * @method signIn
 * @summary sign in to app using user token
 * @returns user info or redirect to call redirect
 */
export const signIn = async (token) => {
  const { user } = await signInWithCustomToken(token);
  return user ? user : null;
};

/**
 * @method redirectNotLoggedUser
 * @summary redirect not logged user to login page
 */
export const redirectNotLoggedUser = () => {
  // Set delay to avoid race condition when logging out
  setTimeout(() => {
    window.location.href = process.env.REACT_APP_REDIRECT_LOGIN_PAGE;
  }, 500);
};

export const checkUserRegisterData = (userData) => {
  if (userData?.unit) {
    if (GlobalUnits.includes(userData?.unit)) {
      return userData.cluster && userData.gender && userData.unit;
    } else {
      return (
        userData.careerpath &&
        userData.cluster &&
        userData.gender &&
        userData.leadingLeaders &&
        userData.unit
      );
    }
  }
  return false;
};
