/* eslint-disable import/prefer-default-export,import/order */

import NutzenSieConnect from '../../assets/images/offers/160x200/NutzenSieConnect.png';
import CulturizerVideo from '../../assets/images/offers/160x200/CulturizerVideo.png';
import UmsetzungsworkshopNachDemCulturizer from '../../assets/images/offers/160x200/UmsetzungsworkshopNachDemCulturizer.png';
import SelfLeadershipFitForChange from '../../assets/images/offers/160x200/SelfLeadership-FitforChange.png';
import CulturizerInSelbstmoderation from '../../assets/images/offers/160x200/CulturizerInSelbstmoderation.png';
import ResilienzImFuehrungsteam from '../../assets/images/offers/160x200/ResilienzImFuehrungsteam.png';
import ResilienzImTeam from '../../assets/images/offers/160x200/ResilienzImTeam.png';
import FuehrenImHybridenArbeiten from '../../assets/images/offers/160x200/FuehrenImHybridenArbeiten.png';

import Teamworkshop from '../../assets/images/offers/160x200/Teamworkshop.png';
import WorkshopFuerFuehrungsteams from '../../assets/images/offers/160x200/WorkshopFuerFuehrungsteams.png';
import image8 from '../../assets/images/offers/256x720/offer-modal-8.png';
import DurationHour from '../../assets/svg/journey-one-hour.svg';
import DurationHalfDay from '../../assets/svg/journey_duration_half_day.svg';
import {
  OFFNEN_BUTTON,
  VERFUGBAR_BUTTON,
} from '../../components/OfferCard/OfferCard';
import { OFFER_TYPES, WORKSHOPS_TYPES } from '../../constants';
import { GermanyUnits } from '../../constants/Units';
import { LEADING_LEADERS_TYPES } from '../../constants/LeadingLeadersTypes';
import { MODAL_STATES } from '../../constants/ModalStates';

/**
 * @type {Array<any | { wizard?: Wizards }>}
 */
export const ALL_OFFERS_DATA = [
  {
    offerName: 'Führen im hybriden Arbeiten',
    title: 'Zusammen stark in der hybriden Arbeit',
    available_for: ['AGE', 'ASE', 'AE', 'ASA'],
    visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
    visible_for_unit: GermanyUnits,
    visible_for_leadingLeaders: '',
    image_small: FuehrenImHybridenArbeiten,
    text: 'Tauschen Sie sich in einem halbtägigen Workshop mit Ihren Kolleg/innen darüber aus, worauf es beim Führen im hybriden Arbeiten ankommt.',
    firstButton: VERFUGBAR_BUTTON,
    duration: {
      text: '4 Std.',
      icon: DurationHalfDay,
    },
    workshopTypes: [WORKSHOPS_TYPES.VIRTUAL],
    wizard: 'fuehren-im-hybriden-arbeiten',
    modalInfo: {
      pdfLink:
        'https://firebasestorage.googleapis.com/v0/b/fgi-questionnaire.appspot.com/o/static%2FOne%20Pager%20Zusammen%20stark%20in%20der%20hybriden%20Arbeit%20fin.pdf?alt=media&token=7c99e66b-a429-4f8a-b65e-bd0bb2edda4f',
    },
  },
  {
    offerName: 'Change Fitness Workshop', // The name of the workshop category in SEMCO
    title: 'Self Leadership - Fit for Change', // The title that is displayed in the UI
    available_for: ['AGE', 'ASE', 'AE', 'ASA'],
    visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
    visible_for_unit: GermanyUnits,
    visible_for_leadingLeaders: '',
    image_small: SelfLeadershipFitForChange,
    text: 'In diesem Workshop lernen Sie Ihre persönlichen Stärken zu nutzen und Ihre Selbstwirksamkeit zu steigern. So können Sie aus Ihrer Führungsrolle heraus anstehende Veränderungen wirksamer gestalten.',
    firstButton: VERFUGBAR_BUTTON,
    duration: {
      text: '4 Std.',
      icon: DurationHalfDay,
    },
    workshopTypes: [WORKSHOPS_TYPES.PRESENCE, WORKSHOPS_TYPES.VIRTUAL],
    wizard: 'self-leadership',
    modalInfo: {
      pdfLink:
        'https://firebasestorage.googleapis.com/v0/b/fgi-questionnaire.appspot.com/o/static%2FSelf-Leadership-Fit-For-Change.pdf?alt=media&token=951714ab-5973-4d81-a6e1-6f78c4746dfc&_gl=1*b5stsg*_ga*Njg2ODk4NzU2LjE2OTQ3MDY4ODc.*_ga_CW55HF8NVT*MTY5NzIwODM2OS4xNi4xLjE2OTcyMDgzOTcuMzIuMC4w',
    },
  },
  {
    offerName: 'Culturizer Video',
    title: 'Culturizer Video',
    available_for: ['AGE', 'ASE', 'AE', 'ASA'],
    visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
    visible_for_unit: GermanyUnits,
    visible_for_leadingLeaders: '',
    image_small: CulturizerVideo,
    text: 'Interessieren Sie sich für den Culturizer? In diesem Video wird der Culturizer kurz erklärt.',
    firstButton: OFFNEN_BUTTON,
    link: 'https://allianzms.sharepoint.com/sites/DE1478-connect-azd-lernen-fuer-fuehrungskraefte',
    duration: {
      text: '10 Minuten / Tag',
      icon: DurationHour,
    },
    workshopTypes: [WORKSHOPS_TYPES.DIGITAL],
  },
  {
    offerName: 'Culturizer in Selbstmoderation',
    title: 'Culturizer in Selbstmoderation',
    visible_for_unit: GermanyUnits,
    visible_for_leadingLeaders: '',
    image_small: CulturizerInSelbstmoderation,
    available_for: ['AGE', 'ASE', 'AE', 'ASA'],
    visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
    text: 'Führen Sie mit Ihrem Team selbstständig den Culturizer durch. Nach der Buchung werden Ihnen die Zugangsdaten für den Culturizer per E-Mail zugesandt. Wir empfehlen Ihnen nach der Anmeldung die Demo-Version des Culturizer durchzuführen.',
    firstButton: VERFUGBAR_BUTTON,
    duration: {
      text: '1/2 Tag',
      icon: DurationHalfDay,
    },
    workshopTypes: [WORKSHOPS_TYPES.PRESENCE, WORKSHOPS_TYPES.HYBRID],
    modalInfo: {
      title:
        'Vielen Dank für Ihr Interesse an einem Culturizer in Selbstmoderation',
      image: image8,
      offerTitle: 'Culturizer in Selbstmoderation',
      pdfLink:
        'https://firebasestorage.googleapis.com/v0/b/fgi-questionnaire.appspot.com/o/static%2FAnleitung-Culturizer-Demo-Version.pdf?alt=media&token=0bdef259-e32f-44c2-8702-cfd650776d82',
      offerType: OFFER_TYPES.WORKSHOP_CULTURIZER,
    },
    secondaryModalInfo: {
      [MODAL_STATES.SUCCESS]: {
        title: 'Ihre Anmeldung wurde erfolgreich übermittelt.',
        text: 'Sie erhalten in Kürze eine E-Mail von Culturizer mit weiteren Informationen.',
      },
      [MODAL_STATES.ERROR]: {
        title: 'Es ist ein Fehler aufgetreten.',
        text: 'Wir sind bereits über den Fehler informiert und arbeiten daran.',
      },
    },
    customLocation: true,
  },
  {
    offerName: 'Culturizer extern moderiert',
    title: 'Culturizer extern moderiert',
    visible_for_unit: GermanyUnits,
    visible_for_leadingLeaders: '',
    image_small: CulturizerInSelbstmoderation,
    available_for: ['AGE', 'ASE', 'AE', 'ASA'],
    visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
    text: 'Lassen Sie Ihren Culturizer durch eine/n erfahrene/n externe/n Moderator/in begleiten. Sie nehmen als Teil Ihres Teams am Workshop teil. Die Durchführungskosten betragen 1.624,35€ (brutto) zzgl. 107,10€ (brutto) bei einer Durchführung in Präsenz.',
    firstButton: VERFUGBAR_BUTTON,
    duration: {
      text: '1/2 Tag',
      icon: DurationHalfDay,
    },
    workshopTypes: [WORKSHOPS_TYPES.PRESENCE, WORKSHOPS_TYPES.HYBRID],
    modalInfo: {
      pdfLink:
        'https://firebasestorage.googleapis.com/v0/b/fgi-questionnaire.appspot.com/o/static%2FF_M55085_00_Checkliste_Culturizer_pro.pdf?alt=media&token=cccd73aa-4c3f-4a50-869e-d5151e4f42d6',
    },
    wizard: 'lead-culturizer',
  },
  {
    offerName: 'Umsetzungs-Workshop nach dem Culturizer (#lead)',
    title: 'Umsetzungsworkshop nach dem Culturizer',
    visible_for_unit: GermanyUnits,
    visible_for_leadingLeaders: '',
    image_small: UmsetzungsworkshopNachDemCulturizer,
    available_for: ['AGE', 'ASE', 'AE', 'ASA'],
    visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
    text: 'Reflektieren Sie 3 – 4 Monate nach dem Culturizerworkshop in diesem extern moderierten Format gemeinsam mit Ihrem Team den aktuellen Stand Ihrer vereinbarten Maßnahmen und steigern Sie dadurch Ihre Umsetzungsstärke. Dieses Angebot gilt außerhalb von Agile@Betrieb und Schaden.',
    firstButton: VERFUGBAR_BUTTON,
    duration: {
      text: '3,5 Std.',
      icon: DurationHalfDay,
    },
    workshopTypes: [WORKSHOPS_TYPES.PRESENCE, WORKSHOPS_TYPES.VIRTUAL],
    modalInfo: {
      pdfLink:
        'https://firebasestorage.googleapis.com/v0/b/fgi-questionnaire.appspot.com/o/static%2FUmsetzungsworkshop-nach-Culturizer.pdf?alt=media&token=1793ef62-52ec-4a51-9b61-be0300a629f5',
    },
    wizard: 'lead-afterCulturizer',
  },

  {
    offerName: 'Führungsteamworkshop',
    title: 'Führungsteam-Workshop 3.0',
    available_for: ['AGE', 'ASE', 'AE'],
    visible_for_cluster: ['AGE', 'ASE', 'AE'],
    visible_for_unit: GermanyUnits,
    visible_for_leadingLeaders: LEADING_LEADERS_TYPES.LEADERSHIP_TEAM,
    image_small: WorkshopFuerFuehrungsteams,
    text: 'Ein halbtägiger Workshop für Führungsteams, um relevante Themen und Herausforderungen (z.B. zur Zusammenarbeit und zum Teamerfolg) zu bearbeiten und Lösungen zu entwickeln. Sie können diesen Workshop auch nutzen, um AES Ergebnisse zu bearbeiten oder die Bedeutung von Agilität und den agilen Prinzipien für Ihr Team zu vertiefen.',
    firstButton: VERFUGBAR_BUTTON,
    duration: {
      text: '1/2 Tag',
      icon: DurationHalfDay,
    },
    workshopTypes: [WORKSHOPS_TYPES.PRESENCE, WORKSHOPS_TYPES.VIRTUAL],
    modalInfo: {
      pdfLink:
        'https://firebasestorage.googleapis.com/v0/b/fgi-questionnaire.appspot.com/o/static%2F20240202_Fu%CC%88hrungsteamWS%203.0%20OnePager_%23lead_V3.pdf?alt=media&token=0eac0ff9-b5b1-4f22-a1fa-ad551e99ba9c',
    },
    wizard: 'lead-leadershipteamworkshop',
  },
  {
    offerName: 'Resilienz im Führungsteam',
    title: 'Resilienz im Führungsteam',
    available_for: ['AGE', 'ASE', 'AE'],
    visible_for_cluster: ['AGE', 'ASE', 'AE'],
    visible_for_unit: GermanyUnits,
    visible_for_leadingLeaders: '',
    image_small: ResilienzImFuehrungsteam,
    text: 'Ein halbtägiger Workshop zur Stärkung der Resilienz im Führungsteam.',
    firstButton: VERFUGBAR_BUTTON,
    duration: {
      text: '1/2 Tag',
      icon: DurationHalfDay,
    },
    workshopTypes: [WORKSHOPS_TYPES.PRESENCE, WORKSHOPS_TYPES.VIRTUAL],
    modalInfo: {
      pdfLink:
        'https://firebasestorage.googleapis.com/v0/b/fgi-questionnaire.appspot.com/o/static%2FResilienz-im-Fu%CC%88hrungsteam.pdf?alt=media&token=a82f7e2c-234e-41eb-9561-574d42a134ef',
    },
    wizard: 'lead-resillienz',
  },
  {
    offerName: 'Teamworkshop',
    title: 'Machen Sie einen Teamworkshop',
    available_for: ['ASA'],
    visible_for_cluster: ['ASA'],
    visible_for_unit: GermanyUnits,
    visible_for_leadingLeaders: '',
    image_small: Teamworkshop,
    text: 'Workshop für einen offenen Dialog und zur Verbesserung der Zusammenarbeit im Team entlang Ihres individuellen Entwicklungsbedarfs.',
    firstButton: VERFUGBAR_BUTTON,
    duration: {
      text: '1/2 Tag',
      icon: DurationHalfDay,
    },
    workshopTypes: [
      WORKSHOPS_TYPES.PRESENCE,
      WORKSHOPS_TYPES.HYBRID,
      WORKSHOPS_TYPES.VIRTUAL,
    ],
    modalInfo: {
      pdfLink:
        'https://firebasestorage.googleapis.com/v0/b/fgi-questionnaire.appspot.com/o/static%2FTeam_WS_20220203.pdf?alt=media&token=76bdbfbe-a2d6-4057-aac5-ba946650389b',
    },
    wizard: 'lead-teamworkshop',
  },
  {
    offerName: 'Resilienz im Team', // SEMCO Course Category
    title: 'Resilienz im Team',
    available_for: ['ASA'],
    visible_for_cluster: ['ASA'],
    visible_for_unit: GermanyUnits,
    visible_for_leadingLeaders: '',
    image_small: ResilienzImTeam,
    text: 'Ein halbtägiger Workshop zur Stärkung der Resilienz in ihrem Team.',
    firstButton: VERFUGBAR_BUTTON,
    duration: {
      text: '1/2 Tag',
      icon: DurationHalfDay,
    },
    workshopTypes: [WORKSHOPS_TYPES.PRESENCE],
    modalInfo: {
      pdfLink:
        'https://firebasestorage.googleapis.com/v0/b/fgi-questionnaire.appspot.com/o/static%2FResilienzImTeam.pdf?alt=media&token=d6d438d7-6e75-4574-bd3b-7454d9bbc33e',
    },
    wizard: 'lead-resillienz-im-team',
  },
  {
    offerName: 'Connect',
    title: 'Nutzen Sie Connect',
    available_for: ['AGE', 'ASE', 'AE', 'ASA'],
    visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
    visible_for_unit: GermanyUnits,
    visible_for_leadingLeaders: '',
    image_small: NutzenSieConnect,
    text: 'Connect ist unsere Informations- und Austauschplattform: Hier erfahren Sie Hintergrundinformationen und Aktuelles rund um die Initiative #lead.',
    firstButton: OFFNEN_BUTTON,
    link: 'https://allianzms.sharepoint.com/sites/DE1890-connect-global-topic-learning-development-lernen-fuer-fuehrungskraefte',
    duration: {
      text: '10 Minuten / Tag',
      icon: DurationHour,
    },
    workshopTypes: [WORKSHOPS_TYPES.DIGITAL],
  },
];
