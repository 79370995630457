import { Box, NativeSelect } from '@mui/material';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { ReactComponent as ArrowDown } from '../../../../../assets/svg/arrow-down-black.svg';
import {
  useAddEmptyParticipantOnTouched,
  useParticipantData,
} from './ParticipantDataProvider';
import { TableCellWithError } from './participantInput.components';

const SalutationInput = () => {
  const [t] = useTranslation();
  const { index } = useParticipantData();
  const formContext = useFormContext();

  const salutationController = useController({
    name: `participants.${index}.salutationId`,
    control: formContext.control,
  });

  useAddEmptyParticipantOnTouched(salutationController.fieldState.isTouched);

  return (
    <TableCellWithError
      id={`participants.${index}.salutationId`}
      {...salutationController.fieldState}
      invalid={!!formContext.formState.errors['participants']?.[index]}
    >
      <Box
        sx={{
          display: 'flex',
          flexWrap: 'wrap',
          alignItems: 'flex-start',
          justifyContent: 'space-between',
        }}
      >
        <Box pl={2} sx={{ paddingRight: '20px' }}>
          <NativeSelect
            {...salutationController.field}
            value={salutationController.field.value ?? ''}
            inputProps={{
              'aria-invalid': salutationController.fieldState.invalid,
            }}
            IconComponent={() => (
              <Box
                component="i"
                sx={{
                  width: 0,
                  marginLeft: '-15px',
                  userSelect: 'none',
                  pointerEvents: 'none',
                  '& > *': { width: '10px' },
                }}
              >
                <ArrowDown />
              </Box>
            )}
            sx={{
              fontWeight: '700',
              color:
                salutationController.field.value === '' ? '#999999' : 'black',
              '&::before, &::after': {
                borderBottom: 'none!important',
              },
              fontSize: '14px',
            }}
          >
            <Box component="option" hidden value="" disabled>
              {t('form.salutation')}
            </Box>
            {salutationOptions.map((salutationOption) => (
              <Box
                key={salutationOption.value}
                component={'option'}
                value={salutationOption.value}
                sx={{
                  color: 'black',
                }}
              >
                {salutationOption.label}
              </Box>
            ))}
          </NativeSelect>
        </Box>
      </Box>
    </TableCellWithError>
  );
};

const salutationOptions = [
  {
    label: 'Herr',
    value: 1,
  },
  {
    label: 'Frau',
    value: 2,
  },
  {
    label: 'Divers',
    value: 3,
  },
];

export default SalutationInput;
