import { bookingTypes } from '../actionTypes';

export const setRequestOffer = (data, id) => ({
  type: bookingTypes.SET_REQUEST,
  payload: { data, id },
});

export const setCulturizerOffer = (data) => ({
  type: bookingTypes.SET_CULTURIZER_OFFER,
  payload: data,
});

export const setRequestOfferStart = () => ({
  type: bookingTypes.SET_REQUEST_START,
});

export const setRequestOfferSuccess = () => ({
  type: bookingTypes.SET_REQUEST_SUCCESS,
});

export const setRequestOfferError = (err) => ({
  type: bookingTypes.SET_REQUEST_ERROR,
  payload: err,
});

export const setRequestOfferReset = () => ({
  type: bookingTypes.SET_REQUEST_RESET,
});
