const fontAllianzNeoRegular =
  'AllianzNeo-Regular, Arial, Helvetica, sans-serif';
const fontAllianzNeoBold = 'AllianzNeo-Bold, Arial, Helvetica, sans-serif';

const defineTypography = (theme) => {
  theme.typography.paragraph = {
    fontFamily: fontAllianzNeoRegular,
    fontSize: '16px',
    color: theme.palette.grey.navyGrey,
    lineHeight: '24px',
  };
  theme.typography.body1 = theme.typography.paragraph;
  theme.typography.paragraphHighlight = {
    fontFamily: fontAllianzNeoBold,
    fontSize: '16px',
    color: theme.palette.grey.navyGrey,
    lineHeight: '24px',
  };
  theme.typography.h2HeadlineGrey = {
    fontFamily: fontAllianzNeoRegular,
    fontSize: '24px',
    color: theme.palette.grey.navyGrey,
    lineHeight: '31px',
    fontWeight: 'normal',
  };
  theme.typography.h1Small = {
    fontFamily: fontAllianzNeoBold,
    fontSize: '36px',
    color: theme.palette.grey.navyGrey,
    lineHeight: '47px',
  };
  theme.typography.h1Bold = {
    fontFamily: fontAllianzNeoBold,
    fontSize: '54px',
    color: theme.palette.grey.navyGrey,
    lineHeight: '110%',
  };
  theme.typography.h1 = theme.typography.h1Bold;
  theme.typography.offerHeadlineBlue = {
    fontFamily: fontAllianzNeoRegular,
    fontStyle: 'normal',
    fontSize: '20px',
    lineHeight: '130%',
    color: theme.palette.blue.primary,
  };
  theme.typography.HeadlineH4 = {
    fontFamily: fontAllianzNeoRegular,
    fontStyle: 'normal',
    fontSize: '24px',
    fontWeight: 400,
    lineHeight: '31px',
  };
  theme.typography.DarkBlueBoldCaps = {
    fontFamily: fontAllianzNeoBold,
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '130%',
    textTransform: 'uppercase',
    color: theme.palette.blue.dark,
  };
  theme.typography.OfferHeadlineBlue = {
    fontFamily: fontAllianzNeoBold,
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 700,
    lineHeight: '26px',
    color: theme.palette.blue.primary,
  };
  theme.typography.OfferCardBullets = {
    fontFamily: fontAllianzNeoRegular,
    fontStyle: 'normal',
    fontSize: '14px',
    fontWeight: 600,
    lineHeight: '21px',
    color: theme.palette.grey.navyGrey,
  };
  theme.typography.IndexHeadlineLinkBlue = {
    fontFamily: fontAllianzNeoRegular,
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 600,
    lineHeight: '130%',
    color: theme.palette.blue.dark,
  };
  theme.typography.navigationLinkText = {
    fontFamily: fontAllianzNeoRegular,
    fontWeight: 600,
    fontSize: '16px',
    lineHeight: '21px',
    letterSpacing: '1%',
  };
  theme.typography.subtitle = {
    fontFamily: fontAllianzNeoRegular,
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '21px',
    letterSpacing: '1%',
    color: theme.palette.common.black,
  };
  theme.typography.textListGrey = {
    fontFamily: fontAllianzNeoRegular,
    fontStyle: 'normal',
    fontSize: '20px',
    fontWeight: 300,
    lineHeight: '25px',
    color: theme.palette.common.black,
  };
  theme.typography.boldParagraph = {
    fontFamily: fontAllianzNeoRegular,
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.common.black,
  };
  theme.typography.smallBoldParagraph = {
    fontFamily: fontAllianzNeoRegular,
    fontStyle: 'normal',
    fontSize: '16px',
    fontWeight: 700,
    lineHeight: '24px',
    color: theme.palette.common.black,
  };
};

export default defineTypography;
