import {
  TextField,
  Snackbar,
  Modal,
  Alert,
  CircularProgress,
  Box,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import { bool, object, func, string, node, array } from 'prop-types';
import React, { useMemo, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { useGetWorkshopsData } from '../../api';
import closeIcon from '../../assets/icons/X.png';
import Button from '../../components/Button';
import {
  KeyCodes,
  AllianzConsultingAndSalesUnit,
  BOOKING_TARGETS,
} from '../../constants';
import { OFFER_TYPES, WORKSHOPS_TYPES, MODAL_STATES } from '../../constants';
import { bookingActions } from '../../store/actions';
import {
  getUserSalutationId,
  useFetchWorkshopDateAndTime,
  workshopTimestampsToString,
} from '../../utils';
import { regexpPhone } from '../../utils/Regexp';
import Description from './components/Description';
import InfoUser from './components/InfoUser';
import OfferModalHeader from './components/OfferModalHeader';
import './OfferModal.scss';

const minBookingNotice = 1000 * 60 * 60 * 24 * 21; // How far in advance should workshops be bookable?

export const OfferModal = (props) => {
  const {
    offerName,
    open,
    image,
    onClose,
    onError,
    onSubmit,
    pdfLink,
    storeUser,
    solutionsUser,
    authUser,
    target = '',
    actions,
    workshopTypes,
    customLocation,
    offerType,
    semcoCategoryId,
    modalState,
    secondaryModalInfo,
    isRequestFinished,
    error,
    isLoading,
  } = props;

  const date =
    offerType === OFFER_TYPES.WORKSHOP_CLOSED
      ? new Date(Date.now() + minBookingNotice)
      : '';

  const initialState = {
    createdAt: new Date(Date.now()),
    offerName,
    participants: '',
    date,
    time: '',
    roomNo: '',
    comments: '',
    phone: '',
    offerType,
    location: '',
  };

  const [selectedWorkshop, setSelectedWorkshop] = useState({ id: '' });
  const [phoneError, setPhoneError] = useState(false);
  const [formError, setFormError] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [locationSelector, setLocationSelector] = useState('');

  const workshopsDataQuery = useGetWorkshopsData(
    {
      categoryId: semcoCategoryId,
    },
    { enabled: !!open && !!semcoCategoryId },
  );

  const workshopsDateAndTimeQuery = useFetchWorkshopDateAndTime(
    workshopsDataQuery.data,
    {
      initialData: [],
      enabled: workshopsDataQuery.isFetched,
    },
  );

  const isDatesLoading =
    workshopsDataQuery.isFetching || workshopsDateAndTimeQuery.isFetching;

  const commentsBox = React.useRef(null);

  const mainWorkshopType = useMemo(() => {
    if (workshopTypes.some((type) => type === WORKSHOPS_TYPES.HYBRID)) {
      return WORKSHOPS_TYPES.HYBRID;
    }
    if (workshopTypes.some((type) => type === WORKSHOPS_TYPES.VIRTUAL)) {
      return WORKSHOPS_TYPES.VIRTUAL;
    }
    return WORKSHOPS_TYPES.PRESENCE;
  }, [workshopTypes]);

  /**
   * Takes a workshop object that has been generated by onWorkshopDatesFetch and
   * returns a string representing the dates.
   */
  const dateStringFromWorkshopObject = (workshop) => {
    let result;
    if (typeof workshop.date === 'string') {
      result = workshop.date;
    } else if (Array.isArray(workshop.dates)) {
      if (workshop.dates.length === 0) {
        throw new Error('Empty dates array.`');
      }
      result = workshopTimestampsToString(
        new Date(workshop.dates[0].start),
        new Date(workshop.dates[0].end),
      );
    }
    return result;
  };

  const handleDate = (value) => {
    setFormData({ ...formData, date: value });
    setFormError(false);
  };

  const handleLocationSelectorChange = (event) => {
    const { value } = event.target;
    setLocationSelector(value);
    setFormData({ ...formData, location: value === 'custom' ? '' : value });
    setFormError(false);
    setPhoneError(false);
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setFormError(false);
    setPhoneError(false);
  };

  const handleClose = () => {
    onClose();
    actions.setRequestOfferReset();
  };

  const checkRequiredProperties = () => {
    if (offerType === OFFER_TYPES.WORKSHOP_CULTURIZER) {
      return false;
    }
    const { phone, participants, roomNo, date, time, location } = formData;
    if (phone && !regexpPhone.test(phone)) {
      setPhoneError(true);
      return true;
    }

    if (offerType === OFFER_TYPES.WORKSHOP_OPEN && !date) {
      setFormError(true);
      return true;
    }

    if (
      offerType === OFFER_TYPES.WORKSHOP_CLOSED &&
      (!participants || !date || !time || !roomNo || !location)
    ) {
      setFormError(true);
      return true;
    }
  };

  const handleRegistrationSuccess = async () => {
    try {
      if (checkRequiredProperties()) return;
      if (offerType === OFFER_TYPES.WORKSHOP_CULTURIZER) {
        const { email } = solutionsUser;
        const projectId =
          storeUser.unit === AllianzConsultingAndSalesUnit ? '75' : '72';
        await actions.setCulturizerOffer({ email, projectId });
        return;
      }
      const booking = {
        createdAt: new Date(Date.now()),
        offerName,
        offerType,
        target,
        ...formData,
      };
      if (offerType === OFFER_TYPES.WORKSHOP_OPEN) {
        booking.semcoClassId = workshopsDateAndTimeQuery.data?.find(
          (workshop) =>
            dateStringFromWorkshopObject(workshop) === formData.date,
        )?.id;
        if (target === BOOKING_TARGETS.TEAM) {
          booking.participantList = [
            {
              firstName: solutionsUser.firstname,
              lastName: solutionsUser.lastname,
              salutationId: getUserSalutationId(solutionsUser.sex),
              email: solutionsUser.email,
            },
          ];
        }
      }
      await actions.setRequestOffer(booking, authUser.uid);
      setFormData(initialState);
    } catch (err) {
      Sentry.captureException(err, {
        method: 'handleRegistrationSuccess',
      });
      onError();
    }
  };

  const closeModalOnEsc = (event) => {
    if (
      event.keyCode === KeyCodes.ENTER &&
      document.activeElement !== commentsBox.current
    ) {
      handleRegistrationSuccess();
    }
    if (event.keyCode === KeyCodes.ESC) {
      handleClose();
    }
  };

  const openPdf = () => {
    window.open(pdfLink, '_blank');
  };

  const renderContent = () => {
    if (isLoading) {
      return (
        <Box
          sx={{
            width: '100%',
            height: '100%',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <CircularProgress />
        </Box>
      );
    }
    if (
      modalState === MODAL_STATES.SUCCESS ||
      modalState === MODAL_STATES.ERROR
    ) {
      return (
        <div className="offer-modal-success-content-container">
          <h1 className="offer-modal-success-content-title h1-small">
            {secondaryModalInfo.title}
          </h1>
          <div className="offer-modal-success-content-subtitle paragraph">
            {secondaryModalInfo.text}
          </div>
          <div>
            <Button color="green-grey" onClick={handleClose}>
              Fenster schließen
            </Button>
          </div>
        </div>
      );
    }
    if (modalState === MODAL_STATES.DEFAULT) {
      return (
        <>
          <div className="offer-modal-content-container">
            <OfferModalHeader {...props} />
            <InfoUser
              storeUser={storeUser}
              solutionsUser={solutionsUser}
              offerType={offerType}
            />
            {offerType !== OFFER_TYPES.WORKSHOP_CULTURIZER && (
              <div className="offer-modal-content-phone-row">
                <div className="paragraph">Ihre Telefonnummer? (optional)</div>
                <TextField
                  variant="standard"
                  tabIndex="1"
                  onChange={handleChange}
                  value={formData.phone}
                  className="offer-modal-content-phone-row-text-field"
                  name="phone"
                />
              </div>
            )}
            <Description
              formData={formData}
              offerType={offerType}
              mainWorkshopType={mainWorkshopType}
              handleChange={handleChange}
              workshopTypes={workshopTypes}
              workshopDates={workshopsDateAndTimeQuery.data?.map((item) =>
                dateStringFromWorkshopObject(item),
              )}
              handleDate={handleDate}
              minBookingNotice={minBookingNotice}
              locationSelector={locationSelector}
              handleLocationSelectorChange={handleLocationSelectorChange}
              customLocation={customLocation}
              commentsBox={commentsBox}
              isDatesLoading={isDatesLoading}
              handleSelectedCycle={(workshop) => setSelectedWorkshop(workshop)}
              selectedWorkshopId={selectedWorkshop.id}
              workshopClassIds={workshopsDateAndTimeQuery.data}
            />
          </div>
          <div className="offer-modal-content-button-wrapper">
            {pdfLink && (
              <div
                tabIndex="0"
                className="offer-modal-content-button-wrapper-pdf navigation-link"
                onClick={openPdf}
              >
                Info-PDF herunterladen
              </div>
            )}
            <Button color="blue" onClick={handleRegistrationSuccess}>
              Jetzt anmelden
            </Button>
          </div>
          <Snackbar className="phone-error-alert" open={phoneError}>
            <Alert variant="filled" severity="error">
              Als Telefonnummer bitte mindestens 7 und maximal 20 Zahlen oder +
              - ( ) . x eingeben.
            </Alert>
          </Snackbar>
          <Snackbar className="phone-error-alert" open={formError}>
            <Alert variant="filled" severity="error">
              Bitte alle Felder ausfüllen
            </Alert>
          </Snackbar>
        </>
      );
    }
  };

  useEffect(() => {
    if (isRequestFinished) {
      if (error) {
        onError();
      } else {
        onSubmit();
      }
    }
  }, [isRequestFinished, error, onError, onSubmit]);

  return (
    <Modal
      onKeyDown={closeModalOnEsc}
      open={open}
      onClose={handleClose}
      className="offer-modal"
    >
      <div className="offer-modal-content">
        <img className="offer-modal-content-image" src={image} alt="" />
        <div className="offer-modal-content-close" onClick={handleClose}>
          <img src={closeIcon} alt="Close Icon" />
        </div>
        {renderContent()}
      </div>
    </Modal>
  );
};

OfferModal.defaultProps = {
  onError: () => {},
};

OfferModal.propTypes = {
  open: bool.isRequired,
  title: string.isRequired,
  image: node.isRequired,
  pdfLink: string.isRequired,
  onClose: func.isRequired,
  onSubmit: func.isRequired,
  onError: func,
  authUser: object.isRequired,
  storeUser: object.isRequired,
  workshopTypes: array.isRequired,
  workshopDates: array,
};

const mapStateToProps = (state) => ({
  authUser: state.authStore.user,
  isRequestFinished: state.bookingStore.isRequestFinished,
  error: state.bookingStore.error,
  isLoading: state.bookingStore.isLoading,
  storeUser: state.userStore.user,
  solutionsUser: state.userStore.solutionsUser,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        ...bookingActions,
      },
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(OfferModal);
