import { authTypes } from '../actionTypes';

export const getAuthFulfilled = (user) => ({
  type: authTypes.GET_USER_AUTH_FULFILLED,
  payload: user,
});

export const signInWithCustomToken = (token) => ({
  type: authTypes.SIGN_IN_WITH_CUSTOM_TOKEN,
  payload: { token },
});

export const signInWithCustomTokenFulfilled = (user) => ({
  type: authTypes.SIGN_IN_WITH_CUSTOM_TOKEN_FULFILLED,
  payload: user,
});

export const signInWithCustomTokenStarted = () => ({
  type: authTypes.SIGN_IN_WITH_CUSTOM_TOKEN_STARTED,
});
