import { Box, Typography, Stack } from '@mui/material';
import format from 'date-fns/format';
import de from 'date-fns/locale/de';
import set from 'date-fns/set';
import PropTypes from 'prop-types';

import Actions from '../../../components/Actions';
import BookingSummary from '../../../components/BookingSummary';
import { Page } from '../WizardSelectWorkshop';

export const Page2 = ({ data, onBack, onNext }) => (
  <>
    <Stack sx={{ width: '100%' }}>
      <Typography
        component="h2"
        variant="h2HeadlineGrey"
        sx={{
          marginBottom: 2,
        }}
      >
        Bitte prüfen Sie Ihre Reservierung
      </Typography>
      <BookingSummary
        data={[
          {
            label: 'Workshop',
            value: data.name,
          },
          {
            label: 'Termin',
            value: format(
              set(new Date(data.timeSlot.startDate), {
                hours: Number(data.timeSlot.value.slice(0, 2)),
                minutes: Number(data.timeSlot.value.slice(3, 5)),
              }),
              "eeee, d. MMMM yyyy 'um' HH:mm 'Uhr'",
              {
                locale: de,
              },
            ),
            onEdit: () => onBack(Page.TimeSlots),
          },
        ]}
      />
    </Stack>
    <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
      <Actions
        hasBackButton
        onBackButtonClick={() => onBack()}
        hasConfirmButton
        onConfirmButtonClick={onNext}
      />
    </Box>
  </>
);

Page2.pageProps = {
  data: PropTypes.array.isRequired,
  onBack: PropTypes.func,
  onNext: PropTypes.func.isRequired,
};

export default Page2;
