import React, { useState } from 'react';

import Button from '../../components/Button';
import GlobalLink from '../../components/GlobalLink';
import PrioritiesModal from '../../components/PrioritiesModal';
import './Priorities.scss';
import {
  dynamicPriorities,
  personallyPriorities,
  modalArray,
} from './PrioritiesStatic';

const Priorities = () => {
  const [showModal, setShowModal] = useState('');

  const openModal = (title) => () => {
    setShowModal(title);
  };

  const closeModal = () => {
    setShowModal('');
  };

  const openPDF = () => {
    var newWindow = window.open(
      'https://firebasestorage.googleapis.com/v0/b/fgi-questionnaire.appspot.com/o/static%2Flead-schwerpunkte.pdf?alt=media',
      '_blank',
    );
    newWindow.opener = null; // Prevents tabnabbing (https://cheatsheetseries.owasp.org/cheatsheets/HTML5_Security_Cheat_Sheet.html#tabnabbing)
  };

  return (
    <div className="priorities">
      <h1 className="h1-bold">Die Schwerpunkte</h1>
      <div className="priorities-top-wrapper">
        <div className="priorities-top-wrapper-left paragraph">
          Inhaltlicher Kern von #lead sind acht Schwerpunkte, die auf den People
          Attributes aufbauen. Sie beschreiben die Führungsthemen, die für eine
          erfolgreiche Zukunft wichtig sind entlang der beiden Achsen Dynamisch
          und Persönlich.
        </div>
        <div className="priorities-top-wrapper-right">
          <div className="paragraph-highlight priorities-top-wrapper-small-text">
            Hier können Sie alle Schwerpunkte mit ausführlichen Details als PDF
            herunterladen.
          </div>
          <Button onClick={openPDF} color="orange">
            pdf herunterladen
          </Button>
        </div>
      </div>
      <h3 className="priorities-title h3-dark-blue-bold-caps">DYNAMISCH</h3>
      <hr className="priorities-hr" />
      <div className="priorities-items-container">
        {dynamicPriorities.map((item) => (
          <div className="priorities-items-container-item" key={item.title}>
            <div>
              <img src={item.image} alt="" />
              <div className="paragraph-highlight">{item.title}</div>
              <div className="paragraph">{item.text}</div>
            </div>
            <GlobalLink
              isLink={false}
              onClick={openModal(item.title)}
              text="Mehr Informationen"
              active
            />
          </div>
        ))}
      </div>
      <h3 className="priorities-title h3-dark-blue-bold-caps">persönlich</h3>
      <hr className="priorities-hr" />
      <div className="priorities-items-container">
        {personallyPriorities.map((item) => (
          <div className="priorities-items-container-item" key={item.title}>
            <div>
              <img src={item.image} alt="" />
              <div className="paragraph-highlight">{item.title}</div>
              <div className="paragraph">{item.text}</div>
            </div>
            <GlobalLink
              isLink={false}
              onClick={openModal(item.title)}
              text="Mehr Informationen"
              active
            />
          </div>
        ))}
      </div>
      {modalArray.map((modal) => (
        <PrioritiesModal
          key={modal.title}
          title={modal.title}
          icon={modal.icon}
          image={modal.image}
          open={showModal === modal.title}
          subTitle={modal.subTitle}
          onClose={closeModal}
          textArray={modal.textArray}
        />
      ))}
    </div>
  );
};

export default Priorities;
