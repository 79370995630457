import { modalTypes } from '../actionTypes';

export const setRegistrationOfferActive = () => ({
  type: modalTypes.SET_REGISTRATION_OFFER_ACTIVE,
});

export const setRegistrationOfferInactive = () => ({
  type: modalTypes.SET_REGISTRATION_OFFER_INACTIVE,
});

export const setRegistrationOfferSuccessActive = () => ({
  type: modalTypes.SET_REGISTRATION_OFFER_SUCCESS_ACTIVE,
});

export const setRegistrationOfferSuccessInactive = () => ({
  type: modalTypes.SET_REGISTRATION_OFFER_SUCCESS_INACTIVE,
});
