import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

import Footer from '../components/Footer';
import GlobalHeader from '../components/GlobalHeader';
import GlobalNavBar from '../components/GlobalNavBar';
import {
  ROOT_URL,
  SHIFT_URL,
  PRIORITIES_URL,
  PROFILE_URL,
  ALL_OFFERS,
  EXPLOITATION_URL,
  EXPLOITATION_OFFERS_URL,
  AGILEMASTERCOACHOFFERS_URL,
  ANMELDEN_URL,
} from '../constants';
import AgileMasterCoachOffers from '../containers/AgileMasterCoachOffers';
import AllOffers from '../containers/AllOffers';
import Exploitation from '../containers/Exploitation';
import ExploitationOffers from '../containers/ExploitationOffers';
import Login from '../containers/Login';
import MainMenu from '../containers/MainMenu';
import Priorities from '../containers/Priorities';
import Profile from '../containers/Profile';
import ShiftPage from '../containers/ShiftPage/ShiftPage';
import Translation from '../containers/Translation';
import Welcome from '../containers/Welcome';

function App() {
  return (
    <Router>
      <GlobalHeader />
      <Switch>
        <>
          <Login />
          <Translation />
          <div className="App">
            <Switch>
              <Route component={MainMenu} exact path={ROOT_URL} />
              <Route component={Profile} exact path={PROFILE_URL} />
              <Route exact path={ANMELDEN_URL} component={Welcome} />

              <GlobalNavBar>
                <Switch>
                  <Route exact path={SHIFT_URL} component={ShiftPage} />
                  <Route exact path={ALL_OFFERS} component={AllOffers} />
                  <Route
                    exact
                    path={EXPLOITATION_URL}
                    component={Exploitation}
                  />
                  <Route
                    exact
                    path={EXPLOITATION_OFFERS_URL}
                    component={ExploitationOffers}
                  />
                  <Route
                    exact
                    path={AGILEMASTERCOACHOFFERS_URL}
                    component={AgileMasterCoachOffers}
                  />
                  <Route path={PRIORITIES_URL} component={Priorities} />
                </Switch>
              </GlobalNavBar>
            </Switch>
          </div>
          <Footer />
        </>
      </Switch>
    </Router>
  );
}

export default App;
