import PropTypes from 'prop-types';
import slugify from 'slugify';
import useLocalStorageState from 'use-local-storage-state';

import { pushWorkshopBooking } from '../../../../api';
import { BOOKING_TARGETS, OFFER_TYPES } from '../../../../constants';
import FullPageMessage from '../../components/FullPageMessage';
import Page1 from './Page1';
import Page2 from './Page2';

/**
 * @constant
 * @type {{TimeSlots: 0, Summary: 1, Success: 2, Error: 'error'}}
 */
export const Page = {
  TimeSlots: 0,
  Summary: 1,
  Success: 2,
  Error: 'error',
};

/**
 * @param categoryName {string}
 * @param onFinish {() => void}
 * @param offerName {string}
 * @return {JSX.Element}
 * @constructor
 */
export const WizardSelectWorkshop = ({ onFinish, offerName }) => {
  const [state, setState, { removeItem }] = useLocalStorageState(
    `WizardSelectWorkshop-${slugify(offerName)}`,
    {
      defaultValue: {
        currentPage: Page.TimeSlots,
        name: offerName,
      },
    },
  );

  /**
   * @param name {string}
   * @param timeSlot {TimeSlot}
   * @return {Promise<void>}
   */
  const submitWizard = async ({ name, timeSlot }) => {
    const bookingObject = {
      offerType: OFFER_TYPES.WORKSHOP_OPEN,
      name,
      date: timeSlot.date,
      startDate: timeSlot.startDate,
      semcoClassId: timeSlot.workshopId,
      target: BOOKING_TARGETS.MYSELF,
    };

    // We push everything to Firestore
    await pushWorkshopBooking(bookingObject, {
      // And display a success message ...
      onSuccess: () => {
        setState((state) => ({ ...state, currentPage: Page.Success }));
      },

      // ... or an error message
      onError: (e) => {
        throw new Error(e);
      },
    });
  };

  switch (state.currentPage) {
    case Page.TimeSlots: {
      return (
        <Page1
          onNext={(timeSlot) => {
            setState((state) => ({
              ...state,
              timeSlot,
              currentPage: Page.Summary,
            }));
          }}
          offerName={offerName}
        />
      );
    }
    case Page.Summary: {
      return (
        <Page2
          data={state}
          onNext={() => {
            submitWizard(state);
          }}
          onBack={(page = Page.TimeSlots) => {
            setState((state) => ({
              ...state,
              currentPage: page,
            }));
          }}
        />
      );
    }
    case Page.Success: {
      return (
        <FullPageMessage
          title="Ihre Anmeldung wurde erfolgreich übermittelt."
          message="Sie bekommen in Kürze eine E-Mail von uns."
          onClick={() => {
            removeItem();
            onFinish();
          }}
        />
      );
    }
    default:
    case Page.Error:
      throw new Error(`Invalid page specified: ${state.currentPage}`);
  }
};

WizardSelectWorkshop.propTypes = {
  name: PropTypes.string.isRequired,
  offerName: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
};

export default WizardSelectWorkshop;
