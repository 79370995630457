import { Typography, Box, CircularProgress } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import UserInfoText from '../../components/UserInfoText';
import useFetchSolutionsUser from '../../effects/useFetchSolutionsUser';
import { userActions } from '../../store/actions';
import { useDisplayWorkshopList } from './workshops';

export const MainMenu = ({ storeUser, authUser, solutionsUser, actions }) => {
  const [t] = useTranslation();
  useFetchSolutionsUser({ ...actions, solutionsUser, authUser });

  const Workshops = useDisplayWorkshopList(storeUser?.unit ?? null);

  if (!solutionsUser || !Object.keys(solutionsUser).length) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', mt: 10 }}>
        <CircularProgress />
      </Box>
    );
  }

  if (Workshops.length === 0) {
    return (
      <>
        <Typography variant="h1" sx={{ mt: '76px', mb: 4 }}>
          {t('main.menu.title')}
        </Typography>
        <Typography gutterBottom>{t('main.menu.noworkshops')}</Typography>
        <Typography>
          <UserInfoText
            unit={storeUser.unit}
            cluster={storeUser.cluster}
            leadingLeaders={storeUser.leadingLeaders}
          />
        </Typography>
      </>
    );
  } else
    return (
      <>
        <Typography variant="h1" sx={{ mt: '76px', mb: 4 }}>
          {t('main.menu.title')}
        </Typography>
        <Typography gutterBottom>{t('main.menu.text1')}</Typography>
        <Typography mb={4}>{t('main.menu.text2')}</Typography>
        <Box
          sx={{
            display: 'grid',
            gridTemplateColumns: '1fr 1fr 1fr',
            gridColumnGap: '32px',
            gridRowGap: '32px',
            paddingBottom: '80px',
          }}
        >
          {Workshops}
        </Box>
      </>
    );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        ...userActions,
      },
      dispatch,
    ),
  },
});

const mapStateToProps = (state) => ({
  storeUser: state.userStore?.user,
  authUser: state.authStore.user,
  solutionsUser: state.userStore.solutionsUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(MainMenu);
