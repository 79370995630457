import { Box } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { ReactComponent as ContactIcon } from '../../assets/svg/contact.svg';
import { ReactComponent as EarthIcon } from '../../assets/svg/earth.svg';
import { ReactComponent as HomeIcon } from '../../assets/svg/home.svg';
import { ReactComponent as SettingsIcon } from '../../assets/svg/settings.svg';
import { ReactComponent as WhiteContactIcon } from '../../assets/svg/white-contact.svg';
import { GlobalUnits, KeyCodes } from '../../constants';
import { PROFILE_URL, ROOT_URL } from '../../constants';
import { DE_LOCALE, EN_LOCALE } from '../../containers/Translation/constants';
import { commonActions } from '../../store/actions';
import { getFooterLinks } from '../../utils';
import FooterButton from './FooterButton';

export const Footer = ({ locale, actions, company, storeUser }) => {
  const [t] = useTranslation();
  const location = useLocation();
  const history = useHistory();

  const { isFooterHide } = getFooterLinks(location.pathname);
  const closeAllModals = (event) => {
    if (event.keyCode === KeyCodes.ESC) {
      handleCloseContact();
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', closeAllModals);
    return () => window.removeEventListener('keydown', closeAllModals);
  }, []); // eslint-disable-line

  const [isOpenContact, setOpenContact] = useState(false);
  const handleOpenContact = () => setOpenContact(true);
  const handleCloseContact = (event) => {
    event?.stopPropagation();
    setOpenContact(false);
  };

  const handleLocale = () => {
    const newLocale = locale === DE_LOCALE ? EN_LOCALE : DE_LOCALE;
    actions.setLocale(newLocale);
  };

  const navigateToSettings = () => {
    const custom_path = company?.custom_path ? company.custom_path : '';
    const url = `https://fgi-digitalsolutions.de/${custom_path}/settings`;
    const newWindow = window.open(url, '_blank');
    newWindow.opener = null;
  };

  const navigateToHomePage = () => {
    history.push(ROOT_URL);
  };

  const isVisibleSection = location.pathname !== PROFILE_URL;
  const isVisibleMenuSection =
    location.pathname !== PROFILE_URL && !GlobalUnits.includes(storeUser.unit);

  if (isFooterHide) {
    return null;
  }

  return (
    <Box
      component="div"
      sx={{
        width: '100%',
        borderTop: '1px solid',
        borderColor: 'grey.6',
        zIndex: 10,
        position: 'fixed',
        bottom: '64px',
        left: '0',
      }}
    >
      <Box
        component="div"
        sx={{
          backgroundColor: 'common.white',
          height: '64px',
          width: '100%',
          maxWidth: '1184px',
          position: 'absolute',
          transform: 'translateX(-50%)',
          left: '50%',
        }}
      >
        <Box
          component="div"
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            height: '64px',
          }}
        >
          <Box
            component="div"
            sx={{
              display: 'flex',
              justifyContent: 'space-around',
              '&:last-child': {
                borderRight: '1px solid',
                borderColor: 'grey.6',
              },
            }}
          >
            {isVisibleMenuSection && (
              <FooterButton
                onClick={navigateToHomePage}
                Icon={HomeIcon}
                text={t('global.footer.item.menu')}
              />
            )}
            {isVisibleSection &&
              (!isOpenContact ? (
                <FooterButton
                  onClick={handleOpenContact}
                  Icon={ContactIcon}
                  text={t('global.footer.item.contact')}
                />
              ) : (
                <FooterButton
                  onClick={handleCloseContact}
                  Icon={WhiteContactIcon}
                  text={t('global.footer.item.contact')}
                  onCloseModal={handleCloseContact}
                  isOpen
                />
              ))}
            {isVisibleSection && (
              <FooterButton
                onClick={navigateToSettings}
                Icon={SettingsIcon}
                text={t('global.footer.item.settings')}
              />
            )}
            {!isVisibleSection && (
              <FooterButton
                onClick={handleLocale}
                Icon={EarthIcon}
                text={t('global.footer.item.locale')}
                id="global-footer-locale-section"
              />
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state) => ({
  locale: state.commonStore.locale,
  company: state.companyStore.company,
  storeUser: state.userStore.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        ...commonActions,
      },
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
