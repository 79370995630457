/* eslint-disable import/prefer-default-export */
import { useQuery } from 'react-query';

import { getDateAndTimeForWorkshop } from '../api';
import { workshopTimestampsToString } from './';

export const fetchWorkshopDateAndTime = async (workshops = []) => {
  const result = [];

  await Promise.all(
    workshops.map(async (workshop) => {
      const workshopData = await getDateAndTimeForWorkshop(workshop.id);

      if (workshopData.length === 2) {
        const [first, second] = workshopData;
        const firstWorkshopDate = workshopTimestampsToString(
          new Date(first.start),
          new Date(first.end),
        );
        const secondWorkshopDate = workshopTimestampsToString(
          new Date(second.start),
          new Date(second.end),
        );

        const workshopDate = `${firstWorkshopDate} & ${secondWorkshopDate}`;

        result.push({
          id: workshop.id,
          name: workshop.name,
          date: workshopDate,
        });
      } else {
        workshopData.forEach((w) => {
          const { start, end } = w;
          const workshopDate = { start, end };

          let index = result.findIndex((element) => element.id === workshop.id);
          if (index >= 0) {
            result[index].dates.push(workshopDate);
          } else {
            result.push({
              id: workshop.id,
              name: workshop.name,
              dates: [workshopDate],
            });
          }
        });
      }
    }),
  );
  return result;
};

export const useFetchWorkshopDateAndTime = (workshops, options = undefined) => {
  return useQuery(
    ['fetchWorkshopDateAndTime', workshops],
    (context) => {
      return fetchWorkshopDateAndTime(context.queryKey[1]);
    },
    options,
  );
};
