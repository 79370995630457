import * as Sentry from '@sentry/react';
import { put, fork, take } from 'redux-saga/effects';

import { commonActions } from '../actions';
import { commonTypes } from '../actionTypes';

function* setLocale(locale) {
  try {
    yield put(commonActions.setLocaleFulfilled(locale));
  } catch (err) {
    Sentry.captureException(err);
  }
}

//=====================================
//  WATCHERS
//-------------------------------------

function* watchSetLocale() {
  while (true) {
    const { payload } = yield take(commonTypes.SET_LOCALE);
    yield fork(setLocale, payload);
  }
}

//=====================================
//  INIT SAGAS
//-------------------------------------

const commonSaga = [fork(watchSetLocale)];
export default commonSaga;
