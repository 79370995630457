import { Alert, Box, Collapse, Typography, Link } from '@mui/material';

import Actions from '../../../components/Actions';
import { useParticipantsListForm } from '../../../components/ParticipantsList/ParticipantsList';

const __SHOW_TOOLTIP_BELOW_PARTICIPANTS__ = 4;

const Page5 = ({ participants, onBack, onNext }) => {
  const handleFormSubmit = (payload) => {
    onNext(payload.participants);
  };

  const [ParticipantsList, { onSubmit, isError }, _participants] =
    useParticipantsListForm(participants, handleFormSubmit);

  return (
    <Box sx={{ display: 'grid', gridTemplateRows: 'auto 1fr auto' }}>
      <div>
        <Typography variant="HeadlineH4" component="h1" gutterBottom>
          Bitte nennen Sie uns nun, wer an dem Workshop teilnehmen soll.
        </Typography>
        <Typography>
          Bitte fügen Sie nun unten alle Teilnehmenden des Workshops hinzu. Sich
          selbst müssen Sie nicht hinzufügen, da Ihre Daten automatisch
          übermittelt werden. Für die Gutschrift der Lernpunkte benötigen wir
          die Namen und E-Mail-Adressen aller Teilnehmenden. Sollte sich
          bezüglich Ihrer Buchung im Nachhinein etwas ändern (Teilnehmende,
          Raumnummer, etc.), schicken Sie bitte eine Nachricht an&nbsp;
          <Link href="mailto:booking@fgi-mail.com">booking@fgi-mail.com</Link>.
        </Typography>
      </div>
      <Box sx={{ overflowY: 'auto' }}>{ParticipantsList}</Box>
      <Box my={1}>
        <Collapse in={isError}>
          <Alert variant="filled" severity="error">
            Bitte alle Felder ausfüllen
          </Alert>
        </Collapse>
      </Box>
      <Box
        sx={{ display: 'flex', justifyContent: 'flex-end', margin: '-32px' }}
      >
        <Actions
          hasBackButton
          onBackButtonClick={() => onBack()}
          hasNextButton
          onNextButtonClick={onSubmit}
          tooltipText={
            typeof _participants?.length === 'number' &&
            _participants.length - 1 < __SHOW_TOOLTIP_BELOW_PARTICIPANTS__
              ? `Sie haben nur ${
                  _participants.length - 1
                } Teilnehmer angegeben. Ist das wirklich korrekt?`
              : null
          }
        />
      </Box>
    </Box>
  );
};

export default Page5;
