import imageAgile from '../../assets/images/BookingWizard/allianz-agile1.png';
import imageZIT from '../../assets/images/BookingWizard/Führungsteams.png';
import imageLeadTeamWorkshop from '../../assets/images/offers/256x720/journey-boxenstop-508x1436.png';
import imageTeamWorkshop from '../../assets/images/offers/256x720/journey-boxenstop-508x1436.png';
import KundeUndBegeisterung from '../../assets/images/offers/256x720/KundeUndBegeisterung.jpg';
import imageFuehrenImHybridenArbeiten from '../../assets/images/offers/256x720/modal-fuehren-im-hybriden-arbeiten.png';
import imageLeadershipWorkshop from '../../assets/images/offers/256x720/offer-modal-3.png';
import imageResilienz from '../../assets/images/offers/256x720/offer-modal-5.png';
import imageCulturizer from '../../assets/images/offers/256x720/offer-modal-8.png';
import image8 from '../../assets/images/offers/256x720/offer-modal-8.png';
import imageResilienzImTeam from '../../assets/images/offers/256x720/offer-resilienz-im-team-256x720.jpg';
import imageSelfLeadershipFitForChange from '../../assets/images/offers/256x720/Self-Leadership-Fit–for-Change.jpg';
import imageSelectWorkshop from '../../assets/images/offers/256x720/wizard-select-workshop-default.png';
import WizardAllianz from './wizards/WizardAllianz';
import WizardLead from './wizards/WizardLead';
import { FORMAT_PRESENCE } from './wizards/WizardLead/Page2';
import WizardSelectWorkshop from './wizards/WizardSelectWorkshop';
import WizardZIT from './wizards/wizardZIT/PageContent';

/**
 * @typedef {{ wizard: T, image: string, durationInHours?: number, name?: string }} WizardOption<T>
 * @template {React.ReactElement} T
 */

/**
 * @typedef {'ZIT'} WizardZITKeys
 */

/**
 * @typedef WizardZITOptions
 * @type {{ [key in WizardZITKeys ]: WizardOption<typeof WizardZIT>}}
 */
export const wizards_zit = {
  ZIT: {
    wizard: WizardZIT,
    image: imageZIT,
  },
};

/**
 * @typedef {'allianz-zit1' | 'allianz-zit2' | 'allianz-culturizer' | 'allianz-agile1' | 'allianz-agile2'} WizardAllianzKeys
 */

/**
 * @typedef WizardAllianzOptions
 * @type {{ [key in WizardAllianzKeys ]: WizardOption<typeof WizardAllianz>}}
 */
export const wizards_allianz = {
  'allianz-zit1': {
    wizard: WizardAllianz,
    image: imageZIT,
    durationInHours: 4,
    name: 'Zusammenarbeit im Team 1',
  },
  'allianz-zit2': {
    wizard: WizardAllianz,
    image: imageZIT,
    name: 'Zusammenarbeit im Team 2',
    durationInHours: 3,
  },
  'allianz-culturizer': {
    wizard: WizardAllianz,
    image: imageCulturizer,
    name: 'Culturizer',
    durationInHours: 4,
  },
  'allianz-agile1': {
    wizard: WizardAllianz,
    image: imageAgile,
    name: 'Agile Prinzipien und Arbeitsweisen 1',
    durationInHours: 4,
  },
  'allianz-agile2': {
    wizard: WizardAllianz,
    image: imageAgile,
    name: 'Agile Prinzipien und Arbeitsweisen 2',
    durationInHours: 4,
  },
  'kunde-begeisterung-1': {
    wizard: WizardAllianz,
    image: KundeUndBegeisterung,
    name: 'Kunde und Begeisterung 1',
    durationInHours: 4,
  },
  'kunde-begeisterung-2': {
    wizard: WizardAllianz,
    image: KundeUndBegeisterung,
    name: 'Kunde und Begeisterung 2',
    durationInHours: 4,
  },
};

/**
 * @typedef {'lead-zit' | 'lead-culturizer' | 'lead-leadershipteamworkshop' | 'lead-teamworkshop' | 'lead-resillienz' | 'lead-afterCulturizer' | 'lead-initiative'} WizardLeadKeys
 */

/**
 * @typedef WizardLeadOptions
 * @type {{ [key in WizardLeadKeys ]: WizardOption<typeof WizardLead>}}
 */
export const wizard_lead = {
  'lead-zit': {
    wizard: WizardLead,
    image: imageZIT,
    durationInHours: 4,
  },
  'lead-culturizer': {
    wizard: WizardLead,
    image: image8,
    durationInHours: 4,
  },
  'lead-leadershipteamworkshop': {
    wizard: WizardLead,
    image: imageLeadTeamWorkshop,
    durationInHours: 4,
  },
  'lead-teamworkshop': {
    wizard: WizardLead,
    image: imageTeamWorkshop,
    durationInHours: 4,
  },
  'lead-resillienz': {
    wizard: WizardLead,
    image: imageResilienz,
    durationInHours: 4,
  },
  'lead-resillienz-im-team': {
    wizard: WizardLead,
    image: imageResilienzImTeam,
    durationInHours: 4,
    fixedFormat: FORMAT_PRESENCE,
  },
  'lead-afterCulturizer': {
    wizard: WizardLead,
    image: imageResilienz,
    durationInHours: 3.5,
  },
  'lead-initiative': {
    wizard: WizardLead,
    image: imageLeadershipWorkshop,
    durationInHours: 4,
  },
};

/**
 * @typedef {'select-workshop'} WizardSelectWorkshopKeys
 */

/**
 * @typedef WizardSelectWorkshopOptions
 * @type {{ [key in WizardSelectWorkshopKeys ]: WizardOption<typeof WizardSelectWorkshop>}}
 */
export const wizard_selectWorkshop = {
  'select-workshop': {
    wizard: WizardSelectWorkshop,
    image: imageSelectWorkshop,
  },
  'fuehren-im-hybriden-arbeiten': {
    wizard: WizardSelectWorkshop,
    image: imageFuehrenImHybridenArbeiten,
  },
  'self-leadership': {
    wizard: WizardSelectWorkshop,
    image: imageSelfLeadershipFitForChange,
  },
};

/**
 * @typedef {WizardSelectWorkshopKeys | WizardLeadKeys | WizardAllianzKeys | WizardZITKeys} Wizards
 */

/**
 * @typedef {typeof WizardZIT | typeof WizardAllianz | typeof WizardLead | typeof WizardSelectWorkshop} WizardNode
 */

/**
 * Wizards object contains every option that `BookingWizard` can have
 * It contains a preset containing of `images` and misc data like `durationInHours` and `name`
 * @typedef WizardOptions
 * @type {{ [key in Wizards]: WizardOption<WizardNode> }}
 */

/**
 * @type WizardOptions
 */
const wizards = {
  ...wizards_zit,
  ...wizards_allianz,
  ...wizard_lead,
  ...wizard_selectWorkshop,
};

export default wizards;
