import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';

import { useGetWorkshopsData } from '../../../../../api';
import SelectDateAndTime from '../../../components/SelectDateAndTime';
import { customErrorMessages } from '../../../components/WizardErrorBoundary/WizardErrorBoundary';

/**
 * @param onNext {(timeSlot: TimeSlot) => void}
 * @param storeUser
 * @param offerName {string}
 * @return {JSX.Element}
 * @constructor
 */
const Page1 = ({ onNext, offerName }) => {
  const workshopDataQuery = useGetWorkshopsData({
    categoryName: offerName,
  });

  // This useEffect is used to work with the WizardErrorBoundary
  useEffect(() => {
    if (workshopDataQuery.isError) {
      if (
        workshopDataQuery.error?.message.includes(
          "Couldn't find course category",
        )
      ) {
        throw new Error(customErrorMessages.loadingAppointments);
      }
      throw new Error(workshopDataQuery.error);
    }
  }, [workshopDataQuery.error, workshopDataQuery.isError]);

  const availableDates = useMemo(() => {
    if (
      workshopDataQuery.isFetching ||
      !Array.isArray(workshopDataQuery.data)
    ) {
      return null;
    }
    return workshopDataQuery.data.map((workshop) => ({
      id: workshop.id,
      startDate: workshop.startDate,
    }));
  }, [workshopDataQuery.data, workshopDataQuery.isFetching]);

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Typography component="h1" variant="HeadlineH4" sx={{ marginBottom: 4 }}>
        Bitte wählen Sie einen Termin aus
      </Typography>
      <SelectDateAndTime availableDates={availableDates} onSelect={onNext} />
    </Box>
  );
};

Page1.propTypes = {
  onNext: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  storeUser: state.userStore.user,
});

export default connect(mapStateToProps)(Page1);
