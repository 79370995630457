import styled from '@emotion/styled';

const OfferCardContainer = styled('div')`
  margin-top: 32px;

  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 64px;

  @media (max-width: 1024px) {
    grid-template-columns: 1fr;
  }
`;

export default OfferCardContainer;
