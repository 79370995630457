import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../../../Button';

const PageSuccess = ({ onFinish }) => {
  const onClick = () => {
    localStorage.removeItem('workShop');
    localStorage.removeItem('currentUser');
    onFinish(1);
  };

  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Typography
        sx={{ margin: '0', width: '597px', textAlign: 'center' }}
        variant="h1Small"
      >
        Ihre Reservierung wurde erfolgreich übermittelt.
      </Typography>
      <Typography component="div" variant="paragraph" sx={{ margin: '30px 0' }}>
        Sie werden in Kürze telefonisch oder via E-Mail von uns kontaktiert.
      </Typography>
      <Box>
        <Button id={'close'} color="green-grey" onClick={onClick}>
          Fenster schließen
        </Button>
      </Box>
    </Box>
  );
};
PageSuccess.propTypes = {
  onFinish: PropTypes.func,
};
export default PageSuccess;
