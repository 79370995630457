import * as Sentry from '@sentry/react';
import { doc, getDoc } from 'firebase/firestore';

import { db } from '../firebaseConfig';

const COLLECTION_NAME = 'companies';

// eslint-disable-next-line import/prefer-default-export
export const getCompanyByUserId = async (uid) => {
  try {
    const userRef = doc(db, 'users', uid);
    const userSnap = await getDoc(userRef);
    const user = userSnap.data();
    const companyRef = doc(db, COLLECTION_NAME, user.company_id);
    const companySnap = await getDoc(companyRef);
    return companySnap.data();
  } catch (err) {
    Sentry.captureException(err, { method: 'getCompanyByUserId', userId: uid });
  }
};
