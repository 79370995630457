import { modalTypes } from '../actionTypes';

const initialState = {
  isActiveOfferRegister: false,
  isActiveOfferRegisterSuccess: false,
};

function modalReducer(state = initialState, { type, payload }) {
  switch (type) {
    case modalTypes.SET_REGISTRATION_OFFER_ACTIVE:
      return {
        ...state,
        isActiveOfferRegister: true,
      };
    case modalTypes.SET_REGISTRATION_OFFER_INACTIVE:
      return {
        ...state,
        isActiveOfferRegister: false,
      };
    case modalTypes.SET_REGISTRATION_OFFER_SUCCESS_ACTIVE:
      return {
        ...state,
        isActiveOfferRegisterSuccess: true,
      };
    case modalTypes.SET_REGISTRATION_OFFER_SUCCESS_INACTIVE:
      return {
        ...state,
        isActiveOfferRegisterSuccess: false,
      };
    default:
      return state;
  }
}

export default modalReducer;
