import modalImage1 from '../../assets/images/priorities-modal-1.png';
import modalImage2 from '../../assets/images/priorities-modal-2.png';
import modalImage3 from '../../assets/images/priorities-modal-3.png';
import modalImage4 from '../../assets/images/priorities-modal-4.png';
import modalImage5 from '../../assets/images/priorities-modal-5.png';
import modalImage6 from '../../assets/images/priorities-modal-6.png';
import modalImage7 from '../../assets/images/priorities-modal-7.png';
import modalImage8 from '../../assets/images/priorities-modal-8.png';
import modalIcon1 from '../../assets/images/priorities-modal-icon-1.png';
import modalIcon2 from '../../assets/images/priorities-modal-icon-2.png';
import modalIcon3 from '../../assets/images/priorities-modal-icon-3.png';
import modalIcon4 from '../../assets/images/priorities-modal-icon-4.png';
import modalIcon5 from '../../assets/images/priorities-modal-icon-5.png';
import modalIcon6 from '../../assets/images/priorities-modal-icon-6.png';
import modalIcon7 from '../../assets/images/priorities-modal-icon-7.png';
import modalIcon8 from '../../assets/images/priorities-modal-icon-8.png';
import dynamicImage1 from '../../assets/images/priorities-small-1.png';
import dynamicImage2 from '../../assets/images/priorities-small-2.png';
import dynamicImage3 from '../../assets/images/priorities-small-3.png';
import dynamicImage4 from '../../assets/images/priorities-small-4.png';
import personallyImage1 from '../../assets/images/priorities-small-5.png';
import personallyImage2 from '../../assets/images/priorities-small-6.png';
import personallyImage3 from '../../assets/images/priorities-small-7.png';
import personallyImage4 from '../../assets/images/priorities-small-8.png';

const titles = [
  'Agilität & Innovation',
  'Flexibilität & Vereinfachung',
  'Eigenverantwortung & Empowerment',
  'Ergebnisorientierung & Umsetzungskraft',
  'Resilienz & Selfcare',
  'Teamgeist & Wertschätzung',
  'Feedback & Lernkultur',
  'Motivation & Überzeugung',
];

export const dynamicPriorities = [
  {
    image: dynamicImage1,
    title: titles[0],
    text: 'Offen für Neues und für frische Ideen',
  },
  {
    image: dynamicImage2,
    title: titles[1],
    text: 'Mutig und fokussiert die Komplexität reduzieren',
  },
  {
    image: dynamicImage3,
    title: titles[2],
    text: 'Gestärkt mit end2end Verantwortung für mehr Co-Creation',
  },
  {
    image: dynamicImage4,
    title: titles[3],
    text: 'Mit klarer Richtung gemeinsam vorwärtskommen',
  },
];

export const personallyPriorities = [
  {
    image: personallyImage1,
    title: titles[4],
    text: 'Gesund, robust und achtsam besser werden',
  },
  {
    image: personallyImage2,
    title: titles[5],
    text: 'Co-creative und mit Rückenwind Neues entwickeln',
  },
  {
    image: personallyImage3,
    title: titles[6],
    text: 'Sich und andere durch Rückmeldungen weiterbringen',
  },
  {
    image: personallyImage4,
    title: titles[7],
    text: 'Sinn stiften für eine starke Organisation',
  },
];

export const modalArray = [
  {
    image: modalImage1,
    title: titles[0],
    icon: modalIcon1,
    subTitle: 'Offen für Neues und für frische Ideen',
    textArray: [
      {
        question: 'Was verstehen wir darunter?',
        statement: `Wir wollen <b>Kundenerlebnisse</b> schaffen, die begeistern. Dafür trauen wir uns, <b>Neues</b> auszuprobieren und Altes loszulassen.

        Wir probieren neue Methoden aus und setzen <b>agile und klassische Methoden</b> gezielt ein.`,
      },
      {
        question: 'Wie kann ich das umsetzen?',
        statement: `<b>Reflexion:</b> Wieviel “agil” brauchen wir bei uns? Was wollen wir ausprobieren, z.B. für mehr kreativen Raum für Ideen?

        <b>Ideen wertschätzen:</b> Welche neuen Ideen gibt es? Welche Fehler bringen uns weiter?

        <b>Anwenden:</b> Retro, Daily, Scrum-Board, Kanban-Board, Kundeneinbindung.`,
      },
      {
        question: 'Warum ist das wichtig für zukunftsfähige Führung?',
        statement: `Weil neue Ideen für unseren <b>Kunden</b> und Innovationen, die schnell umgesetzt werden, unsere Zukunft sichern. 

        Weil Teams die Erlaubnis, die Unterstützung und ein ermutigendes Umfeld brauchen, um <b>Neues</b> auszuprobieren. 

        Weil manchmal Geschwindigkeit zählt und manchmal Sorgfalt. Wirksame Führung weiß, wann <b>Agiles</b> passt und wann nicht.`,
      },
      {
        question: 'Was haben wir davon?',
        statement: `<b>Mehr Kundenorientierung</b> dank besserer Abstimmung mit den (in- und externen) Kunden in kürzeren Zyklen.

        <b>Höhere Mitarbeitermotivation</b> durch gezieltes Nutzen unterschiedlicher Stärken und mehr Eigenverantwortung.

        <b>Bessere Ergebnisse</b> durch Rückmeldungen und Umsetzung und durch mehr Transparenz.`,
      },
    ],
  },
  {
    image: modalImage2,
    title: titles[1],
    icon: modalIcon2,
    subTitle: 'Mutig und fokussiert die Komplexittät reduzieren',
    textArray: [
      {
        question: 'Was verstehen wir darunter?',
        statement: `Wir suchen stetig nach <b>Vereinfachung</b>, agieren schnell, flexibel und pragmatisch. Auch <b>radikale Veränderungen</b> gehen wir an und lassen unseren Mitarbeitenden dabei <b>Freiräume.</b>`,
      },
      {
        question: 'Wie kann ich das umsetzen?',
        statement: `<b>Verschwendungsanalyse:</b> mutig all das identifizieren, was keine Wertschöpfung für Prozesse oder Produkte bringt

        <b>Fokus:</b> Eine Priorität zur Zeit festlegen
        
        <b>Experimente:</b> Neues in einer kleinen Anwendung testen.
        
        <b>Verantwortlichkeit:</b> Chief Simplicity Officer ernennen`,
      },
      {
        question: 'Warum ist das wichtig für zukunftsfähige Führung?',
        statement: `Weil wir in einer Welt, die immer komplexer wird, nicht planen können, <b>was nicht planbar ist</b>.

        Weil sich die Anforderungen von Kunden und Kundinnen, Mitarbeitern und Talenten immer radikaler und schneller ändern, Führung musss darauf <b>schnell und beweglich reagieren.</b>
        
        Weil gute Ergebnisse entstehen, wenn Teams den Freiraum bekommen, cross-funktional und co-creativ zu arbeiten.
        `,
      },
      {
        question: 'Was haben wir davon?',
        statement: `<b>Höhere Beweglichkeit</b> in neuen, fordernden Situationen.
        
        <b>Viel Klarheit</b> bei Abläufen, Prozesse und Verantwortlich-keiten.

        <b>Bessere Ergebnisse</b>, da Produkte genau das leisten, was sie sollen. 
        
        <b>Keine Verschwendung</b> von Ressourcen und von Aufmerksamkeit.`,
      },
    ],
  },
  {
    image: modalImage3,
    title: titles[2],
    icon: modalIcon3,
    subTitle: 'Gestärkt mit end2end Verantwortung für me Co-Creation',
    textArray: [
      {
        question: 'Was verstehen wir darunter?',
        statement: `Wir <b>vertrauen</b> unseren Mitarbeitenden. Deshalb übertragen wir ihnen End2End-<b>Verantwortung</b> und fordern diese auch ein. <b>Entscheidungskompetenz</b> übertragen wir denen, die die Expertise haben.`,
      },
      {
        question: 'Wie kann ich das umsetzen?',
        statement: `<b>Visualisierung</b> für mehr Transparenz und Co-Creation, z.B. mit Board-Arbeit

        <b>Selbstbestimmte Projektarbeit:</b> Einfach ausprobieren
        
        <b>Delegation:</b> Selbstreflexion unter dem Motto: „Wann habe ich mich heute schon rausgehalten?“ 
        
        <b>Akzente setzen</b> im Alltag z.B. „Dein Budget – dein Freiraum“ oder mit Reverse Mentoring`,
      },
      {
        question: 'Warum ist das wichtig für zukunftsfähige Führung?',
        statement: `Weil <b>Vertrauen, aktive Mitwirkung und Gestaltungsfreiheit</b> für hohe Motivation, Engagement und Zusammenhalt sorgen. 
        
        Weil mit weniger Aufwand mehr Output entsteht, wenn Wissen und Erfahrung von allen <b>end2end</b> genutzt wird.
        
        Weil wirksame Führung Verantwortung dorthin bringt, wo sie hingehört und damit alle <b>Potentiale freisetzt.</b>
        `,
      },
      {
        question: 'Was haben wir davon?',
        statement: `<b>Mehr Zeit zum Führen,</b> da Aufgaben dort erledigt werden, wo die Expertise liegt.

        <b>Gestärkte Mitarbeiter,</b> die in ihrer Kompetenz gefördert werden und sich optimistisch auf neue Situationen einstellen.

        <b>Mehr Commitment,</b> weil sich alle verantwortlich fühlen und  das Ergebnis mittragen können.

        <b>Hohe Motivation</b> dank mehr Autonomie für die Teams.        
        `,
      },
    ],
  },
  {
    image: modalImage4,
    title: titles[3],
    icon: modalIcon4,
    subTitle: 'Mit klarer Richtung gemeinsam vorwärtskommen',
    textArray: [
      {
        question: 'Was verstehen wir darunter?',
        statement: `Wir suchen die <b>beste Lösung</b> für unsere Kunden und richten unsere Prozesse und Tools konsequent daran aus. Wir priorisieren klar und treffen <b>mutige Entscheidungen.</b> Getroffene Entscheidungen <b>setzen wir um.</b>`,
      },
      {
        question: 'Wie kann ich das umsetzen?',
        statement: `<b>Aufwandsschätzung</b> durch das Team, um Machbarkeit zu checken und Prioritäten festzulegen

        <b>Abschied nehmen</b> von Themen, die niedrige Priorität haben
        
        <b>Ausprobieren</b> in Form von Piloten und Prototypen
        
        <b>Kunden-Galerie</b> mit Kurz-Portraits der Kunden und Kundinnen `,
      },
      {
        question: 'Warum ist das wichtig für zukunftsfähige Führung?',
        statement: `Weil wirksame Führung Prioritäten im Sinne der Kunden setzt und Mitarbeitern den Rücken freihält, um <b>fokussiert</b> zu arbeiten.

        Weil zukunftsfähige Führung <b>entscheidungsstark</b> ist und auch durch Weglassen wichtige Signale sendet. 
        
        Weil Ausprobieren wichtige Erkenntnisse für die Konzeption und <b>Nachbesserung</b> von Ergebnissen liefert.
        `,
      },
      {
        question: 'Was haben wir davon?',
        statement: `<b>Klare Priorisierung,</b> weil Fokus und Energie auf den wirklich wichtigen Themen für unsere Kunden liegen.

        <b>Tragfähigere Entscheidungen,</b> die gemeinsam getroffen werden.

        <b>Schnellere und bessere Ergebnisse,</b> da wir mutig auch mit 80% Lösungen starten, Entscheidungen erneuern und uns von unpassenden Themen verabschieden.
        `,
      },
    ],
  },
  {
    image: modalImage5,
    title: titles[4],
    icon: modalIcon5,
    subTitle: 'Gesund, robust und achtsam besser werden',
    textArray: [
      {
        question: 'Was verstehen wir darunter?',
        statement: `Wir <b>kümmern uns</b> um unsere Mitarbeitenden und um uns selbst. Wir sorgen für Zeiten zur Selbstreflexion und unseren <b>Ausgleich.</b> Wir schaffen Transparenz in Veränderungen und gehen mit <b>Unsicherheit</b> offen um.`,
      },
      {
        question: 'Wie kann ich das umsetzen?',
        statement: `<b>Etablierung neuer Routinen:</b> Gemeinsam Herausforderungen und Lösungsvorschläge im Team reflektieren.

        <b>Arbeiten im Flow:</b> Ablenkungsfreie Orte und Zeiten einrichten und einhalten.
        
        <b>Achtsamkeit üben:</b> Strategien zum Abbau von akutem Stress einsetzen.
        `,
      },
      {
        question: 'Warum ist das wichtig für zukunftsfähige Führung?',
        statement: `Weil wir ein <b>forderndes, aber nicht überforderndes Umfeld</b> schaffen wollen, in dem alle ihre Ressourcen gezielt einsetzen.

        Weil Resilienz <b>bei mir selbst beginnt</b> und Reflexion und Ausgleich dabei helfen, sich Herausforderungen zu stellen.
        
        Weil ein leistungsfähiges Unternehmen <b>gesunde Mitarbeiter</b> braucht und mitnimmt, sie aber nie „verbrennt“.
        `,
      },
      {
        question: 'Was haben wir davon?',
        statement: `<b>Bessere Nutzung unserer Stärken</b> dank gegenseitiger Unterstützung im Team und über Teamgrenzen hinaus. 

        <b>Einfacher Umgang mit Veränderung</b> dank gebündelter Widerstandskraft gegen negative Einflüsse.
        
        <b>Mehr Gelassenheit,</b> da wir es schaffen, die Ruhe und den Überblick auch in stürmischen Zeiten zu bewahren.
        
        <b>Ausgeglichene Mitarbeiter,</b> die gesund sind und bleiben
        `,
      },
    ],
  },
  {
    image: modalImage6,
    title: titles[5],
    icon: modalIcon6,
    subTitle: 'Co-creative und mit Rückenwind Neues entwickeln',
    textArray: [
      {
        question: 'Was verstehen wir darunter?',
        statement: `Gemeinsam sind wir stärker. Wir fördern cross-funktionale <b>Zusammenarbeit</b> und wollen gemeinsam mit unseren <b>Teams erfolgreich</b> sein. Wir setzen auf Dialog, Vielfalt und Vertrauen. Erfolge feiern wir gemeinsam.
        `,
      },
      {
        question: 'Wie kann ich das umsetzen?',
        statement: `<b>Stärken im Team bewusst machen:</b> klären, wie sie zum Einsatz kommen und was man voneinander lernen kann.

        <b>Gemeinsame Aktivitäten</b> wie z.B. eine virtuelle Kaffeepause
        
        <b>Besuch einer Nachbarabteilung</b> für mehr Verständnis der vor- und nachgelagerten Bereiche.
        
        <b>Design Thinking</b> als ein Tool, das unterschiedliche Kompetenzen braucht und nutzt.`,
      },
      {
        question: 'Warum ist das wichtig für zukunftsfähige Führung?',
        statement: `Weil <b>Co-Creation</b> einer der besten Wege ist, um innovative Lösungen zu entwickeln.

        Weil selbstorganisierte Teams durch Wertschätzung, Unter-stützung und Selbstbestimmung <b>viel stärker motiviert</b> werden als durch monetäre Anreize. 
        
        Weil wirksame Führung <b>Meinungs- und Perspektivenvielfalt</b> begrüßt und dafür hohe Dialogkompetenz mitbringt.`,
      },
      {
        question: 'Was haben wir davon?',
        statement: `<b>Bessere Wissensbasis,</b> da die Expertise und die Erfahrung von vielen in die Arbeit einfließt.

        <b>Mehr Stärkenorientierung,</b> indem wir prüfen, wie die Stärken im Team am besten kombiniert und eingesetzt werden können.

        <b>Mehr Perspektivenvielfalt,</b> weil Unterschiedlichkeit vorurteilsfrei als Mehrwert begrüßt und genutzt wird.

        <b>Ein positives Arbeitsklima,</b> in dem Mitarbeiter gerne arbeiten.`,
      },
    ],
  },
  {
    image: modalImage7,
    title: titles[6],
    icon: modalIcon7,
    subTitle: 'Sich und andere durch Rückmeldungen weiterbringen',
    textArray: [
      {
        question: 'Was verstehen wir darunter?',
        statement: `Wir geben und erhalten stärkenorientiertes <b>Feedback. Fehler</b> machen wir <b>transparent</b> und betrachten sie als Lernchancen für alle. Wir lassen kreative Ideen zu und heißen <b>Impulse</b> von allen willkommen.
        `,
      },
      {
        question: 'Wie kann ich das umsetzen?',
        statement: `<b>Feedback etablieren:</b> Feedback geben und nehmen üben.

        <b>Neue Methoden einführen:</b> z.B. mit regelmäßigen Retrospektiven im Team oder mit selbstorganisierten Learning Circles.

        <b>Von anderen lernen:</b> Hospitation oder kollegiales „Schatten-coaching“.
        
        <b>Ideen sammeln,</b> wie und was wir voneinander lernen können.`,
      },
      {
        question: 'Warum ist das wichtig für zukunftsfähige Führung?',
        statement: `Weil konstruktive Rückmeldung die Basis für individuelle und gemeinsame <b>Weiterentwicklung</b> ist.

        Weil gegenseitiges konstruktives Feedback - auch über <b>Fehler</b> -  zu einer modernen, wirksamen Führung auf Augenhöhe gehört. 
        
        Weil es beflügelt, wenn <b>Lernen</b> selbstbestimmt und im <b>„Entdeckermodus“</b> passieren darf und Ideen gehört werden. 
        `,
      },
      {
        question: 'Was haben wir davon?',
        statement: `<b>Mehr Weiterentwicklung</b> durch konstruktive Rückmeldung.

        <b>Mehr Klärung</b> dank positiven Feedbackkultur, in der auch schwierige Themen angstfrei angesprochen und geklärt werden.
        
        <b>Mehr Klarheit</b> weil erst durch Feedback meine Erwartung als Leader an andere von diesen umgesetzt werden kann.

        <b>Schnellere Rückkopplung,</b> weil dank zügiger Rückmeldung aus Fehlern gelernt wird.`,
      },
    ],
  },
  {
    image: modalImage8,
    title: titles[7],
    icon: modalIcon8,
    subTitle: 'Mit klarer Richtung gemeinsam vorwärtskommen',
    textArray: [
      {
        question: 'Was verstehen wir darunter?',
        statement: `Wir <b>kommunizieren</b> direkt, persönlich und ermutigend. Wir erkennen und verstehen unsere <b>Motivation</b> und die unserer Mitarbeitenden. Wir unterstützen und <b>fördern Talente</b> gezielt für morgen.
        `,
      },
      {
        question: 'Wie kann ich das umsetzen?',
        statement: `<b>Über Motivatoren austauschen: was motiviert Euch wirklich?</b>

        <b>Big Picture klären:</b> Kennt jeder seinen Beitrag dazu?
        
        <b>Sinn stiften:</b> Einen klaren Purpose formulieren.
        
        <b>Mit Storytelling überzeugen:</b> Den Purpose mit einer attraktiven Story teilen.
        `,
      },
      {
        question: 'Warum ist das wichtig für zukunftsfähige Führung?',
        statement: `Weil zukunftsfähige Führung sich durch <b>Kommunikation</b> und <b>Sinnstiftung</b> auszeichnet, die überzeugt statt durchsetzt.
 
        Weil <b>Freude an der Arbeit</b> für bessere Ergebnisse sorgt.
        
        Weil das Erkennen und <b>Nutzen der Potentiale</b> unserer Mitarbeiter Vorteile für alle bringt: Engagement und Leistung.`,
      },
      {
        question: 'Was haben wir davon?',
        statement: `<b>Motiviertere Mitarbeitenden,</b> die ihre Aufgabe gerne und gut machen und die weder über- noch unterfordert sind.
        
        <b>Sinnstiftung</b> für mich und andere.
        
        <b>Die Chance auf ein Growth Mindset</b> mit dem Vertrauen darauf, dass ich und andere sich weiterentwickeln wollen und können. 
        
        <b>Starke organisationale Resilienz,</b> da aus Überzeugung gehandelt wird und nicht aufgrund von Druck.
        `,
      },
    ],
  },
];
