import { Box, Typography } from '@mui/material';
import React from 'react';

import imageLead from '../../../assets/images/main-menu-picture-1.png';
import Button from '../../../components/Button';
import GlobalLink from '../../../components/GlobalLink';
import { ALL_OFFERS, PRIORITIES_URL, SHIFT_URL } from '../../../constants';
import { ALL_OFFERS_DATA } from '../../AllOffers/AllOffersData';

/**
 * @type {WorkshopOffer['isVisible']}
 */
export const isVisible = (userUnit) => {
  return ALL_OFFERS_DATA.some((item) => {
    return item.visible_for_unit.includes(userUnit);
  });
};

const InitiativeLead = () => (
  <Box>
    <Box
      component={'img'}
      src={imageLead}
      alt="INITIATIVE #LEAD / NEUES ARBEITEN"
      sx={{ width: '100%' }}
    />
    <Typography
      component="h1"
      variant="DarkBlueBoldCaps"
      sx={{ marginTop: 3 }}
      gutterBottom
    >
      INITIATIVE #LEAD / NEUES ARBEITEN
    </Typography>
    <Typography variant="body1" gutterBottom>
      Hier erfahren Sie Hintergründe zur Initiative #lead: Worauf sie abzielt,
      welche Schwerpunkte sie setzt und welche Angebote sie Ihnen für Ihre
      Learning Journey macht.
    </Typography>
    <Typography variant="body1">
      Außerdem sehen Sie die aktuellen Angebote zur Unterstützung im Rollout
      Neues Arbeiten.
    </Typography>
    <GlobalLink to={SHIFT_URL} text="Der Shift" active />
    <GlobalLink to={PRIORITIES_URL} text="Die Schwerpunkte" active />
    <Button sx={{ marginTop: 2 }} color="blue-grey" to={ALL_OFFERS}>
      Die Angebote im Überblick
    </Button>
  </Box>
);

/**
 * @type {WorkshopOffer}
 */
export const workshopOffer = { component: InitiativeLead, isVisible };

export default InitiativeLead;
