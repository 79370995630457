import React from 'react';

import List from '../../List';
import {
  staticSection2_cell1,
  staticSection2_cell4,
} from '../../static/static';
import './Section2.scss';

const Section2 = () => {
  return (
    <div className="section2">
      <div className="section2-cell">
        <span className="title">Veränderung ist die neue Konstante</span>
        <h2 className="title">Unser Umfeld verändert sich immer schneller</h2>
        <List
          titles={staticSection2_cell1.titles}
          content={staticSection2_cell1.content}
        />
      </div>
      <div className="section2-cell"></div>
      <div className="section2-cell"></div>
      <div className="section2-cell">
        <span className="title">Morgen so erfolgreich wie Heute</span>
        <h2 className="title">Warum wir uns weiter entwickeln wollen</h2>
        <List
          titles={staticSection2_cell4.titles}
          content={staticSection2_cell4.content}
        />
      </div>
    </div>
  );
};

export default Section2;
