/* eslint-disable import/prefer-default-export */
/**
 *
 * To configure the buttons for a specific page, add the page URL to the NAVIGATION object below.
 * If the url is not added, the buttons will fall back to the ROOT_URL configuration.
 */

import {
  ROOT_URL,
  SHIFT_URL,
  PRIORITIES_URL,
  PROFILE_URL,
  ALL_OFFERS,
  EXPLOITATION_URL,
  EXPLOITATION_OFFERS_URL,
  ANMELDEN_URL,
} from './urls';

const NO_LINK = '#';

export const NAVIGATION = {
  [ROOT_URL]: {
    menu: [],
  },
  [EXPLOITATION_URL]: {
    menu: [{ name: 'Initiative agile@betrieb&schaden', url: EXPLOITATION_URL }],
    prevLink: ROOT_URL,
    nextLink: EXPLOITATION_OFFERS_URL,
  },
  [SHIFT_URL]: {
    menu: [
      { name: 'Initiative Lead', url: SHIFT_URL },
      { name: 'Der Shift', url: NO_LINK },
    ],
  },
  [PRIORITIES_URL]: {
    menu: [
      { name: 'Initiative Lead', url: SHIFT_URL },
      { name: 'Die Schwerpunkte', url: NO_LINK },
    ],
  },
  [PROFILE_URL]: {
    menu: [],
  },
  [ANMELDEN_URL]: {
    isFooterHide: true,
    menu: [],
  },
  [ALL_OFFERS]: {
    menu: [
      { name: 'Initiative Lead', url: SHIFT_URL },
      { name: 'Alle Angebote im Überblick', url: NO_LINK },
    ],
    prevLink: PRIORITIES_URL,
    nextLink: NO_LINK,
  },
  [EXPLOITATION_OFFERS_URL]: {
    menu: [
      { name: 'Initiative agile@betrieb&schaden', url: EXPLOITATION_URL },
      { name: 'Extern moderiertes Angebot', url: EXPLOITATION_OFFERS_URL },
    ],
  },
};
