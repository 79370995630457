import { initializeApp } from 'firebase/app';
import { getAuth, connectAuthEmulator } from 'firebase/auth';
import {
  initializeFirestore,
  connectFirestoreEmulator,
} from 'firebase/firestore';
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions';

// firebaseApps previously initialized using initializeApp()

/**
 * @description firebase config
 * values gets from .env.local file for development
 * for production values gets from git-lab ci/cd env variables
 */
const firebaseConfig = {
  apiKey: `${process.env.REACT_APP_FIREBASE_API_KEY}`,
  authDomain: `${process.env.REACT_APP_FIREBASE_AUTH_DOMAIN}`,
  databaseURL: `${process.env.REACT_APP_FIREBASE_DATABASE_URL}`,
  projectId: `${process.env.REACT_APP_FIREBASE_PROJECT_ID}`,
  storageBucket: `${process.env.REACT_APP_FIREBASE_STORAGE_BUCKET}`,
  messagingSenderId: `${process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID}`,
  appId: `${process.env.REACT_APP_FIREBASE_APP_ID}`,
};

const firebaseApp = initializeApp(firebaseConfig);

const functions = getFunctions(firebaseApp, 'europe-west1');
const db = initializeFirestore(firebaseApp, {
  /**
   * Some corporate networks use using buffering proxies and we suspect this causes connectivity issues.
   * See this [issue](https://github.com/firebase/firebase-js-sdk/issues/1674) for more details.
   */
  experimentalAutoDetectLongPolling: true,
});
const auth = getAuth();

if (process.env.NODE_ENV === 'development') {
  connectFunctionsEmulator(functions, 'localhost', 5001);
  connectFirestoreEmulator(db, 'localhost', 8080);
  connectAuthEmulator(auth, 'http://localhost:9099', { disableWarnings: true });
}

export default firebaseApp;
export { functions, db, auth };
