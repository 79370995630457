import React from 'react';

import { OFFER_TYPES } from '../../../../constants';

export const InfoUser = ({ solutionsUser, offerType }) => {
  const { firstname, lastname, email } = solutionsUser;

  return (
    <div className="offer-modal-content-user-data">
      {offerType !== OFFER_TYPES.WORKSHOP_CULTURIZER && (
        <div className="offer-modal-content-user-data-item">
          <div className="paragraph">Ihr Name</div>
          <div className="paragraph-highlight">
            {`${firstname} ${lastname}`}{' '}
          </div>
        </div>
      )}
      <div className="offer-modal-content-user-data-item">
        <div className="paragraph">Ihre E-Mail-Adresse </div>
        <div className="paragraph-highlight">
          {email && email.length > 26 ? `${email.substr(0, 23)}...` : email}
        </div>
      </div>
      <div className="offer-modal-content-user-data-item">
        <div className="paragraph"></div>
        <div className="paragraph-highlight"></div>
      </div>
    </div>
  );
};

export default InfoUser;
