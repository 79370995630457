import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { TextField, NativeSelect, FormControl } from '@mui/material';
import { makeStyles } from '@mui/styles';
import deLocale from 'date-fns/locale/de';
import React from 'react';

import { OFFER_TYPES, WORKSHOPS_TYPES } from '../../../../constants';
import '../../OfferModal.scss';
import BookingsWorkshopDates from '../BookingsWorkshopDates/BookingsWorkshopDates';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: 131,
    width: 182,
    marginTop: 10,
  },
}));

export const BookingInfo = ({
  onChange,
  workshopTypes,
  offerType,
  workshopDates,
  handleDate,
  disableWeekends,
  minBookingNotice,
  locationSelector,
  handleLocationSelectorChange,
  customLocation,
  formData,
  isDatesLoading,
}) => {
  const classes = useStyles();

  const { participants, date, time, roomNo, location } = formData;
  const { formControl } = classes;
  return (
    <>
      {offerType !== OFFER_TYPES.WORKSHOP_OPEN ? (
        <div className="offer-modal-content-user-data">
          <div className="offer-modal-content-user-data-item">
            <div className="paragraph-highlight">Anzahl der WS Teilnehmer</div>
            <FormControl className={formControl} variant="standard">
              <NativeSelect
                value={participants}
                onChange={onChange}
                name="participants"
              >
                <option hidden value="" disabled>
                  Bitte auswählen
                </option>
                {Array.from(
                  {
                    length: workshopTypes.some(
                      (type) => type === WORKSHOPS_TYPES.HYBRID,
                    )
                      ? WORKSHOPS_TYPES.HYBRID.participantsCount
                      : WORKSHOPS_TYPES.PRESENCE.participantsCount,
                  },
                  (_, i) => i + 1,
                ).map((number) => (
                  <option key={number} value={number}>
                    {number}
                  </option>
                ))}
              </NativeSelect>
            </FormControl>
          </div>
          <div className="offer-modal-content-user-data-item">
            <div className="paragraph-highlight">Geplanter Termin</div>
            <FormControl className={formControl} variant="standard">
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={deLocale}>
                <KeyboardDatePicker
                  disableToolbar
                  variant="inline"
                  format="dd.MM.yyyy"
                  id="date-picker-inline"
                  value={date}
                  autoOk
                  onChange={handleDate}
                  shouldDisableDate={disableWeekends}
                  minDate={new Date(Date.now() + minBookingNotice)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
          </div>
          <div className="offer-modal-content-user-data-item">
            <div className="paragraph-highlight">Geplante Uhrzeit</div>
            <FormControl className={formControl} variant="standard">
              <NativeSelect value={time} onChange={onChange} name="time">
                <option hidden value="" disabled>
                  Bitte auswählen
                </option>
                <option value="Vormittags (9 Uhr)">Vormittags (9 Uhr)</option>
                <option value="Nachmittags (13h)">Nachmittags (14h)</option>
              </NativeSelect>
            </FormControl>
          </div>
          <div className="offer-modal-content-user-data-item">
            <div className="paragraph-highlight">Ort des Workshops</div>
            <div className="paragraph">
              Bitte wählen Sie aus der Liste der verfügbaren Standorte einen für
              Ihren Workshop aus
            </div>
            <FormControl className={formControl} variant="standard">
              <NativeSelect
                value={locationSelector}
                onChange={handleLocationSelectorChange}
                name="locationSelector"
              >
                <option hidden value="" disabled>
                  Standort
                </option>
                {countryList.map((country) => (
                  <option key={country} value={country}>
                    {country}
                  </option>
                ))}
                {customLocation && <option value="custom">Anderer Ort</option>}
              </NativeSelect>
              {locationSelector === 'custom' && (
                <TextField
                  variant="standard"
                  tabIndex="1"
                  placeholder="Bitte Ort eingeben"
                  onChange={onChange}
                  value={location}
                  style={{ marginTop: '25px' }}
                  name="location"
                />
              )}
            </FormControl>
          </div>
          <div
            className={
              workshopTypes.some((type) => type === WORKSHOPS_TYPES.HYBRID)
                ? 'offer-modal-content-user-data-item-wide'
                : 'offer-modal-content-user-data-item'
            }
          >
            <div className="paragraph-highlight">Raumnummer</div>
            <div className="paragraph">
              {workshopTypes.some((type) => type === WORKSHOPS_TYPES.HYBRID)
                ? WORKSHOPS_TYPES.HYBRID.raumnummerText
                : WORKSHOPS_TYPES.PRESENCE.raumnummerText}
            </div>
            <FormControl className={classes.formControl} variant="standard">
              <TextField
                variant="standard"
                tabIndex="1"
                onChange={onChange}
                value={roomNo}
                name="roomNo"
              />
            </FormControl>
          </div>
          <div className="offer-modal-content-user-data-item" />
        </div>
      ) : (
        <BookingsWorkshopDates
          onChange={onChange}
          date={date}
          workshopDates={workshopDates}
          classes={classes}
          isLoading={isDatesLoading}
        />
      )}
    </>
  );
};

const countryList = [
  'Berlin – Adlershof',
  'Bremen – Flughafenallee',
  'Frankfurt – Theodor-Stern-Kai',
  'Hamburg – Kapstadtring',
  'Hannover – Lange Laube',
  'Karlsruhe – Kriegsstraße',
  'Köln – Kaiser-Wilhelm-Ring',
  'Leipzig – Kontor',
  'München Unterföhring – Betrieb & Schaden',
  'München Unterföhring – Hauptverwaltung',
  'Nürnberg – Hochstraße',
  'Stuttgart – Reinsburgstraße Hauptverwaltung',
  'Stuttgart – Uhlandstraße',
];

export default BookingInfo;
