import { FormControl, Input, InputLabel } from '@mui/material';
import Alert from '@mui/material/Alert';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import { getCurrentUser, signInWithEmailAndPassword } from '../../api';
import ErrorIcon from '../../assets/svg/error-icon.svg';
import Button from '../../components/Button';
import { ANMELDEN_URL, ROOT_URL } from '../../constants';
import { userActions, authActions, companyActions } from '../../store/actions';
import './Welcome.scss';

const useStyles = makeStyles(() => ({
  bigFormControl: {
    width: 450,
    marginBottom: 26,
  },
  mediumFormControl: {
    width: 250,
    marginBottom: 26,
  },
  smallFormControl: {
    width: 150,
    marginBottom: 26,
  },
  lastFormControl: {
    width: 450,
    marginBottom: 32,
  },
  error: {
    marginBottom: 32,
    border: 1,
    borderColor: '#DC3149',
    borderStyle: 'solid',
    backgroundColor: '#FCE5EB',
    width: 416,
  },
}));

export const Welcome = ({ actions }) => {
  const [t] = useTranslation();
  const [showPopupError, setShowPopUpError] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const history = useHistory();
  const classes = useStyles();

  const RenderError = () => {
    if (!showPopupError) return <></>;
    return (
      <Alert
        className={classes.error}
        icon={<img src={ErrorIcon} alt="" />}
        severity="error"
        style={{ padding: '26px 65px 26px 65px' }}
      >
        <div className="paragraph">
          <span className="paragraph-highlight">
            {t('welcome.error.title')}
          </span>{' '}
          {t('welcome.error.text')}
        </div>
      </Alert>
    );
  };

  const onSubmit = async (e) => {
    try {
      e.preventDefault();
      if (!email || !password) setShowPopUpError(true);
      const response = await signInWithEmailAndPassword(email, password);
      const { emailVerified } = getCurrentUser();
      const authUser = response.user;
      if (emailVerified && authUser) {
        history.push(ROOT_URL);
        actions.fetchUserData(authUser.uid);
        actions.fetchUserCompany(authUser.uid);
        return;
      }
      if (authUser === null) {
        history.push(ANMELDEN_URL);
        return;
      }
      if (authUser.uid) {
        actions.fetchUserData(authUser.uid);
        actions.fetchUserCompany(authUser.uid);
      }
    } catch (error) {
      setShowPopUpError(true);
    }
  };

  const onChange = (e) => {
    e.target.name === 'email'
      ? setEmail(e.target.value)
      : setPassword(e.target.value);
  };

  return (
    <div className="welcome">
      <div className="welcome-image"></div>
      <div className="welcome-container">
        <div className="welcome-title">{t('welcome.title')}</div>
        <div
          className="paragraph"
          style={{ marginBottom: showPopupError ? '25px' : '151px' }}
        >
          {t('welcome.sub.title')}
        </div>
        <RenderError />
        <form onSubmit={onSubmit}>
          <div className="welcome-form">
            <div className="profile-form-selects-wrapper">
              <FormControl variant="standard" className="field">
                <InputLabel className="label">
                  {t('welcome.email.label')}
                </InputLabel>
                <Input name="email" value={email} onChange={onChange} />
              </FormControl>
              <FormControl variant="standard" className="field">
                <InputLabel className="label">
                  {t('welcome.password.label')}
                </InputLabel>
                <Input
                  type="password"
                  name="password"
                  autoComplete="on"
                  value={password}
                  onChange={onChange}
                />
              </FormControl>
            </div>
          </div>
          <div className="confirm-button">
            <Button type="submit" color="blue">
              {t('welcome.button')}
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

Welcome.propTypes = {
  actions: PropTypes.object.isRequired,
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        ...userActions,
        ...authActions,
        ...companyActions,
      },
      dispatch,
    ),
  },
});

const mapStateToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(Welcome);
