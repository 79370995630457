export const FETCH_USER_DATA = '@user/FETCH_USER_DATA';
export const FETCH_USER_DATA_FOR_COACHE = '@user/FETCH_USER_DATA_FOR_COACHE';
export const FETCH_USER_DATA_FULFILLED = '@user/FETCH_USER_DATA_FULFILLED';
export const FETCH_SOLUTIONS_USER_DATA = '@user/FETCH_SOLUTIONS_USER_DATA';
export const FETCH_SOLUTIONS_USER_DATA_FULFILLED =
  '@user/FETCH_SOLUTIONS_USER_DATA_FULFILLED';
export const PUSH_GOALS = '@user/PUSH_GOALS';
export const REGISTER_TO_OFFERS = '@user/REGISTER_TO_OFFERS';
export const PUSH_USER_CONTEXT_NOTES = '@user/PUSH_USER_CONTEXT_NOTES';
export const PUSH_USER_SELF_NOTES = '@user/PUSH_USER_SELF_NOTES';
export const REMOVE_USER_SELF_IDEAS = '@user/REMOVE_USER_SELF_IDEAS';
export const PUSH_USER_SELF_IDEAS = '@user/PUSH_USER_SELF_IDEAS';
export const PUSH_USER_TEAM_NOTES = '@user/PUSH_USER_TEAM_NOTES';
export const REMOVE_USER_TEAM_IDEAS = '@user/REMOVE_USER_TEAM_IDEAS';
export const PUSH_USER_TEAM_IDEAS = '@user/PUSH_USER_TEAM_IDEAS';
export const GET_USER_DATA_FULFILLED = '@user/GET_USER_DATA_FULFILLED';
export const RUN_WATCH_USER_DATA = '@user/RUN_WATCH_USER_DATA';
export const PUSH_USER_ANSWERS = '@user/PUSH_USER_ANSWERS';
export const REGISTER_USER = '@user/REGISTER_USER';
export const LOGOUT = '@user/LOGOUT';
export const SET_EMPTY_USER_DATA = '@user/SET_EMPTY_USER_DATA';
export const UNSET_EMPTY_USER_DATA = '@user/UNSET_EMPTY_USER_DATA';
