import {
  AllPossibleUnits,
  CAREERPATH_TYPES,
  LEADING_LEADERS_TYPES,
  USER_GENDERS,
} from '../../constants';

export const genderOptions = (t) => [
  {
    label: t('profile.options.gender.m'),
    value: USER_GENDERS.M,
  },
  {
    label: t('profile.options.gender.f'),
    value: USER_GENDERS.F,
  },
  {
    label: t('profile.options.gender.d'),
    value: USER_GENDERS.OTHER,
  },
];
export const unitOptions = AllPossibleUnits;
export const clusterOptions = ['AGE', 'ASE', 'AE', 'ASA'];
export const careerpathOptions = (t) => [
  {
    label: t('profile.options.careerpath.1'),
    value: CAREERPATH_TYPES.MANAGEMENT,
  },
  {
    label: t('profile.options.careerpath.2'),
    value: CAREERPATH_TYPES.CLASSIC_PROJECT_MANAGEMENT,
  },
  {
    label: t('profile.options.careerpath.3'),
    value: CAREERPATH_TYPES.AGILE_PROJECT_ROLE,
  },
  {
    label: t('profile.options.careerpath.4'),
    value: CAREERPATH_TYPES.EXPERTS,
  },
];
export const leadingLeadersOptions = (t) => [
  {
    label: t('profile.options.leadingLeaders.1'),
    value: LEADING_LEADERS_TYPES.LEADERSHIP_TEAM,
  },
  {
    label: t('profile.options.leadingLeaders.2'),
    value: LEADING_LEADERS_TYPES.STAFF_TEAM,
  },
];
