import { companyTypes } from '../actionTypes';

const initialState = {
  company: {},
};

function companyReducer(state = initialState, { type, payload }) {
  switch (type) {
    case companyTypes.FETCH_COMPANY_FULFILLED:
      return {
        ...state,
        company: payload.company,
      };
    default:
      return state;
  }
}

export default companyReducer;
