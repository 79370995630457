import * as Sentry from '@sentry/react';
import { call, fork, put, take } from 'redux-saga/effects';

import { getCompanyByUserId } from '../../api';
import { companyActions } from '../actions';
import { companyTypes } from '../actionTypes';

function* getUserCompany(uid) {
  try {
    const company = yield call(getCompanyByUserId, uid);
    yield put(companyActions.fetchUserCompanyFulfilled(company));
  } catch (err) {
    Sentry.captureException(err);
  }
}

// =====================================
//  WATCHERS
// -------------------------------------

function* watchGetUserCompany() {
  while (true) {
    const {
      payload: { uid },
    } = yield take(companyTypes.FETCH_COMPANY);
    yield fork(getUserCompany, uid);
  }
}

// =====================================
//  INIT SAGAS
// -------------------------------------

const companySaga = [fork(watchGetUserCompany)];
export default companySaga;
