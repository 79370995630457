import { authTypes } from '../actionTypes';

const initialState = {
  user: {},
  signInStartedWithCustomToken: false,
};

function authReducer(state = initialState, { type, payload }) {
  switch (type) {
    case authTypes.GET_USER_AUTH_FULFILLED:
      if (state.signInStartedWithCustomToken) {
        return {
          ...state,
        };
      }
      return {
        ...state,
        user: payload.user,
      };
    case authTypes.SIGN_IN_WITH_CUSTOM_TOKEN_FULFILLED:
      return {
        ...state,
        user: payload.user,
        signInStartedWithCustomToken: false,
      };
    case authTypes.SIGN_IN_WITH_CUSTOM_TOKEN_STARTED:
      return {
        ...state,
        signInStartedWithCustomToken: true,
      };
    default:
      return state;
  }
}

export default authReducer;
