import { Box, Typography, Stack } from '@mui/material';
import format from 'date-fns/format';
import de from 'date-fns/locale/de';
import addHours from 'date-fns/setHours';
import PropTypes from 'prop-types';

import Actions from '../../../components/Actions';
import BookingSummary from '../../../components/BookingSummary';
import { FORMAT_ONLINE } from '../Page2';
import { Page } from '../WizardLead';

export const Page4 = ({ data, onBack, onNext }) => (
  <>
    <Stack sx={{ width: '100%' }}>
      <Typography
        component="h2"
        variant="h2HeadlineGrey"
        sx={{
          marginBottom: 2,
        }}
      >
        Bitte prüfen Sie Ihre Reservierung
      </Typography>
      <BookingSummary
        data={[
          {
            label: 'Workshop',
            value: data.name,
          },
          {
            label: 'Ort',
            value: data.location.name,
            onEdit: () => onBack(Page.Location),
          },
          {
            label: 'Termin',
            value: format(
              addHours(new Date(data.date), data.time),
              "eeee, d. MMMM yyyy 'um' HH:mm 'Uhr'",
              {
                locale: de,
              },
            ),
            onEdit: () => onBack(Page.DateAndType),
          },
          {
            label: 'Teilnehmer',
            value: String(data.participants.length),
            onEdit: () => onBack(Page.Participants),
          },
          {
            label: 'Format des Workshops',
            value:
              data.format.value === FORMAT_ONLINE.value
                ? data.format.label
                : `${data.format.label}, Raum ${data.room}`,
            onEdit: () => onBack(Page.DateAndType),
          },
        ]}
      />
    </Stack>
    <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
      <Actions
        hasBackButton
        onBackButtonClick={() => onBack()}
        hasConfirmButton
        onConfirmButtonClick={onNext}
      />
    </Box>
  </>
);

Page4.pageProps = {
  data: PropTypes.array.isRequired,
  onBack: PropTypes.func,
  onNext: PropTypes.func.isRequired,
};

export default Page4;
