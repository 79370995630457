import { Box } from '@mui/material';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import './Button.scss';

const Button = ({
  sx,
  children,
  color,
  onClick,
  to,
  className,
  // TODO: `type` is used in html button api, change it to something else
  type = 'default',
  disabled = false,
  ...props
}) => {
  const history = useHistory();

  const getButtonColor = (color) => {
    switch (color) {
      case 'blue':
        return 'button-blue';
      case 'orange':
        return 'button-orange';
      case 'secondary':
        return 'button-secondary';
      case 'blue-grey':
        return 'button-blue-grey';
      case 'white-grey':
        return 'button-white-grey';
      case 'white':
        return 'button-white';
      case 'green-grey':
        return 'button-green-grey';
      default:
        return 'button-blue';
    }
  };

  const getTypeButton = (className, type) => {
    switch (type) {
      case 'outline':
        return `${className}-outline`;
      default:
        return `${className}-default`;
    }
  };

  const pushTo = (url) => {
    history?.push(url);
  };

  const openInNewWindow = (url) => {
    const newWindow = window.open(url, '_blank');
    if (newWindow) {
      newWindow.opener = null; // Prevents tabnabbing (https://cheatsheetseries.owasp.org/cheatsheets/HTML5_Security_Cheat_Sheet.html#tabnabbing)
    }
  };

  const navigateToNewLocation = (url) => {
    if (url.match(/https?:\/\//)) {
      openInNewWindow(url);
    } else {
      pushTo(url);
    }
  };

  const onClickHandler = (e) => {
    if (typeof onClick == 'function') {
      onClick(e);
    }

    if (to) {
      navigateToNewLocation(to);
    }
  };

  const isActive = useMemo(() => {
    return typeof onClick !== 'undefined' || typeof to !== 'undefined';
  }, [onClick, to]);

  return (
    <Box
      component="button"
      sx={sx}
      onClick={onClickHandler}
      className={`primary-button-text button ${getTypeButton(
        getButtonColor(color),
        type,
      )} ${className} ${isActive ? '' : 'button-inactive'}`}
      disabled={disabled}
      type={type === 'submit' ? 'submit' : null}
      {...props}
    >
      {children}
    </Box>
  );
};

Button.defaultProps = {
  className: '',
};

Button.propTypes = {
  onClick: PropTypes.func,
  to: PropTypes.oneOfType([PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  color: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
  sx: PropTypes.object,
};

export default Button;
