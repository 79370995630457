/* eslint-disable import/prefer-default-export */
import { Box, Typography } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';

export const ParticipantsListHeader = ({ children, ...props }) => {
  const theme = useTheme();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
      }}
      {...props}
    >
      <Typography
        typography={'boldParagraph'}
        sx={{ color: theme.palette.blue.primary }}
      >
        {children}
      </Typography>
    </Box>
  );
};
