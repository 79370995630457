import * as Sentry from '@sentry/react';
import { call, fork, take, put } from 'redux-saga/effects';

import { pushBooking, bookCulturizerWorkshop } from '../../api';
import { bookingActions } from '../actions';
import { bookingTypes } from '../actionTypes';

function* sendRequestOffer(data, id) {
  try {
    yield put(bookingActions.setRequestOfferStart());
    yield call(pushBooking, data, id);
    yield put(bookingActions.setRequestOfferSuccess());
  } catch (err) {
    Sentry.captureException(err);
    yield put(bookingActions.setRequestOfferError(err));
  }
}

function* sendCulturizerOffer(data) {
  try {
    yield put(bookingActions.setRequestOfferStart());
    const result = yield call(bookCulturizerWorkshop, data);
    if (!result?.projectId && !result?.email) {
      throw new Error('bookCulturizerWorkshop request failed');
    }
    yield put(bookingActions.setRequestOfferSuccess());
  } catch (err) {
    Sentry.captureException(err);
    yield put(bookingActions.setRequestOfferError(err));
  }
}

//=====================================
//  WATCHERS
//-------------------------------------

function* watchRequestOffer() {
  while (true) {
    const {
      payload: { data, id },
    } = yield take(bookingTypes.SET_REQUEST);
    yield fork(sendRequestOffer, data, id);
  }
}

function* watchCulturizerOffer() {
  while (true) {
    const { payload } = yield take(bookingTypes.SET_CULTURIZER_OFFER);
    yield fork(sendCulturizerOffer, payload);
  }
}

//=====================================
//  INIT SAGAS
//-------------------------------------

const bookingSaga = [fork(watchRequestOffer), fork(watchCulturizerOffer)];
export default bookingSaga;
