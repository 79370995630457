/* eslint-disable import/prefer-default-export */
import { NAVIGATION } from '../constants';
const ROOT_URL_MENU = { name: 'Hauptmenü', url: '/' };

/**
 * @method getNavbarLinks
 * @param {string} pathname - current path name from location
 * @returns navigation object for current page
 */
export const getNavbarLinks = (pathname) => {
  if (!NAVIGATION[pathname]) {
    throw new Error("Couldn't find navigation object");
  }
  const { menu } = NAVIGATION[pathname];
  return [ROOT_URL_MENU, ...menu];
};
