import { Snackbar, Alert, Box, Typography, Stack } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import theme from '../../../../../theme/index';
import { regexpPhone } from '../../../../../utils/Regexp';
import Actions from '../../../components/Actions/Actions';

const Page5 = ({ onBack, onNext }) => {
  const [inputValue, setInputValue] = useState('');
  const [isAlert, setIsAlert] = useState(false);
  const [, setAllValid] = useState(false);
  const onChange = (e) => {
    setInputValue(e.target.value);
    e.target.value === '' || regexpPhone.test(e.target.value)
      ? setAllValid(true)
      : setAllValid(false);
  };
  const onNavigateClick = () => {
    if (inputValue === '') setIsAlert(false);
    else if (!regexpPhone.test(inputValue)) {
      setIsAlert(true);
      return;
    }
    const currentLocalStore = JSON.parse(localStorage.getItem('workShop'));
    currentLocalStore[4] = inputValue;
    localStorage.setItem('workShop', JSON.stringify(currentLocalStore));

    onNext();
  };
  useEffect(() => {
    if (isAlert === false) setAllValid(true);
  }, [isAlert]);
  useEffect(() => {
    const currentLocalStore = JSON.parse(localStorage.getItem('workShop'));
    if (currentLocalStore.length < 5) return;
    setInputValue(currentLocalStore[4]);
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Box>
          <Typography
            component="h2"
            variant="h2HeadlineGrey"
            sx={{
              textAlign: 'center',
            }}
          >
            Bitte geben Sie Ihre Telefonnummer ein
          </Typography>
          <Box>
            <Typography
              component="p"
              varibale="paragraphHighlight"
              sx={{ marginTop: '25px' }}
            >
              Falls wir Fragen zur Organisation des Workshops haben, würden wir
              uns gerne bei Ihnen melden. Unter welcher Rufnummer können wir Sie
              erreichen? Die Angabe ist optional.
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                color: '#414141',
                gap: '36px',
                marginTop: '48px',
              }}
            >
              <Typography component="span" variant="paragraph">
                Telefonnummer (optional):
              </Typography>
              <Box
                component="input"
                type="text"
                value={inputValue}
                onChange={onChange}
                sx={{
                  width: '170px',
                  border: 'none',
                  borderBottom: '1px solid #414141',
                  color: '#414141',
                  textAlign: 'center',
                  marginBottom: '5px',
                }}
              />
            </Box>
          </Box>
          <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
            <Actions
              hasNextButton
              hasBackButton
              onNextButtonClick={() => onNavigateClick()}
              onBackButtonClick={() => onBack()}
            />
          </Box>
          <Snackbar className="phone-error-alert" open={isAlert}>
            <Alert variant="filled" severity="error">
              Als Telefonnummer bitte mindestens 7 und maximal 20 Zahlen oder +
              - ( ) . x eingeben.
            </Alert>
          </Snackbar>
        </Box>
      </Stack>
    </ThemeProvider>
  );
};
Page5.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};
export default Page5;
