import { Snackbar, Alert, Box, Typography } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import PropTypes from 'prop-types';
import React from 'react';
import { useState, useEffect } from 'react';

import theme from '../../../../../theme/index';
import Actions from '../../../components/Actions/Actions';

const Page4 = ({ onBack, onNext }) => {
  const [inputValue, setInputValue] = useState('');
  const [isAlert, setIsAlert] = useState(false);
  const [, setAllValid] = useState(false);
  const onChange = (e) => {
    setInputValue(e.target.value);
    if (!e.target.value.length) {
      setAllValid(false);
      return;
    }
    setIsAlert(false);
    setAllValid(true);
  };
  const onNavigateClick = () => {
    if (!inputValue.length) {
      setAllValid(false);
      setIsAlert(true);
      return;
    }
    const currentLocalStore = JSON.parse(localStorage.getItem('workShop'));
    currentLocalStore[3] = inputValue;
    localStorage.setItem('workShop', JSON.stringify(currentLocalStore));
    setAllValid(true);

    onNext();
  };
  useEffect(() => {
    if (JSON.parse(localStorage?.getItem('workShop')).length > 3) {
      setInputValue(JSON.parse(localStorage.getItem('workShop'))[3]);
      setAllValid(true);
    }
  }, []);
  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: '0 5% 0 5% ',
        }}
      >
        <Box>
          <Typography
            component="h2"
            variant="h2HeadlineGrey"
            sx={{
              textAlign: 'center',
            }}
          >
            Wählen Sie einen Raum für den Workshop aus
          </Typography>
          <Box>
            <Typography
              component="p"
              varibale="paragraphHighlight"
              sx={{ marginTop: '25px' }}
            >
              Sie können diesen Workshop in Präsenz oder als virtuellen Workshop
              durchführen. Eine hybride Durchführung ist nicht möglich. Für eine
              Durchführung in Präsenz buchen Sie bitte einen Raum und geben Sie
              hier die Raumnummer an. Für eine rein virtuelle Durchführung bitte
              „virtuell“ eintragen. Bitte beachten Sie die zum Zeitpunkt der
              Workshopdurchführung gültigen Corona-Regelungen (in Bezug auf
              Teilnehmeranzahl und Format).
            </Typography>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                color: '#414141',
                gap: '36px',
                marginTop: '48px',
              }}
            >
              <Typography component="span" variant="navigationLinkText">
                Raumnummer
              </Typography>
              <Box
                component="input"
                type="text"
                value={inputValue}
                onChange={onChange}
                sx={{
                  width: '170px',
                  border: 'none',
                  borderBottom: '1px solid #414141',
                  color: '#414141',
                  textAlign: 'center',
                  marginBottom: '5px',
                }}
              />
            </Box>
          </Box>
          <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
            <Actions
              hasNextButton
              hasBackButton
              onNextButtonClick={() => onNavigateClick()}
              onBackButtonClick={() => onBack()}
            />
          </Box>
          <Snackbar className="phone-error-alert" open={isAlert}>
            <Alert variant="filled" severity="error">
              Bitte geben Sie eine Raumnummer an
            </Alert>
          </Snackbar>
        </Box>
      </Box>
    </ThemeProvider>
  );
};
Page4.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};
export default Page4;
