/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react';
import { httpsCallable } from 'firebase/functions';
import { useQuery } from 'react-query';

import { functions } from '../firebaseConfig';

export const getCoaches = async (qualification) => {
  try {
    const getCoaches = httpsCallable(functions, 'semco-getCoaches');
    const { data } = await getCoaches(qualification);
    return data.data.map((d) => {
      return {
        id: d.teacher_id,
        firstname: d.teacher_firstname,
        lastname: d.teacher_lastname,
      };
    });
  } catch (err) {
    Sentry.captureException(err, {
      method: 'getCoaches',
    });
  }
};

export const useGetCoachesQuery = (qualification, options = undefined) => {
  return useQuery(
    ['getCoaches', qualification],
    async (context) => {
      const qualification = context.queryKey[1];
      const coaches = (await getCoaches(qualification)) ?? [];
      return coaches.sort((coach1, coach2) => {
        const _coach1 = `${coach1.lastname}${coach1.firstname}`;
        const _coach2 = `${coach2.lastname}${coach2.firstname}`;
        if (_coach1 < _coach2) {
          return -1;
        }
        if (_coach1 > _coach2) {
          return 1;
        }
        return 0;
      });
    },
    options,
  );
};
