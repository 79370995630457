/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react';
import { httpsCallable } from 'firebase/functions';

import { functions } from '../firebaseConfig';

export const bookCulturizerWorkshop = async (userData) => {
  try {
    const request = httpsCallable(functions, 'bookCulturizerWorkshop');
    const { data } = await request(userData);
    return data;
  } catch (err) {
    Sentry.captureException(err, {
      method: 'bookCulturizerWorkshop',
      userData,
    });
    throw err;
  }
};
