import { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { GlobalUnits } from '../../constants';
import { commonActions } from '../../store/actions';
import i18n from './config';
import { EN_LOCALE, DE_LOCALE } from './constants';

export const Translation = ({ locale, actions, storeUser }) => {
  useEffect(() => {
    const defaultLanguage = localStorage.getItem('i18nextLng');
    const language =
      locale || window.localStorage.getItem('locale') || defaultLanguage;

    localStorage.setItem('locale', language);
    i18n.changeLanguage(language);
    if (!locale) {
      actions.setLocale(language);
    }
  }, [locale]); // eslint-disable-line

  useEffect(() => {
    if (storeUser.unit) {
      const newLocale = GlobalUnits.includes(storeUser.unit)
        ? EN_LOCALE
        : DE_LOCALE;
      actions.setLocale(newLocale);
    }
  }, [storeUser.unit, actions]);

  return null;
};

const mapStateToProps = (state) => ({
  locale: state.commonStore.locale,
  storeUser: state.userStore.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        ...commonActions,
      },
      dispatch,
    ),
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Translation);
