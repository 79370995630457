/* eslint-disable import/prefer-default-export */
export const OFFER_TYPES = {
  /**
   * Dec 29th, 2021
   * --------------
   * The offer type COACHING is currently not in use in any offer. However, the logic to display
   * a modal dialog to support that offer type remains for now. We might need it in the future.
   *
   * If by end of 2022 we haven't used it, we may remove that functionality.
   * If we use that type again, we remove this comment.
   */
  COACHING: 'coaching',
  WORKSHOP_OPEN: 'workshop-open',
  WORKSHOP_CLOSED: 'workshop-closed',
  WORKSHOP_FIT4HYBRID: 'workshop-fit4hybrid',
  WORKSHOP_CULTURIZER: 'workshop-culturizer',
  WORKSHOP_NEW: 'workshop-new',
};
