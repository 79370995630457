import React from 'react';
import { useController, useFormContext } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import {
  useAddEmptyParticipantOnTouched,
  useParticipantData,
} from './ParticipantDataProvider';
import { TableCellWithError, TextInput } from './participantInput.components';

const LastNameInput = () => {
  const [t] = useTranslation();
  const { index } = useParticipantData();
  const formContext = useFormContext();

  const lastNameController = useController({
    name: `participants.${index}.lastName`,
    control: formContext.control,
  });

  useAddEmptyParticipantOnTouched(lastNameController.fieldState.isTouched);

  return (
    <TableCellWithError
      id={`participants.${index}.lastName`}
      {...lastNameController.fieldState}
      invalid={!!formContext.formState.errors['participants']?.[index]}
    >
      <TextInput
        {...lastNameController.field}
        aria-invalid={lastNameController.fieldState.invalid}
        value={lastNameController.field.value ?? ''}
        placeholder={t('form.lastName')}
      />
    </TableCellWithError>
  );
};

export default LastNameInput;
