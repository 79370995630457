/* eslint-disable import/prefer-default-export */
import * as Sentry from '@sentry/react';
import { httpsCallable } from 'firebase/functions';
import { useQuery } from 'react-query';

import { functions } from '../firebaseConfig';

export const getLocations = async () => {
  try {
    const getLocations = httpsCallable(functions, 'semco-getLocations');
    const { data } = await getLocations();
    return data.data.map((d) => {
      return {
        id: d.room_id,
        name: d.room_token,
      };
    });
  } catch (err) {
    Sentry.captureException(err, {
      method: 'getLocations',
    });
  }
};

export const useGetLocationsQuery = () => {
  return useQuery('getLocations', () => getLocations());
};
