import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import Actions from '../../../components/Actions/Actions';
import SelectDateAndTime from '../../../components/SelectDateAndTime';

const Page2 = ({ onBack, onNext, availableDates }) => {
  const onTimeClick = (timeEntry) => {
    let currentLocalStorage = JSON.parse(localStorage.getItem('workShop'));
    if (currentLocalStorage[1]?.id !== timeEntry.id) {
      currentLocalStorage = currentLocalStorage?.slice(0, 1);
      localStorage.setItem(
        'workShop',
        JSON.stringify([...currentLocalStorage, timeEntry]),
      );
    }
    onNext();
  };

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
      }}
    >
      <Typography component="h1" variant="HeadlineH4" sx={{ marginBottom: 4 }}>
        Bitte wählen Sie einen Termin aus
      </Typography>
      <SelectDateAndTime
        availableDates={availableDates}
        onSelect={onTimeClick}
      />
      <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
        <Actions hasBackButton onBackButtonClick={() => onBack()} />
      </Box>
    </Box>
  );
};

Page2.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};

export default Page2;
