import { useState } from 'react';

import { useGetCoachesQuery } from '../../../../../api';
import { renderCoachName } from '../../../components/SelectAgileCoach/SelectAgileCoach';

export const setFilterWithCoachName = ({
  coaches,
  selectedCoachId,
  setFilterValue,
}) => {
  if (selectedCoachId) {
    const coach = coaches?.find((coach) => coach.id === selectedCoachId);
    if (coach) {
      setFilterValue(renderCoachName(coach));
    }
  }
};
const useSelectAgileCoach = (
  qualification,
  selectedCoachId = undefined,
  queryOptions = undefined,
) => {
  const [filterValue, setFilterValue] = useState('');

  const getCoachesQuery = useGetCoachesQuery(qualification, {
    onSuccess: (data) => {
      setFilterWithCoachName({
        coaches: data,
        setFilterValue,
        selectedCoachId,
      });
    },
    ...queryOptions,
  });

  return {
    ...getCoachesQuery,
    coaches: getCoachesQuery.data,
    state: getCoachesQuery.status,
    filterValue,
    setFilterValue,
  };
};

export default useSelectAgileCoach;
