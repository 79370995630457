import {
  Box,
  Stack,
  Typography,
  TextField,
  Collapse,
  Alert,
} from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import add from 'date-fns/add';
import endOfToday from 'date-fns/endOfToday';
import { de } from 'date-fns/locale';
import startOfDay from 'date-fns/startOfDay';
import startOfMonth from 'date-fns/startOfMonth';
import PropTypes from 'prop-types';
import { useState } from 'react';

import Actions from '../../../components/Actions';
import DropdownWithHeadline from '../../../components/DropdownWithHeadline';
import TextFieldWithLabel from '../../../components/TextFieldWithLabel';

const FORMAT_PRESENCE = { label: 'Präsenz', value: '1' }; // Value should be a string since dropdowns return strings
const FORMAT_ONLINE = { label: 'Online', value: '2' }; // Value should be a string since dropdowns return strings

const Page2 = ({ onBack, onNext, data, fixedFormat }) => {
  // TODO: Implement a form here using react-hook-form to store this state with yup validation
  const [date, setDate] = useState(data.date ?? null);
  const [time, setTime] = useState(data.time);
  const [format, setFormat] = useState(data.format || fixedFormat);
  const [room, setRoom] = useState(data.room);
  const [showError, setShowError] = useState(false);

  return (
    <Box sx={{ width: '100%' }}>
      <Typography variant="HeadlineH4" component="h1" gutterBottom>
        Bitte wählen Sie hier Datum, Uhrzeit und Format Ihres Workshops aus.
      </Typography>
      <Collapse in={showError}>
        <Alert variant="filled" severity="error">
          Bitte alle Felder ausfüllen
        </Alert>
      </Collapse>
      <Stack
        direction="row"
        spacing={12}
        sx={{ width: '588px', margin: 'auto', marginTop: 12 }}
      >
        <LocalizationProvider locale={de} dateAdapter={AdapterDateFns}>
          <StaticDatePicker
            displayStaticWrapperAs="desktop"
            openTo="month"
            value={date}
            onChange={(newValue) => {
              setDate(startOfDay(newValue));
            }}
            views={['day']}
            renderInput={(params) => <TextField {...params} />}
            disableHighlightToday
            minDate={add(endOfToday(), { days: 7 * 3 })}
            defaultCalendarMonth={startOfMonth(
              add(endOfToday(), { days: 7 * 3 }),
            )}
          />
        </LocalizationProvider>
        <Stack spacing={4}>
          <DropdownWithHeadline
            onChange={(e) => {
              setTime(e.target.value);
            }}
            value={time}
            options={[
              { label: 'Vormittags (8 Uhr)', value: '8' },
              { label: 'Nachmittag (13 Uhr)', value: '13' },
            ]}
            title="Geplante Uhrzeit"
          />
          {!fixedFormat && (
            <DropdownWithHeadline
              onChange={(e) => {
                const { text, value } = e.target.selectedOptions[0];
                setFormat({ label: text, value });
                if (value === FORMAT_ONLINE.value) {
                  setRoom('');
                }
              }}
              value={format?.value}
              options={[FORMAT_ONLINE, FORMAT_PRESENCE]}
              title="Soll der Workshop online oder in Präsenz stattfinden?"
            />
          )}
          {format?.value === FORMAT_PRESENCE.value && (
            <TextFieldWithLabel
              label="Bitte buchen Sie einen Raum und geben die Raumnummer an"
              labelPosition="top"
              value={room}
              onChange={(e) => {
                setRoom(e.target.value);
              }}
            />
          )}
        </Stack>
      </Stack>
      <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
        <Actions
          hasBackButton
          onBackButtonClick={() => onBack()}
          hasNextButton
          onNextButtonClick={() =>
            (date && time && format?.value === FORMAT_ONLINE.value) ||
            (date && time && format?.value === FORMAT_PRESENCE.value && room)
              ? onNext({ date, time, format, room })
              : setShowError(true)
          }
        />
      </Box>
    </Box>
  );
};

Page2.propTypes = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
  data: PropTypes.shape({
    date: PropTypes.string,
    time: PropTypes.string,
    format: PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    }),
    room: PropTypes.string,
  }).isRequired,
  fixedFormat: PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string,
  }),
};

export { FORMAT_ONLINE, FORMAT_PRESENCE };
export default Page2;
