import {
  Box,
  Button,
  CircularProgress,
  Stack,
  TextField,
  Typography,
} from '@mui/material';
import * as Sentry from '@sentry/react';
import PropTypes from 'prop-types';
import { memo, useCallback } from 'react';

/**
 * @typedef TCoach
 * @type {object}
 * @property id {string}
 * @property firstname {string}
 * @property lastname {string}
 */

/**
 * @param onResult {(coach: TCoach) => void}
 * @param sx {any}
 * @param coaches {Array<TCoach>}
 * @param state {'error' | 'loading' | 'success'}
 * @param filterValue {string}
 * @param setFilterValue {React.Dispatch<string>}
 * @return {JSX.Element}
 * @constructor
 */
const SelectAgileCoach = ({
  onResult,
  sx,
  coaches = [],
  state,
  filterValue,
  setFilterValue,
}) => {
  const handleOnCoachClick = useCallback(
    /**
     * @param e {import('@mui/material/Button').ButtonProps['onClick']}
     */
    (e) => {
      try {
        const coachId = e.currentTarget.dataset?.['coachId'];
        if (coachId) {
          const _coach = coaches.find((coach) => {
            return coach.id === coachId;
          });
          if (_coach) {
            setFilterValue(renderCoachName(_coach));
            return onResult(_coach);
          }
        }
        throw new Error('Invalid coachId provided');
      } catch (err) {
        Sentry.captureException(err, {
          e,
          coaches,
          method: 'handleOnCoachClick',
        });
      }
    },
    [coaches, onResult, setFilterValue],
  );

  return (
    <Box sx={sx}>
      <TextField
        label="Name des Agile Master oder Agile Coach"
        variant="standard"
        sx={{ width: '100%' }}
        onChange={(e) => {
          setFilterValue(e.target.value);
        }}
        value={filterValue}
      />
      <Stack spacing={0} maxHeight={sx?.maxHeight} overflow="auto">
        {(() => {
          switch (state) {
            case 'error': {
              return renderMessage(
                'Beim Laden der Agile Master oder Coaches ist ein Fehler augetreten.',
              );
            }
            default:
            case 'loading': {
              return (
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    marginTop: 2,
                    minHeight: '64px',
                  }}
                >
                  <CircularProgress />
                </Box>
              );
            }
            case 'success': {
              // TODO: Move this data to the `useSelectAgileCoach` hook as `filteredCoaches` to not use the `filterValue`
              const _coaches = filterValue
                ? coaches.filter((coach) =>
                    renderCoachName(coach)
                      .toLowerCase()
                      .includes(filterValue.toLowerCase()),
                  )
                : coaches;
              if (_coaches.length === 0) {
                return renderMessage(
                  'Es wurden keine Agile Master oder Coaches gefunden.',
                );
              }
              return _coaches.map((coach) => (
                <CoachEntry
                  key={coach.id}
                  onClick={handleOnCoachClick}
                  coach={coach}
                />
              ));
            }
          }
        })()}
      </Stack>
    </Box>
  );
};

const renderMessage = (message) => {
  return (
    <Typography
      variant="boldParagraph"
      sx={{ textAlign: 'center', marginTop: 2 }}
    >
      {message}
    </Typography>
  );
};

/**
 * @type {React.NamedExoticComponent<{readonly onClick: React.MouseEvent, readonly coach: TCoach}>}
 */
const CoachEntry = memo(({ coach, onClick }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="center"
      data-coach-id={coach.id}
    >
      <Typography>{renderCoachName(coach)}</Typography>
      <Button
        onClick={onClick}
        id={coach.id}
        sx={{ textTransform: 'none' }}
        data-coach-id={coach.id}
      >
        wählen
      </Button>
    </Stack>
  );
});

SelectAgileCoach.propTypes = {
  onResult: PropTypes.func.isRequired,
  selectedCoachId: PropTypes.string,
  sx: PropTypes.object,
};

/**
 * @param coach {TCoach}
 * @return {string}
 */
export const renderCoachName = (coach) => {
  return `${coach.lastname}, ${coach.firstname}`;
};

export default SelectAgileCoach;
