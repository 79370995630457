import React, { useMemo } from 'react';

import { workshopOffer as Fit4Hybrid_workshopOffer } from './Fit4Hybrid';
import { workshopOffer as InitiativeAgile_workshopOffer } from './InitiativeAgile';
import { workshopOffer as InitiativeLead_workshopOffer } from './InitiativeLead';

/**
 * @typedef {Object} WorkshopOffer
 * @property {React.FC} component
 * @property {(userUnit: string) => boolean} isVisible
 */

/**
 * @type {WorkshopOffer[]}
 */
const workshopOfferList = [
  Fit4Hybrid_workshopOffer,
  InitiativeLead_workshopOffer,
  InitiativeAgile_workshopOffer,
];

/**
 * @property {string | null} userUnit
 * @returns {DetailedReactHTMLElement<React.HTMLAttributes<HTMLElement>, HTMLElement>[]}
 */
export const useDisplayWorkshopList = (userUnit) => {
  return useMemo(() => {
    if (!userUnit) {
      return [];
    }
    return workshopOfferList
      .map(({ component: WorkshopOffer, isVisible }) => {
        if (isVisible(userUnit)) {
          return WorkshopOffer;
        }
        return null;
      })
      .filter((WorkshopOffer) => WorkshopOffer)
      .map((WorkshopOffer, index) => {
        return React.createElement(WorkshopOffer, {
          key: `workshop-${index}`,
        });
      });
  }, [userUnit]);
};

export default useDisplayWorkshopList;
