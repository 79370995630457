import { Box, CircularProgress, Typography } from '@mui/material';
import groupBy from 'lodash/groupBy';
import PropTypes from 'prop-types';
import React, { useMemo } from 'react';

import DayEntry from './DayEntry';
import WorkshopTimeSlots from './WorkshopTimeSlots';

/**
 * @typedef {Pick<Workshop, 'id' | 'startDate'>} WorkshopDay
 */

/**
 * Renders a list of available dates for a workshop and allows the user to select a date.
 * @param {WorkshopDay[] | null} availableDates - An array of available dates for the workshop.
 * @param {(timeSlot: TimeSlot) => void} onSelect - A function to be called when a date is selected.
 * @returns {JSX.Element} - The SelectDateAndTime component.
 */
const SelectDateAndTime = ({ availableDates = null, onSelect }) => {
  /**
   * Group the available dates by date.
   * @type {Record<string, WorkshopDay[]>}
   */
  const workshopsGroupedByDate = useMemo(() => {
    const _sortedAvailableDates = availableDates?.sort((a, b) => {
      return new Date(a.startDate) - new Date(b.startDate);
    });
    return groupBy(_sortedAvailableDates ?? [], 'startDate');
  }, [availableDates]);

  if (!availableDates || !Array.isArray(availableDates)) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <CircularProgress role={'progressbar'} />
      </Box>
    );
  }

  if (availableDates.length === 0) {
    return (
      <Box
        sx={{
          margin: 'auto',
          maxWidth: '600px',
        }}
      >
        <Typography role={'alert'} variant="paragraphHighlight" align="center">
          Aktuell stehen keine freien Termine zur Verfügung.
        </Typography>
      </Box>
    );
  }

  return (
    <Box sx={{ width: '100%', height: '100%', overflowY: 'auto' }}>
      <Box
        sx={{
          display: 'grid',
          color: '#414141',
          gridTemplateColumns: 'auto 1fr',
          width: '100%',
        }}
      >
        {Object.keys(workshopsGroupedByDate).map((workshopDate) => (
          <DayEntry key={workshopDate} date={workshopDate}>
            {workshopsGroupedByDate[workshopDate].map((workshopDateEntry) => (
              <WorkshopTimeSlots
                key={workshopDateEntry.id}
                onSelect={onSelect}
                {...workshopDateEntry}
              />
            ))}
          </DayEntry>
        ))}
      </Box>
    </Box>
  );
};

SelectDateAndTime.propTypes = {
  /**
   * Available dates for querying the time slots
   */
  availableDates: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      startDate: PropTypes.string,
    }),
  ),
  /**
   * A function that is triggered when the user clicks the time slot button.
   */
  onSelect: PropTypes.func.isRequired,
};

export default SelectDateAndTime;
