import PropTypes from 'prop-types';
import React, { Fragment } from 'react';
import { Link } from 'react-router-dom';
import { useLocation } from 'react-router-dom';

import Chevron from '../../assets/icons/right-grey-chevron.png';
import { getNavbarLinks } from '../../utils/';
import './GlobalNavBar.scss';

const GlobalNavBar = ({ children }) => {
  const location = useLocation();
  let menu;
  try {
    menu = getNavbarLinks(location.pathname);
  } catch (e) {
    return <>{children}</>;
  }
  return (
    <Fragment>
      <div className="global-nav-bar">
        <div className="global-nav-bar-container">
          {menu.map((m, index) =>
            index < menu.length - 1 ? (
              <Fragment key={index}>
                <Link to={m.url}>
                  <span className="global-nav-bar-main-link paragraph">
                    {m.name}
                  </span>
                </Link>
                <img className="global-nav-bar-chevron" alt="" src={Chevron} />
              </Fragment>
            ) : (
              <span key={index} to={m.url}>
                <span className="global-nav-bar-current paragraph">
                  {m.name}
                </span>
              </span>
            ),
          )}
        </div>
      </div>
      {children}
    </Fragment>
  );
};

GlobalNavBar.propTypes = {
  children: PropTypes.node.isRequired,
};

export default GlobalNavBar;
