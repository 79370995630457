import { useEffect } from 'react';

const useFetchSolutionsUser = ({
  fetchSolutionsUserData,
  authUser,
  solutionsUser,
}) => {
  useEffect(() => {
    if (solutionsUser && Object.keys(solutionsUser).length) {
      return;
    }
    if (authUser && Object.keys(authUser).length) {
      fetchSolutionsUserData(authUser.uid);
    }
  }, [fetchSolutionsUserData, authUser, solutionsUser]);
};
export default useFetchSolutionsUser;
