import { useMemo } from 'react';

/**
 * Returns true, if this specific offer is visible to this specific user.
 *
 * @param {Object} user The user object as it is stored in the database
 * @param {Object} offer The offer object as it is stored in src/constants/AllOffersData.js
 *
 * @returns {Boolean} true or false
 */
export const isOfferVisible = (user, offer) => {
  // It is only visible if the user's cluster matches the allowed clusters of the offer
  const isVisibleToCluster =
    user.cluster &&
    offer.visible_for_cluster.filter((cl) => user.cluster.indexOf(cl) > -1)
      .length;

  // It is only visible if the user's unit matches the allowed units of the offer
  const isVisibleToUnit =
    user.unit && offer.visible_for_unit.find((u) => u === user.unit);

  // It is visible if either the offer is visible to everyone or the user is leading a leadership team
  const isVisibleToLeader =
    !offer.visible_for_leadingLeaders ||
    user.leadingLeaders === offer.visible_for_leadingLeaders;

  // Both conditions need to be met
  return !!(isVisibleToCluster && isVisibleToUnit && isVisibleToLeader);
};

/**
 * Returns true, if this specific offer is visible to this specific user.
 * @param user
 * @param offer
 * @return {boolean}
 */
export const useIsOfferVisible = (user, offer) => {
  return useMemo(() => {
    try {
      return isOfferVisible(user, offer);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  }, [user, offer]);
};

/**
 * Returns true, if this specific offer is available (=bookable) to this specific user.
 *
 * @param {Object} user The user object as it is stored in the database
 * @param {Object} offer The offer object as it is stored in src/constants/AllOffersData.js
 *
 * @returns {Boolean} true or false
 */
export const isOfferAvailable = (user, offer) => {
  // It is only available if the user's cluster matches the available clusters of the offer
  const isAvailableForCluster =
    user.cluster &&
    offer.available_for.filter((cl) => user.cluster.indexOf(cl) > -1).length;

  return !!isAvailableForCluster;
};

/**
 * Returns true, if this specific offer is available (=bookable) to this specific user.
 *
 * @param user
 * @param offer
 * @return {boolean}
 */
export const useIsOfferAvailable = (user, offer) => {
  return useMemo(() => {
    try {
      return isOfferAvailable(user, offer);
    } catch (e) {
      // eslint-disable-next-line no-console
      console.error(e);
      return false;
    }
  }, [user, offer]);
};
