import * as Sentry from '@sentry/react';
import { addHours } from 'date-fns';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import slugify from 'slugify';
import useLocalStorageState from 'use-local-storage-state';

import { pushWorkshopBooking } from '../../../../api';
import { OFFER_TYPES } from '../../../../constants';
import FullPageMessage from '../../components/FullPageMessage';
import { mapSolutionsUserToParticipant } from '../../components/ParticipantsList/ParticipantsList';
import Page1 from './Page1';
import Page2 from './Page2';
import Page3 from './Page3';
import Page4 from './Page4';
import Page5 from './Page5';
import Page6 from './Page6';

const _defaultValueParticipants = (solutionsUser) => {
  const _currentUserAsParticipant =
    mapSolutionsUserToParticipant(solutionsUser);
  if (_currentUserAsParticipant) {
    return [_currentUserAsParticipant];
  }
  return [];
};

export const WizardAllianz = ({
  name,
  durationInHours,
  onFinish,
  solutionsUser,
}) => {
  const [state, setState, { removeItem }] = useLocalStorageState(
    `WizardAllianz-${slugify(name)}`,
    {
      defaultValue: {
        currentPage: 1,
        name,
        participants: _defaultValueParticipants(solutionsUser),
      },
    },
  );

  const submitWizard = async () => {
    // We're taking some data from our state, not all
    const { name, branch, coach, format, room, location, participants } = state;

    // We convert start and end date/time of the workshop into proper date objects for easier handling
    const start = addHours(new Date(state.date), state.time);
    const end = addHours(start, durationInHours);

    // We construct the booking object based on data we need at the backend
    const bookingObject = {
      offerType: OFFER_TYPES.WORKSHOP_NEW,
      semcoDepartmentId: 1,
      name,
      branch,
      coach,
      format,
      room,
      location,
      participants,
      start,
      end,
    };

    // We push everything to Firestore
    await pushWorkshopBooking(bookingObject, {
      // And display a success message ...
      onSuccess: () => {
        setState({ ...state, currentPage: state.currentPage + 1 });
      },

      // ... or an error message
      onError: () => {
        setState({ ...state, currentPage: 'error' });
      },
    });
  };

  Sentry.addBreadcrumb({
    category: `WizardAllianz-${slugify(name)}`,
    message: `Navigated to Page ${state.currentPage}`,
    level: 'info',
    data: { state },
  });

  switch (state.currentPage) {
    case 1:
      return (
        <Page1
          onBack={onFinish}
          onNext={(branch) => {
            setState({ ...state, branch, currentPage: state.currentPage + 1 });
          }}
        />
      );
    case 2:
      return (
        <Page2
          onBack={() => {
            setState({ ...state, currentPage: state.currentPage - 1 });
          }}
          onNext={(location) => {
            setState({
              ...state,
              location,
              currentPage: state.currentPage + 1,
            });
          }}
        />
      );
    case 3:
      return (
        <Page3
          selectedCoachId={state.coach?.id}
          onBack={() => {
            setState({ ...state, currentPage: state.currentPage - 1 });
          }}
          onNext={(coach) => {
            setState({ ...state, coach, currentPage: state.currentPage + 1 });
          }}
        />
      );
    case 4:
      return (
        <Page4
          onBack={() => {
            setState({ ...state, currentPage: state.currentPage - 1 });
          }}
          onNext={(data) => {
            setState({ ...state, ...data, currentPage: state.currentPage + 1 });
          }}
          data={state}
        />
      );
    case 5:
      return (
        <Page5
          participants={state.participants}
          onBack={() => {
            setState({ ...state, currentPage: state.currentPage - 1 });
          }}
          onNext={(participants) => {
            setState({
              ...state,
              participants,
              currentPage: state.currentPage + 1,
            });
          }}
        />
      );

    case 6:
      return (
        <Page6
          data={state}
          onBack={(currentPage) => {
            setState({ ...state, currentPage });
          }}
          onNext={() => {
            submitWizard();
          }}
        />
      );

    case 7:
      return (
        <FullPageMessage
          title="Ihre Anmeldung wurde erfolgreich übermittelt."
          message="Sie bekommen in Kürze eine E-Mail von uns. Ihr Agile Master oder Coach wird ebenfalls über Ihre Buchung informiert."
          onClick={() => {
            removeItem();
            onFinish();
          }}
        />
      );

    default:
      throw new Error(`Invalid page specified: ${state.currentPage}`);
  }
};

WizardAllianz.propTypes = {
  name: PropTypes.string.isRequired,
  onFinish: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  solutionsUser: state.userStore.solutionsUser,
});
export default connect(mapStateToProps)(WizardAllianz);
