import { CircularProgress, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';

/**
 * This is a UI wrapper that provides a status indicator if data is queried by `useQuery`.
 *
 * @param status {import('react-query').QueryStatus}
 * @param errorMessage {string | undefined}
 * @param children
 * @constructor
 */
const StatusWrapper = ({
  status,
  errorMessage = 'Beim Laden ist ein Fehler aufgetreten',
  children,
}) => {
  switch (status) {
    case 'success': {
      return children;
    }
    default:
    case 'idle':
    case 'loading': {
      return (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'}>
          <CircularProgress />
        </Box>
      );
    }
    case 'error': {
      return (
        <Typography
          variant="boldParagraph"
          sx={{ textAlign: 'center', marginTop: 2 }}
        >
          {errorMessage}
        </Typography>
      );
    }
  }
};

StatusWrapper.propTypes = {
  /** The `status` returned by [useQuery](https://tanstack.com/query/v4/docs/react/reference/useQuery) */
  status: PropTypes.oneOf(['success', 'idle', 'loading', 'error']).isRequired,

  /** The error message if status is error */
  errorMessage: PropTypes.string,

  /** The children to render if status is success */
  children: PropTypes.node.isRequired,
};

export default StatusWrapper;
