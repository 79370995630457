export const staticSection1 = {
  title: `Initiative agile@betrieb&schaden`,
  content: `Erfahren Sie mehr zu den Hintergründen, zu den Zielen, den sechs agilen
    Prinzipien und dem Prozess für die Initiative agile@betrieb&schaden.`,
};
export const staticSection2_cell1 = {
  titles: [
    'Veränderung ist die neue Konstante',
    'Unser Umfeld verändert sich immer schneller',
  ],
  content: [
    `Kunden wünsche sich einfache, schnelle und passende Lösungen in
      allen Lebenslagen. Dazu gehört zum Beispiel: Banking, Einkaufen,
      Reisen aber auch die Pfege neuer Kontakte.`,
    `In immer kürzeren Abständen entstehen neue Produkte, neue
      Wettbewerber, neue Technologien, aber auch Veränderungen durch neue
      politische Vorgaben.`,
  ],
};

export const staticSection2_cell4 = {
  titles: [
    'Morgen so erfolgreich wie Heute',
    'Warum wir uns weiter entwickeln wollen',
  ],
  content: [
    `Viele unserer Kunden erleben uns noch als unflexibel und unnahbar`,
    `Wir machen uns das Leben schwer – mit vielen Regeln, komplizierten Prozessen und langen Entscheidungswegen`,
    `Manchmal fällt es uns schwer, mehr Verantwortung an unsere Mitarbeiter:innen abzugeben`,
    `Wir sind nicht anpassungsfähig genug, um auch auf radikalere Veränderungen zu reagieren`,
    `Wir nutzen noch nicht konsequent genug die Erfahrungen und die Expertise unserer Mitarbeiter:innen`,
    `Viele Mitarbeiter:innen möchten ihren Arbeitsalltag selbstbestimmter gestalten`,
  ],
};

export const staticSection3_cell2 = {
  content: [
    'anpassungsfähiger werden',
    'das beste Kundenerlebnis bieten',
    'mehr Gestaltungsmöglichkeiten für jede/n Einzelne/n',
  ],
};
export const staticSection3_cell3 = {
  content: [
    `Agilität ist mehr als die Einführung neuer Methoden. Es geht um ein erweitertes Mindset, mit dem wir unsere tägliche Arbeit flexibler und mit noch mehr Freude erledigen – zusammen im Team oder mit dem Kunden.`,
    `Anpassungsfähig beim Eingehen auf Kundenwünsche, z.B. indem wir den Kunden in Lösungsmöglichkeiten einbinden, Entscheidungsspielräume schaffen und nutzen und nach Feedback fragen.`,
    `Anpassungsfähig in Bezug auf Fallbearbeitung und Prozesse, indem wir versuchen, den Kunden positiv zu überraschen und z.B. bereits im ersten Kontakt eine Lösung für ihn finden.`,
  ],
};
export const staticSection4 = [
  {
    title: `Eigenverantwortung`,
    content: `Wissen teilen, Gelerntes transparent machen und zu Fehlern stehen`,
  },
  {
    title: `Kundenzentrierung
& Ergebnisorientierung`,
    content: `Denken und Handeln gehen vom Endkunden bzw. von unseren Zielen aus.`,
  },
  {
    title: `Priotisierung & Fokussierung`,
    content: `Priosieren nach Wichtigkeit unserer Ziele und Fokussieren auf die jeweils relevanten Themen`,
  },
  {
    title: `Teamgeist`,
    content: `Priosieren nach Wichtigkeit unserer Ziele und Fokussieren auf die jeweils relevanten Themen`,
  },
  {
    title: `Feedback & Lernen`,
    content: `Wissen teilen, Gelerntes transparent machen und zu Fehlern stehen.`,
  },
  {
    title: `Risikobereitschaft`,
    content: `Ausprobieren, neugierig und mutig sein`,
  },
];

export const staticSection5Columns = [
  {
    id: 1,
    title: `Individuelle Ebene`,
    content: `An den persönlichen Bedarf angepasste Selbstlernkurse und Learning Nuggets für alle Mitarbeiter:innen und Führungskräfte.
    Diese finden Sie in Form von agilen Lernpfaden auf der AllianzU Lernplattform.`,
    list: [
      `AZ_DE Agilität im Arbeitsalltag - erste Schritte`,
      `AZ_DE Agile@ Betrieb und Schaden - Lernpfad für Mitarbeiter:innen`,
      `Diese finden Sie in Form von agilen Lernpfaden auf der AllianzU Lernplattform`,
    ],
  },
  {
    id: 2,
    title: `Teamebene`,
    content: `Gemeinsame Learning Journey für die Entwicklung hin zu einer agil(er)en Organisation.

    Vier Teamworkshops mit den Schwerpunkten:
    `,
    list: [
      `Zusammenarbeit im Team`,
      `Agile Werte und Arbeitsweisen`,
      `Interne- und Geschäftsprozesse`,
      `Kundenfokus (optional)`,
    ],
  },
  {
    id: 3,
    title: `Organisationale Ebene`,
    content: `Begleitung der agilen Transformation durch agile Coaches und agile Master sowie eine wachsende agile Community.
    Zusätzlich arbeiten wir an der Schaffung begleitender Rahmenbedingungen, die die agile Transformation unterstützen.
    `,
  },
];
