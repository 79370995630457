import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../../Button';

const FullPageMessage = ({ title, message, onClick }) => (
  <Box
    sx={{
      margin: 'auto',
      width: 600,
    }}
  >
    <Typography component="h1" variant="h1Small" align="center">
      {title}
    </Typography>
    <Typography align="center" sx={{ marginY: 4 }}>
      {message}
    </Typography>
    {onClick && (
      <Button
        id={'close'}
        sx={{ margin: 'auto' }}
        color="green-grey"
        onClick={onClick}
      >
        Fenster schließen
      </Button>
    )}
  </Box>
);

FullPageMessage.propTypes = {
  /** The title of the message in bold letters. */
  title: PropTypes.string.isRequired,

  /** The main message to the user. */
  message: PropTypes.string.isRequired,

  /** The function being called when the user clicks the main button */
  onClick: PropTypes.func,
};
export default FullPageMessage;
