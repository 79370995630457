import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';
import React from 'react';

import closeIcon from '../../assets/icons/X.png';
import { KeyCodes } from '../../constants';
import Button from '../Button';
import './PrioritiesModal.scss';

const PrioritiesModal = ({
  open,
  onClose,
  subTitle,
  title,
  icon,
  image,
  textArray,
}) => {
  const closeModalOnEsc = (event) => {
    if (event.keyCode === KeyCodes.ESC || event.keyCode === KeyCodes.ENTER) {
      onClose();
    }
  };

  return (
    <Modal
      onKeyDown={closeModalOnEsc}
      open={open}
      onClose={onClose}
      className="priorities-modal"
    >
      <div className="priorities-modal-content">
        <img className="priorities-modal-content-image" src={image} alt="" />
        <div className="priorities-modal-content-close" onClick={onClose}>
          <img src={closeIcon} alt="" />
        </div>
        <img className="priorities-modal-content-icon" src={icon} alt="" />
        <div className="priorities-modal-content-container">
          <h1 className="priorities-modal-content-title h1-small">{title}</h1>
          <h2 className="priorities-modal-content-sub-title h2-headline">
            {subTitle}
          </h2>
          <div className="priorities-modal-content-text-container">
            {textArray.map((text) => (
              <div
                className="priorities-modal-content-text-container-item"
                key={text.question}
              >
                <div className="priorities-modal-content-question paragraph-blue">
                  {text.question}
                </div>
                <div
                  dangerouslySetInnerHTML={{ __html: text.statement }}
                  className="priorities-modal-content-statement paragraph-small"
                ></div>
              </div>
            ))}
          </div>
        </div>
        <div className="priorities-modal-content-button-wrapper">
          <Button onClick={onClose} color="blue" type="outline">
            seite schliessen
          </Button>
        </div>
      </div>
    </Modal>
  );
};

PrioritiesModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.node.isRequired,
  image: PropTypes.node.isRequired,
  subTitle: PropTypes.string.isRequired,
  textArray: PropTypes.array.isRequired,
};

export default PrioritiesModal;
