import {
  NativeSelect,
  FormControl,
  Stack,
  ThemeProvider,
  Typography,
} from '@mui/material';
import PropTypes from 'prop-types';

import theme from '../../../../theme';

const DropdownWithHeadline = ({ title, options, onChange, name, value }) => {
  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Typography variant="paragraphHighlight">{title}</Typography>
        <FormControl variant="standard" fullWidth>
          <NativeSelect
            value={value ?? ''}
            onChange={onChange}
            name={name}
            inputProps={{ title }}
          >
            <option hidden value="" disabled>
              Bitte wählen
            </option>
            {options.map((option) => (
              <option
                key={option?.value ?? option}
                value={option?.value ?? option}
              >
                {option?.label ?? option}
              </option>
            ))}
          </NativeSelect>
        </FormControl>
      </Stack>
    </ThemeProvider>
  );
};

DropdownWithHeadline.propTypes = {
  title: PropTypes.string.isRequired,
  options: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(
      PropTypes.shape({ label: PropTypes.string, value: PropTypes.string }),
    ),
  ]).isRequired,
  onChange: PropTypes.func.isRequired,
  name: PropTypes.string,
  value: PropTypes.string,
};

export default DropdownWithHeadline;
