import { createContext, useContext, useEffect } from 'react';

import { emptyParticipantData } from '../ParticipantsList';

const ParticipantDataContext = createContext({});

const ParticipantDataProvider = ({
  index,
  participantsFieldArray,
  children,
}) => {
  return (
    <ParticipantDataContext.Provider value={{ index, participantsFieldArray }}>
      {children}
    </ParticipantDataContext.Provider>
  );
};

export const useParticipantData = () => {
  const participantDataContext = useContext(ParticipantDataContext);

  if (
    participantDataContext.participantsFieldArray.fields.length - 1 ===
    participantDataContext.index
  ) {
    const handleAppendEmptyParticipant = () => {
      participantDataContext.participantsFieldArray.append(
        emptyParticipantData,
      );
    };

    return {
      ...participantDataContext,
      handleAppendEmptyParticipant,
    };
  }

  return { ...participantDataContext, handleAppendEmptyParticipant: null };
};

export const useAddEmptyParticipantOnTouched = (isTouched) => {
  const { handleAppendEmptyParticipant } = useParticipantData();
  useEffect(() => {
    if (isTouched) {
      handleAppendEmptyParticipant?.();
    }
  }, [handleAppendEmptyParticipant, isTouched]);
};

export default ParticipantDataProvider;
