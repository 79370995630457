import * as Sentry from '@sentry/react';
import {
  signOut,
  signInWithEmailAndPassword as signIn,
  signInWithCustomToken as signInWithToken,
  onAuthStateChanged as onAuthChanged,
} from 'firebase/auth';
import { doc, getDoc, collection, getDocs, query } from 'firebase/firestore';

import { db, auth } from '../firebaseConfig';
import { getUserSalutationId } from '../utils';

const COLLECTION_NAME = 'users';

/**
 * @method signInWithEmailAndPassword
 * @param {string} email - admin email
 * @param {string} password - admin password
 * @summary sign in to app using email and password
 * @returns error or user info
 */
export const signInWithEmailAndPassword = async (email, password) => {
  try {
    const authUser = await signIn(auth, email, password);
    return authUser;
  } catch (err) {
    Sentry.captureException(err, {
      method: 'signInWithEmailAndPassword',
      userEmail: email,
    });
  }
};

/**
 * @method logout
 * @summary logout admin
 * @returns nothing
 */
export const logout = async () => {
  try {
    await signOut(auth);
  } catch (err) {
    Sentry.captureException(err, { method: 'logout' });
  }
};

/**
 * @method getCurrentUser
 * @summary get current authenticated  user
 * @returns user
 */
export const getCurrentUser = () => auth.currentUser;

/**
 * @method signInWithCustomToken
 * @param {string} token - user token
 * @summary sign in to app using user token
 * @returns error or user info
 */
export const signInWithCustomToken = (token) => {
  return signInWithToken(auth, token);
};

/**
 * @method getUserData
 * @param {string} uid - user uid
 * @summary getting users data
 * @returns {object}
 */
export const getUserData = (uid) => {
  const userRef = doc(db, COLLECTION_NAME, uid);
  return getDoc(userRef);
};

export const getCurrentUserWithSalutationId = async () => {
  const currentUser = getCurrentUser();
  const userData = await getUserData(currentUser.uid);
  const user = userData.data();

  const result = {
    email: user.email,
    firstName: user.firstname,
    lastName: user.lastname,
    salutationId: getUserSalutationId(user.sex),
  };

  return result;
};

export const onAuthStateChanged = (callback) => {
  return onAuthChanged(auth, callback);
};

/**
 * @function getAllUsers
 * @description fetch all users in the app and return them
 * @returns {Promise<Array>} all users or empty array
 */
export const getAllUsers = async () => {
  try {
    const usersRef = collection(db, COLLECTION_NAME);
    const usersSnap = await getDocs(query(usersRef));
    if (usersSnap.empty) {
      return [];
    }
    return usersSnap.docs.map((user) => ({
      ...user.data(),
      id: user.id,
    }));
  } catch (err) {
    Sentry.captureException(err, { method: 'getAllUsers' });
  }
};

/**
 * @function getAllQuestionnaireUsers
 * @description fetch all questionnaire users in the app and return them
 * @returns {Promise<Array>} all questionnaire users or empty array
 */
export const getAllQuestionnaireUsers = async () => {
  const usersRef = collection(db, 'questionnaireApp');
  const usersSnap = await getDocs(query(usersRef));
  if (usersSnap.empty) {
    return [];
  }
  return usersSnap.docs.map((snap) => ({
    id: snap.id,
    ...snap.data(),
  }));
};
