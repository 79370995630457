import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { leadingLeadersOptions } from '../../containers/Profile/ProfileOptions';

/**
 * Outputs a text that informs the user about the details he has entered during sign up.
 * These values can be updated by visiting the [profile sign up page](http://localhost:3000/profile).
 */
const UserInfoText = ({
  cluster = 'NICHT ANGEGEBEN',
  unit = 'NICHT ANGEGEBEN',
  leadingLeaders,
}) => {
  const [t] = useTranslation();
  return (
    <>
      Das Angebot basiert auf Ihren Angaben. Sie arbeiten bei der {unit} im
      Cluster {cluster} und führen ein{' '}
      {leadingLeadersOptions(t).find((item) => item.value === leadingLeaders)
        ?.label || 'NICHT ANGEGEBEN'}
      . Sprechen Sie uns an, sollten die Angaben falsch sein.
    </>
  );
};

UserInfoText.propTypes = {
  cluster: PropTypes.string.isRequired,
  unit: PropTypes.string.isRequired,
  leadingLeaders: PropTypes.oneOf(['Leadership team', 'Staff team']).isRequired,
};

export default UserInfoText;
