import { Stack, Link, Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Link as RouterLink } from 'react-router-dom';

import Chevron from '../../assets/icons/right-grey-chevron.png';
import { ROOT_URL } from '../../constants/urls';

const Breadcrumps = ({ breadcrumps }) => {
  return (
    <Stack
      direction="row"
      alignItems="center"
      spacing={0}
      divider={
        <Box
          component="img"
          sx={{ height: '11px', width: '6px', margin: '0 10px' }}
          alt=""
          src={Chevron}
        />
      }
      sx={{ marginY: 4 }}
    >
      <Link
        component={RouterLink}
        to={ROOT_URL}
        underline="none"
        color="grey.2"
      >
        Hauptmenü
      </Link>
      {breadcrumps.map((crump) => {
        if (crump.label && crump.url) {
          return (
            <Link
              key={crump.label}
              component={RouterLink}
              to={crump.url}
              underline="none"
              color="grey.2"
            >
              {crump.label}
            </Link>
          );
        } else {
          return (
            <Typography sx={{ color: 'blue.primary' }} key={crump.label}>
              {crump.label}
            </Typography>
          );
        }
      })}
    </Stack>
  );
};

Breadcrumps.propTypes = {
  breadcrumps: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      url: PropTypes.string,
    }),
  ),
};

export default Breadcrumps;
