import { createTheme } from '@mui/material/styles';

import defineTypography from './typography';

const theme = createTheme({
  palette: {
    grey: {
      2: '#5d5d5d',
      6: '#d9d9d9',
      10: '#cecece',
      navyGrey: '#414141',
    },
    common: {
      white: '#ffffff',
      black: '#000000',
      red: '#DC3149',
      invalid: '#FCE5EB',
    },
    blue: {
      primary: '#007ab3',
      dark: '#003781',
    },
    primary: {
      main: '#007ab3',
    },
  },
  components: {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          paragraph: 'p',
          paragraphHighlight: 'p',
          h2HeadlineGrey: 'h2',
          OfferHeadlineBlue: 'h3',
        },
      },
    },
  },
});

defineTypography(theme);

export default theme;
