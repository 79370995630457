import React from 'react';

import { WORKSHOPS_TYPES } from '../../../../constants';

const ModalDescriptionText = ({ workshopTypes }) => {
  const types = workshopTypes.map((item) => item.title);
  const render = () => {
    if (
      types.includes(WORKSHOPS_TYPES.PRESENCE.title) &&
      types.includes(WORKSHOPS_TYPES.VIRTUAL.title) &&
      types.includes(WORKSHOPS_TYPES.HYBRID.title)
    ) {
      return (
        <>
          Sie können diesen Workshop als präsenz, virtuellen oder als hybriden
          Workshop durchführen. Bitte beachten Sie die zum Zeitpunkt der
          Workshopdurchführung gültigen Corona-Regelungen (im Bezug auf
          Teilnehmeranzahl und Format).
        </>
      );
    } else if (
      types.includes(WORKSHOPS_TYPES.VIRTUAL.title) &&
      types.includes(WORKSHOPS_TYPES.PRESENCE.title)
    ) {
      return (
        <>
          Sie können diesen Workshop in Präsenz oder als virtuellen Workshop
          durchführen. Bitte beachten Sie die zum Zeitpunkt der
          Workshopdurchführung gültigen Corona-Regelungen (im Bezug auf
          Teilnehmeranzahl und Format).
        </>
      );
    } else if (types.includes(WORKSHOPS_TYPES.VIRTUAL.title)) {
      return (
        <>
          Bei dem Angebot handelt es sich um ein offenes und virtuelles Format.
          Alle Leader und Projektleiter können sich für dieses Angebot anmelden.
          Bei jedem der angebotenen Termine ist die Teilnehmeranzahl begrenzt.
          Sollte die maximale Teilnehmeranzahl überschritten sein, ist der
          Termin nicht mehr buchbar. Bitte wählen Sie einen anderen Termin.
        </>
      );
    }
  };
  return <>{render()}</>;
};

export default ModalDescriptionText;
