import { Typography } from '@mui/material';
import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import AgilePrinzipienUndArbeitsweisen1 from '../../assets/images/offers/160x200/AgilePrinzipienUndArbeitsweisen1.png';
import AgilePrinzipienUndArbeitsweisen2 from '../../assets/images/offers/160x200/AgilePrinzipienUndArbeitsweisen2.png';
import Culturizer from '../../assets/images/offers/160x200/Culturizer.png';
import CulturizerVideo from '../../assets/images/offers/160x200/CulturizerVideo.png';
import KundenUndBegeisterung from '../../assets/images/offers/160x200/KundeUndBegeisterung.png';
import ZusammenarbeitImTeam3hrs from '../../assets/images/offers/160x200/ZusammenarbeitImTeam3hrs.png';
import ZusammenarbeitImTeam4hrs from '../../assets/images/offers/160x200/ZusammenarbeitImTeam4hrs.png';
import DurationHour from '../../assets/svg/journey-one-hour.svg';
import DurationHalfDay from '../../assets/svg/journey_duration_half_day.svg';
import BookingWizard from '../../components/BookingWizard';
import Breadcrumps from '../../components/Breadcrumps';
import OfferCard from '../../components/OfferCard';
import OfferCardContainer from '../../components/OfferCard/components/OfferCardContainer';
import { VERFUGBAR_BUTTON } from '../../components/OfferCard/OfferCard';
import { OFFNEN_BUTTON } from '../../components/OfferCard/OfferCard';
import { GermanyUnits } from '../../constants';
import { EXPLOITATION_URL } from '../../constants';
import { WORKSHOPS_TYPES } from '../../constants/';
import useFetchSolutionsUser from '../../effects/useFetchSolutionsUser';
import { userActions, modalActions } from '../../store/actions';

// TODO:
//  Because of the lack of agileMasterCoachOffersList we cannot simply "map"
//  visible for units array as we did for the AllOffersData
export const visibleForUnits = GermanyUnits;

// TODO: Change this to the real list of available workshops instead of "copy-paste" five times

const AgileMasterCoachOffers = ({ solutionsUser, authUser, actions }) => {
  useFetchSolutionsUser({ ...actions, solutionsUser, authUser });
  const [isWizardOpen, setIsWizardOpen] = useState(false);
  const [wizard, setWizard] = useState('');

  const openWizard = (title) => {
    setWizard(title);
    setIsWizardOpen(true);
  };

  return (
    <>
      <Breadcrumps
        breadcrumps={[
          { label: 'Initiative agile@betrieb&schaden', url: EXPLOITATION_URL },
          { label: 'Durch Allianz moderierte Angebote' },
        ]}
      />
      <div className="journey-recommendations-container themself-recomendations">
        <Typography component="h1" variant="h1Bold" sx={{ marginBottom: 6 }}>
          Buchen Sie einen Workshop mit einem Agile Master oder Agile Coach der
          Allianz
        </Typography>
        <Typography variant="body1" gutterBottom>
          Buchen Sie hier einen Workshop mit einem Agile Master oder Coach der
          Allianz. Bitte buchen Sie auch jeden Folgeworkshop, den Sie mit einem
          Agile Master oder Coach durchführen, wieder hier auf der
          Buchungsplattform.
        </Typography>
        <Typography variant="paragraphHighlight" component="p" marginTop={2}>
          Wichtige Hinweise zu Ihrer Buchung
        </Typography>
        <ul>
          <li>
            <Typography>
              Um eine Buchung durchführen zu können, klären Sie bitte im Vorfeld
              ab, mit welchem Agile Master oder Agile Coach Sie Ihren Workshop
              durchführen werden.
            </Typography>
          </li>
          <li>
            <Typography>
              Bitte vereinbaren Sie bereits im Vorfeld Ihrer Buchung mit Ihrem
              Agile Master oder Agile Coach den konkreten Termin, an dem Ihr
              Workshop stattfinden soll und geben Sie diesen während der Buchung
              an.
            </Typography>
          </li>
        </ul>
        <div className="journey-recommendations-all">
          <OfferCardContainer>
            <OfferCard
              item={{
                offerName: 'allianz-zit1',
                title: 'Zusammenarbeit im Team 1',
                available_for: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_unit: visibleForUnits,
                visible_for_leadingLeaders: '',
                image_small: ZusammenarbeitImTeam4hrs,
                text: `Starten Sie mit Ihrem Team die agile Lernreise. ​In einem halbtägigen Workshop lernen Sie die agilen 6 und unterschiedliche Perspektiven auf das Team kennen und planen Experimente für die Zusammenarbeit im Team.`,
                firstButton: VERFUGBAR_BUTTON,
                duration: {
                  text: '1/2 Tag',
                  icon: DurationHalfDay,
                },
                workshopTypes: [
                  WORKSHOPS_TYPES.PRESENCE,
                  WORKSHOPS_TYPES.VIRTUAL,
                ],
              }}
              handleOpenOffer={openWizard}
            />
            <OfferCard
              item={{
                offerName: 'allianz-culturizer',
                title: 'Culturizer',
                available_for: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_unit: visibleForUnits,
                visible_for_leadingLeaders: '',
                image_small: Culturizer,
                text: 'Gemeinsam mit Ihrem Team führen Sie eine web-basierte Standortbestimmung durch.',
                firstButton: VERFUGBAR_BUTTON,
                duration: {
                  text: '1/2 Tag',
                  icon: DurationHalfDay,
                },
                workshopTypes: [
                  WORKSHOPS_TYPES.PRESENCE,
                  WORKSHOPS_TYPES.VIRTUAL,
                ],
              }}
              handleOpenOffer={openWizard}
            />
            <OfferCard
              item={{
                offerName: 'allianz-zit2',
                title: 'Zusammenarbeit im Team 2',
                available_for: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_unit: visibleForUnits,
                visible_for_leadingLeaders: '',
                image_small: ZusammenarbeitImTeam3hrs,
                text: 'In einem dreistündigen Workshop führen Sie mit Ihrem Team eine Retrospektive der bisherigen Lernreise durch und leiten daraus Verbesserungen für die Zusammenarbeit im Team ab.',
                firstButton: VERFUGBAR_BUTTON,
                duration: {
                  text: '3 Std.',
                  icon: DurationHalfDay,
                },
                workshopTypes: [
                  WORKSHOPS_TYPES.PRESENCE,
                  WORKSHOPS_TYPES.VIRTUAL,
                ],
              }}
              handleOpenOffer={openWizard}
            />
            <OfferCard
              item={{
                offerName: 'allianz-agile1',
                title: 'Agile Prinzipien und Arbeitsweisen 1',
                available_for: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_unit: visibleForUnits,
                visible_for_leadingLeaders: '',
                image_small: AgilePrinzipienUndArbeitsweisen1,
                text: 'In einem halbtägigen Workshop prüfen Sie und Ihr Team gemeinsam, wo Sie aktuell auf Ihrer Lernreise stehen und befassen sich eingehend mit allen Facetten der Agilität.',
                firstButton: VERFUGBAR_BUTTON,
                duration: {
                  text: '1/2 Tag',
                  icon: DurationHalfDay,
                },
                workshopTypes: [
                  WORKSHOPS_TYPES.PRESENCE,
                  WORKSHOPS_TYPES.VIRTUAL,
                ],
              }}
              handleOpenOffer={openWizard}
            />
            <OfferCard
              item={{
                offerName: 'allianz-agile2',
                title: 'Agile Prinzipien und Arbeitsweisen 2',
                available_for: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_unit: visibleForUnits,
                visible_for_leadingLeaders: '',
                image_small: AgilePrinzipienUndArbeitsweisen2,
                text: 'In einem halbtätigen Workshop knüpfen Sie an die Ergebnisse der Experimente aus Agile Prinzipien und Arbeitsweisen 1 an und fokussieren sich auf die agilen Prinzipien Risikobereitschaft und Eigenverantwortung.',
                firstButton: VERFUGBAR_BUTTON,
                duration: {
                  text: '1/2 Tag',
                  icon: DurationHalfDay,
                },
                workshopTypes: [
                  WORKSHOPS_TYPES.PRESENCE,
                  WORKSHOPS_TYPES.VIRTUAL,
                ],
              }}
              handleOpenOffer={openWizard}
            />
            <OfferCard
              item={{
                offerName: 'kunde-begeisterung-1',
                title: 'Kunde und Begeisterung 1',
                available_for: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_unit: visibleForUnits,
                visible_for_leadingLeaders: '',
                image_small: KundenUndBegeisterung,
                text: 'In einem halbtägigen Workshop erfährt ihr Team wie Begeisterung entsteht, reflektiert die eigene Haltung gegenüber dem Kunden und lernt, Kundenbedürfnisse zu erkennen und Kundenbegeisterung zu initiieren.',
                firstButton: VERFUGBAR_BUTTON,
                duration: {
                  text: '1/2 Tag',
                  icon: DurationHalfDay,
                },
                workshopTypes: [WORKSHOPS_TYPES.PRESENCE],
              }}
              handleOpenOffer={openWizard}
            />
            <OfferCard
              item={{
                offerName: 'kunde-begeisterung-2',
                title: 'Kunde und Begeisterung 2',
                available_for: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_unit: visibleForUnits,
                visible_for_leadingLeaders: '',
                image_small: KundenUndBegeisterung,
                text: 'In einem halbtägigen Workshop reflektiert das Team die eigenen Stärken und Erfolgsfaktoren im telefonischen Kundentelefonat, um situationsgerecht und stärkenorientiert agieren zu können.',
                firstButton: VERFUGBAR_BUTTON,
                duration: {
                  text: '1/2 Tag',
                  icon: DurationHalfDay,
                },
                workshopTypes: [
                  WORKSHOPS_TYPES.PRESENCE,
                  WORKSHOPS_TYPES.VIRTUAL,
                ],
              }}
              handleOpenOffer={openWizard}
            />
            <OfferCard
              item={{
                offerName: 'Culturizer Video',
                title: 'Culturizer Video',
                available_for: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_unit: GermanyUnits,
                visible_for_leadingLeaders: '',
                image_small: CulturizerVideo,
                text: 'Interessieren Sie sich für den Culturizer? In diesem Video wird der Culturizer kurz erklärt.',
                firstButton: OFFNEN_BUTTON,
                link: 'https://allianzms.sharepoint.com/sites/DE1478-connect-azd-lernen-fuer-fuehrungskraefte',
                duration: {
                  text: '10 Minuten / Tag',
                  icon: DurationHour,
                },
                workshopTypes: [WORKSHOPS_TYPES.DIGITAL],
              }}
              handleOpenOffer={openWizard}
            />
            {wizard && (
              <BookingWizard
                wizard={wizard}
                open={isWizardOpen}
                onClose={() => setIsWizardOpen(false)}
              />
            )}
          </OfferCardContainer>
        </div>
      </div>
    </>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        ...userActions,
        ...modalActions,
      },
      dispatch,
    ),
  },
});

const mapStateToProps = (state) => ({
  storeUser: state.userStore.user,
  authUser: state.authStore.user,
  solutionsUser: state.userStore.solutionsUser,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AgileMasterCoachOffers);
