import { CircularProgress } from '@mui/material';
import { useCallback, useContext } from 'react';

import { useGetDateAndTimeForWorkshop } from '../../../../api';
import Button from '../../../Button';
import { DayContext } from './DayEntryProvider';

const WorkshopTimeSlots = ({ onSelect, ...workshopDateEntry }) => {
  const { dayState, dayDispatch } = useContext(DayContext);
  const getDateAndTimeSlotsQuery = useGetDateAndTimeForWorkshop(
    workshopDateEntry,
    {
      onSuccess: (newTimeSlots) => {
        dayDispatch({
          type: 'addHours',
          hours: newTimeSlots.map((timeSlot) => ({
            id: timeSlot.id,
            value: timeSlot.value,
          })),
        });
      },
    },
  );

  const handleTimeSlotOnClick = useCallback(
    (e) => {
      const id = e.currentTarget.dataset['id'];
      const timeSlot = getDateAndTimeSlotsQuery.data.find(
        (timeSlot) => timeSlot.id === id,
      );
      onSelect(timeSlot);
    },
    [getDateAndTimeSlotsQuery.data, onSelect],
  );

  if (getDateAndTimeSlotsQuery.isFetching) {
    return (
      <CircularProgress
        role={'progressbar'}
        sx={{ marginRight: '32px', order: '1000' }}
      />
    );
  }

  return getDateAndTimeSlotsQuery.data.map((timeSlot) => {
    const order = dayState.findIndex(
      (_timeSlot) => _timeSlot.id === timeSlot.id,
    );
    return (
      <Button
        role={'button'}
        key={timeSlot.id}
        color="blue"
        data-id={timeSlot.id}
        onClick={handleTimeSlotOnClick}
        sx={{
          minWidth: '73px',
          width: '73px',
          height: '40px',
          padding: '10px 15px',
          margin: '0 5px',
          order: order,
        }}
      >
        {timeSlot.value}
      </Button>
    );
  });
};

export default WorkshopTimeSlots;
