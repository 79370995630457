import React from 'react';

import Card from './Card';
import './Section4.scss';

const Section4 = () => {
  return (
    <div className="section4">
      <div className="row">
        <Card id={1} />
        <Card id={2} />
        <Card id={3} />
      </div>
      <div className="row">
        <Card id={4} />
        <Card id={5} />
        <Card id={6} />
      </div>
    </div>
  );
};

export default Section4;
