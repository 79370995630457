import { Box } from '@mui/material';
import { Button as ButtonWhite } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import PropTypes from 'prop-types';
import React from 'react';

import Button from '../../../Button/Button';

/**
 * Displays a pre-defined set of action buttons that is helpful when building wizard pages.
 */
const Actions = ({
  hasBackButton,
  onBackButtonClick,
  tooltipText,
  hasNextButton,
  onNextButtonClick,
  hasConfirmButton,
  onConfirmButtonClick,
  children,
}) => (
  <Box
    sx={{
      display: 'flex',
      margin: '30px',
      '& > *:not(:last-of-type)': { margin: '0 30px' },
    }}
  >
    <ActionButton
      display={hasBackButton}
      onClick={onBackButtonClick}
      buttonProps={{
        id: 'back',
        sx: { textTransform: 'none', padding: '12px 24px 12px 24px' },
      }}
      ButtonComponent={ButtonWhite}
    >
      zurück
    </ActionButton>
    <ActionButton
      display={hasNextButton}
      onClick={onNextButtonClick}
      tooltipText={tooltipText}
      buttonProps={{ id: 'next' }}
    >
      Weiter
    </ActionButton>
    <ActionButton
      display={hasConfirmButton}
      onClick={onConfirmButtonClick}
      buttonProps={{ id: 'confirm' }}
    >
      Reservierung bestätigen
    </ActionButton>
    {children}
  </Box>
);

Actions.propTypes = {
  /**
   * Should the action component display a back button?
   */
  hasBackButton: PropTypes.bool,
  /**
   * Action on back button click
   */
  onBackButtonClick: PropTypes.func,

  /**
   * Text inside the tooltip, hidden when empty
   */
  tooltipText: PropTypes.string,
  /**
   * Should the action component display a next button?
   */
  hasNextButton: PropTypes.bool,
  /**
   * Action on next button click
   */
  onNextButtonClick: PropTypes.func,

  /**
   * Should the action component display a confirm button?
   */
  hasConfirmButton: PropTypes.bool,
  /**
   * Action on confirm button click
   */
  onConfirmButtonClick: PropTypes.func,
};

export default Actions;

export const ActionButton = ({
  display = false,
  tooltipText,
  tooltipProps = { arrow: true, placement: 'top' },
  onClick,
  buttonProps,
  children,
  ButtonComponent = Button,
}) => {
  if (!display) {
    return null;
  }

  const ActionButton = React.forwardRef((props, ref) => (
    <Box ref={ref} {...props}>
      <ButtonComponent onClick={onClick} {...buttonProps}>
        {children}
      </ButtonComponent>
    </Box>
  ));

  if (!tooltipText) {
    return <ActionButton />;
  }

  return (
    <CustomTooltip title={tooltipText} {...tooltipProps}>
      <ActionButton />
    </CustomTooltip>
  );
};

const CustomTooltip = ({ children, ...props }) => {
  if (!props.title) {
    return children;
  }

  return (
    <Tooltip
      PopperProps={{
        sx: {
          width: '226px',
          '& > *': {
            padding: '8px',
            textAlign: 'center',
            fontWeight: '400',
            fontSize: '14px',
            lineHeight: '130%',
          },
        },
      }}
      {...props}
    >
      {children}
    </Tooltip>
  );
};
