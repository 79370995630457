/* eslint-disable import/prefer-default-export */
import { ROOT_URL, NAVIGATION } from '../constants';

/**
 * @method getFooterLinks
 * @param {string} pathname - current path name from location
 * @returns navigation object for current page
 */
export const getFooterLinks = (pathname) => {
  return NAVIGATION[pathname] || NAVIGATION[ROOT_URL];
};
