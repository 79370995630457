import { getCurrentUserWithSalutationId } from '../../../../../../api';

export const fetchUserData = async () => {
  const obj = await getCurrentUserWithSalutationId();
  localStorage.setItem('currentUser', JSON.stringify(obj));
};

export const getUniqueCities = (workshopsData) => {
  return [
    ...new Set(
      workshopsData.map((el) => {
        return el.location;
      }),
    ),
  ];
};
