import { Box, Grid } from '@mui/material';

import Button from '../../../Button/Button';

const SelectOption = ({ options = [], onResult, ...props }) => {
  return (
    <Box>
      <Grid container spacing={2} {...props}>
        {options.map((option) => (
          <Grid item xs={6} key={option.value}>
            <Button sx={{ width: '100%' }} onClick={() => onResult(option)}>
              {option.label}
            </Button>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default SelectOption;
