import { array } from 'prop-types';
import React, { useState } from 'react';

import BookingWizard from '../../components/BookingWizard';
import OfferCard from '../../components/OfferCard';
import OfferCardContainer from '../../components/OfferCard/components/OfferCardContainer';
import OfferModal from '../../components/OfferModal';
import { MODAL_STATES } from '../../constants';

export const AllOffersList = ({ list }) => {
  const [nameOfOpenModal, setNameOfOpenModal] = useState('');
  const [modalState, setModalState] = useState(MODAL_STATES.DEFAULT);

  const handleOpenOffer = (offerName) => {
    setNameOfOpenModal(offerName);
  };

  const submitOfferModal = () => {
    setModalState(MODAL_STATES.SUCCESS);
  };

  const closeOfferModals = () => {
    setNameOfOpenModal('');
    setModalState(MODAL_STATES.DEFAULT);
  };

  const handleError = () => {
    setModalState(MODAL_STATES.ERROR);
  };

  const item = list.find((i) => i.offerName === nameOfOpenModal);

  return (
    <OfferCardContainer>
      {list.map((item) => {
        return (
          <OfferCard
            item={item}
            handleOpenOffer={handleOpenOffer}
            key={item.title}
          />
        );
      })}
      {(() => {
        if (item?.wizard) {
          return (
            <BookingWizard
              onClose={closeOfferModals}
              open
              name={item.offerName}
              {...item.modalInfo}
              {...item}
            />
          );
        } else {
          return (
            item?.modalInfo && (
              <OfferModal
                {...item}
                {...item.modalInfo}
                onSubmit={submitOfferModal}
                onClose={closeOfferModals}
                onError={handleError}
                open={nameOfOpenModal === item.offerName}
                modalState={modalState}
                secondaryModalInfo={item.secondaryModalInfo[modalState]}
              />
            )
          );
        }
      })()}
    </OfferCardContainer>
  );
};

AllOffersList.propTypes = {
  list: array.isRequired,
};

export default AllOffersList;
