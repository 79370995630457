/* eslint-disable import/prefer-default-export */
export const getUrlParams = (search) => {
  const hashes = search?.slice(search.indexOf('?') + 1).split('&');
  const params = {};
  hashes.forEach((hash) => {
    const [key, val] = hash.split('=');
    if (val) {
      params[key] = decodeURIComponent(val);
    }
  });
  return params;
};
