import { ThemeProvider } from '@material-ui/core/styles';
import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';

import theme from '../../../../theme';

const TextFieldWithLabel = ({ labelPosition, label, value, onChange }) => {
  return (
    <ThemeProvider theme={theme}>
      <Box>
        <Box component="label" sx={{ display: 'inline-block' }}>
          <Typography
            variant={
              labelPosition === 'top' ? 'paragraphHighlight' : 'boldParagraph'
            }
          >
            {label}
          </Typography>
          <Box
            component="input"
            sx={
              labelPosition === 'top'
                ? {
                    display: 'block',
                    outline: 0,
                    borderWidth: '0 0 1px',
                    borderColor: theme.palette.grey.navyGrey,
                    marginTop: 1,
                  }
                : {
                    display: 'inline',
                    outline: 0,
                    borderWidth: '0 0 1px',
                    borderColor: theme.palette.grey.navyGrey,
                    marginLeft: 4,
                  }
            }
            value={value}
            onChange={onChange}
            type="text"
          />
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default TextFieldWithLabel;

TextFieldWithLabel.propTypes = {
  labelPosition: PropTypes.oneOf(['top', 'left']).isRequired,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
};
