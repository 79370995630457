import { Box, Typography, Stack } from '@mui/material';
import format from 'date-fns/format';
import { de } from 'date-fns/locale';
import PropTypes from 'prop-types';
import React from 'react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';

import { pushPhoneNumber, pushWorkshopBooking } from '../../../../../api';
import { BOOKING_TARGETS } from '../../../../../constants';
import Actions from '../../../components/Actions';
import BookingSummary from '../../../components/BookingSummary';

export const Page6 = ({ onBack, onNext, authUser }) => {
  const [localStore, setLocalStore] = useState([]);
  const createBookingObject = () => {
    return {
      offerType: 'workshop-closed-participants',
      participants: localStore[2].length,
      participantList: localStore[2].map((item) => {
        delete item.id;
        return item;
      }),
      semcoClassId: localStore[1].workshopId,
      roomNo: localStore[3],
    };
  };
  // TODO: Wrap in a react-query mutation to gain the `isLoading` property and pass it to the button for better UX
  const nextPage = async () => {
    try {
      let booking = createBookingObject();
      booking.participants = booking.participantList.length;
      booking.target = BOOKING_TARGETS.TEAM;
      // eslint-disable-next-line no-console
      await pushWorkshopBooking(booking, { onError: console.error });
      if (localStore.length === 5) {
        await pushPhoneNumber(localStore[4], authUser.uid);
      }
      onNext();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error(error);
    }
  };
  useEffect(() => {
    setLocalStore(JSON.parse(localStorage.getItem('workShop')));
  }, []);

  return (
    <Stack sx={{ width: '100%' }}>
      <Typography
        component="h2"
        variant="h2HeadlineGrey"
        sx={{
          marginBottom: 2,
        }}
      >
        Bitte prüfen Sie Ihre Reservierung
      </Typography>
      <BookingSummary
        data={[
          {
            label: 'Workshop',
            value: 'Zusammenarbeit im Team',
          },
          {
            label: 'Ort',
            value: localStore[0],
            onEdit: () => onBack({ page: 1 }),
          },
          {
            label: 'Termin',
            value:
              localStore[1]?.date &&
              format(
                new Date(localStore[1]?.date),
                "eeee, d. MMMM yyyy 'um' HH:mm 'Uhr'",
                { locale: de },
              ),
            onEdit: () => onBack({ page: 2 }),
          },
          {
            label: 'Teilnehmeranzahl',
            value: localStore[2]?.length,
            onEdit: () => onBack({ page: 3 }),
          },
          {
            label: 'Raumnummer',
            value: localStore[3],
            onEdit: () => onBack({ page: 4 }),
          },
          {
            label: 'Telefonnummer',
            value: localStore[4] || 'Nicht angegeben',
            onEdit: () => onBack({ page: 5 }),
          },
        ]}
      />
      <Box sx={{ position: 'absolute', bottom: 0, right: 0 }}>
        <Actions hasConfirmButton onConfirmButtonClick={nextPage} />
      </Box>
    </Stack>
  );
};

Page6.pageProps = {
  onBack: PropTypes.func.isRequired,
  onNext: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => ({
  authUser: state.authStore.user,
});

export default connect(mapStateToProps)(Page6);
