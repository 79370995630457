import React from 'react';

import { staticSection5Columns } from '../../static/static';
import './Section5.scss';
const Column = () => {
  return (
    <div className="columns">
      {staticSection5Columns.map((item) => (
        <div className="column" key={item.id}>
          <img
            className="icon"
            src={require(`../../../../assets/images/exploitation/sections/section5/icons/icon${item.id}.png`)}
            alt=""
          />
          <h4 className="title">{staticSection5Columns[item.id - 1].title}</h4>
          <span className="content">
            {staticSection5Columns[item.id - 1].content}
          </span>
          <ul className="list">
            {staticSection5Columns[item.id - 1].list ? (
              staticSection5Columns[item.id - 1]?.list.map((itemList) => (
                <li key={itemList}>{itemList}</li>
              ))
            ) : (
              <></>
            )}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default Column;
