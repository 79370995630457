import { Alert, Snackbar } from '@mui/material';
import * as Sentry from '@sentry/react';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useLocation, useHistory } from 'react-router-dom';
import { bindActionCreators } from 'redux';

import {
  ANMELDEN_URL,
  ROOT_URL,
  PROFILE_URL,
  ERROR_INIT,
  ERROR_DURATION,
} from '../../constants';
import { userActions, authActions, companyActions } from '../../store/actions';
import { redirectNotLoggedUser, checkUserRegisterData } from '../../utils';
import { getUrlParams } from '../../utils/getUrlParams';
import './Login.scss';

export const Login = ({ storeUser, authUser, actions, emptyUserData }) => {
  const location = useLocation();
  const history = useHistory();
  const [error, setError] = useState(ERROR_INIT);

  useEffect(() => {
    const { token } = getUrlParams(location.search);
    if (token && authUser !== null && !Object.keys(authUser).length) {
      actions.signInWithCustomToken(token);
      return;
    }
    if (token && authUser) {
      history.push(ROOT_URL);
      actions.fetchUserData(authUser.uid);
      actions.fetchUserCompany(authUser.uid);
      return;
    }
    if (authUser === null && location.pathname !== ANMELDEN_URL) {
      redirectNotLoggedUser();
      return;
    }
    if (authUser && authUser.uid) {
      Sentry.setUser({ id: authUser.uid });
      actions.fetchUserData(authUser.uid);
      actions.fetchUserCompany(authUser.uid);
    }
  }, [authUser]); // eslint-disable-line

  useEffect(() => {
    if (storeUser && Object.keys(storeUser).length) {
      const userRegistered = checkUserRegisterData(storeUser);
      if (!userRegistered) {
        history.push(PROFILE_URL);
        return;
      }
    }
  }, [storeUser]); // eslint-disable-line

  useEffect(() => {
    if (emptyUserData) {
      history.push(PROFILE_URL);
    }
  }, [emptyUserData]); // eslint-disable-line

  useEffect(() => {
    if (error) {
      setTimeout(() => {
        setError(ERROR_INIT);
      }, ERROR_DURATION);
    }
  }, [error]);

  if (error) {
    return (
      <div className="login-page">
        <Snackbar className="alert" open={!!error}>
          <Alert severity="error">{error}</Alert>
        </Snackbar>
      </div>
    );
  }
  return null;
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        ...userActions,
        ...authActions,
        ...companyActions,
      },
      dispatch,
    ),
  },
});

const mapStateToProps = (state) => ({
  storeUser: state.userStore.user,
  authUser: state.authStore.user,
  emptyUserData: state.userStore.emptyUserData,
});

export default connect(mapStateToProps, mapDispatchToProps)(Login);
