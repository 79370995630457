import { Box, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

import ContactModal from '../ContactModal';

const FooterButton = ({ text, Icon, onClick, isOpen, onCloseModal, id }) => {
  return (
    <Box
      component="div"
      sx={{
        padding: '0 36px',
        borderLeft: '1px solid',
        borderColor: 'grey.6',
        cursor: 'pointer',
        position: 'relative',
        ...(isOpen && { backgroundColor: 'blue.primary' }),
      }}
      id={id}
      onClick={onClick}
    >
      <Box
        component="div"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100%',
          width: '100%',
          textDecoration: 'none',
        }}
      >
        <Box
          component={Icon}
          sx={{
            width: '20px',
            height: '24px',
          }}
        />
        <Typography
          variant="navigationLinkText"
          component="span"
          sx={{
            color: isOpen ? 'common.white' : 'blue.primary',
            paddingLeft: '10px',
          }}
        >
          {text}
        </Typography>
      </Box>
      {isOpen && <ContactModal onClose={onCloseModal} />}
    </Box>
  );
};

FooterButton.defaultProps = {
  id: '',
  isOpen: false,
  onCloseModal: () => {},
};

FooterButton.propTypes = {
  text: PropTypes.string.isRequired,
  Icon: PropTypes.object.isRequired,
  onClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool,
  onCloseModal: PropTypes.func,
  id: PropTypes.string,
};

export default FooterButton;
