import { Box, Typography } from '@mui/material';

import imageAZSE from '../../../assets/images/main-menu-picture-2.png';
import { GlobalUnits } from '../../../constants';

/**
 * @type {WorkshopOffer['isVisible']}
 */
export const isVisible = (userUnit) => GlobalUnits.includes(userUnit);

const Fit4Hybrid = () => {
  return (
    <Box>
      <Box
        component={'img'}
        src={imageAZSE}
        alt="INITIATIVE #LEAD / NEUES ARBEITEN"
        sx={{ width: '100%' }}
      />
      <Typography
        component="h1"
        variant="DarkBlueBoldCaps"
        sx={{ marginTop: 3 }}
        gutterBottom
      >
        Fit4Hybrid
      </Typography>
      <Typography variant="body1" gutterBottom>
        This offering has been discontinued.
      </Typography>
    </Box>
  );
};

/**
 * @type {WorkshopOffer}
 */
export const workshopOffer = { component: Fit4Hybrid, isVisible };

export default Fit4Hybrid;
