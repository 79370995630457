import * as Sentry from '@sentry/react';
import { eventChannel } from 'redux-saga';
import { call, fork, put, take } from 'redux-saga/effects';

import { signInWithCustomToken, onAuthStateChanged } from '../../api';
import { authActions } from '../actions';
import { authTypes } from '../actionTypes';

function getAuthChannel() {
  return eventChannel((emit) => {
    const callback = (user) => {
      return emit(authActions.getAuthFulfilled({ user }));
    };
    const unsubscribe = onAuthStateChanged(callback);
    return unsubscribe;
  });
}

function* signInWithUserObject(user) {
  try {
    yield put(authActions.signInWithCustomTokenFulfilled(user));
  } catch (e) {}
}

function* signInWithToken(token) {
  try {
    yield put(authActions.signInWithCustomTokenStarted());
    const user = yield call(signInWithCustomToken, token);
    yield put(authActions.signInWithCustomTokenFulfilled(user));
  } catch (err) {
    Sentry.captureException(err);
  }
}

// =====================================
//  WATCHERS
// -------------------------------------

function* watchSignInWithCustomToken() {
  while (true) {
    const {
      payload: { token },
    } = yield take(authTypes.SIGN_IN_WITH_CUSTOM_TOKEN);
    yield fork(signInWithToken, token);
  }
}

function* watchSignInWithUserObject() {
  while (true) {
    const {
      payload: { user },
    } = yield take(authTypes.SIGN_IN_WITH_USER_OBJECT);
    yield fork(signInWithUserObject, user);
  }
}

function* watchForFirebaseAuth() {
  const channel = yield call(getAuthChannel);
  while (true) {
    let action = yield take(channel);
    yield put(action);
  }
}

// =====================================
//  INIT SAGAS
// -------------------------------------

const authSaga = [
  fork(watchForFirebaseAuth),
  fork(watchSignInWithCustomToken),
  fork(watchSignInWithUserObject),
];

export default authSaga;
