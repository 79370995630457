import { Box, TextField } from '@mui/material';
import PropTypes from 'prop-types';
import { useCallback, useMemo, useState } from 'react';

import Actions from '../Actions';
import { ActionButton } from '../Actions/Actions';
import SelectOption from '../SelectOption';

/**
 * @typedef TLocation
 * @type {object}
 * @property {string} id
 * @property {string} name
 */

/**
 * @param locations {TLocation[]}
 * @param onResult {(location: TLocation | { id: null, name: string }) => void}
 * @param allowCustomLocation {boolean}
 * @param props {any}
 * @constructor
 */
const SelectLocation = ({
  locations = [],
  onResult,
  allowCustomLocation = false,
  ...props
}) => {
  const [customLocation, setCustomLocation] = useState('');
  const [screen, setScreen] = useState(Screen.Options);

  /**
   * @type {(option: TOption) => void}
   */
  const handleOnResult = useCallback(
    (option) => {
      if (allowCustomLocation) {
        if (option.value === _customLocation.id) {
          setScreen(Screen.CustomOption);
          return;
        }
      }
      const _location = locations.find(
        (location) => location.id === option.value,
      );
      if (_location) {
        setCustomLocation('');
        return onResult(_location);
      }
      throw new Error('Invalid option provided');
    },
    [allowCustomLocation, locations, onResult],
  );

  const handleSubmitCustomOption = (e) => {
    e.preventDefault();
    onResult({
      id: null,
      name: customLocation,
    });
    setCustomLocation('');
  };

  /**
   * @type {TOption[]}
   */
  const _options = useMemo(() => {
    const _options = [...locations];
    if (allowCustomLocation) {
      _options.push(_customLocation);
    }
    return _options.map((location) => ({
      value: location.id,
      label: location.name,
    }));
  }, [locations, allowCustomLocation]);

  switch (screen) {
    default:
    case Screen.Options: {
      return (
        <SelectOption {...props} onResult={handleOnResult} options={_options} />
      );
    }
    case Screen.CustomOption: {
      return (
        <Box {...props} as={'form'} onSubmit={handleSubmitCustomOption}>
          <TextField
            variant={'standard'}
            placeholder={'Bitte geben Sie einen Ort ein'}
            fullWidth
            name={'customLocation'}
            value={customLocation}
            onChange={(e) => {
              setCustomLocation(e.target.value);
            }}
            required
          />
          <Actions
            hasBackButton
            onBackButtonClick={() => {
              setScreen(Screen.Options);
              setCustomLocation('');
            }}
            sx={{
              justifyContent: 'flex-end',
            }}
          >
            <Box>
              <ActionButton
                display
                buttonProps={{ type: 'submit' }}
                onClick={null}
              >
                Weiter
              </ActionButton>
            </Box>
          </Actions>
        </Box>
      );
    }
  }
};

const Screen = {
  Options: 'options',
  CustomOption: 'custom',
};

/**
 * @type {TLocation}
 */
export const _customLocation = {
  id: 'other',
  name: 'Sonstige',
};

SelectLocation.propTypes = {
  /** The locations the user can choose from */
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,

  /** A function that is triggered when the user clicks the button or provides the custom input. */
  onResult: PropTypes.func.isRequired,

  /** A flag that allows user to input custom location */
  allowCustomLocation: PropTypes.bool,
};

export default SelectLocation;
