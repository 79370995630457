import {
  doc,
  setDoc,
  getDoc,
  collection,
  orderBy,
  onSnapshot,
  query,
} from 'firebase/firestore';

import { db } from '../firebaseConfig';

const COLLECTION_NAME = 'questionnaireApp';

/**
 * @method registerUser
 * @param {object} userData - user data
 * @summary add user data to db
 */

export const registerUser = async ({ userData, uid }) => {
  const questionnaireAppRef = doc(db, COLLECTION_NAME, uid);
  return setDoc(
    questionnaireAppRef,
    {
      ...userData,
    },
    { merge: true },
  );
};

/**
 * @method getQuestionnaireData
 * @param {string} uid - user uid
 * @summary getting users data
 * @returns {object} with answers if document exist or false
 */
export const getQuestionnaireData = async (uid) => {
  const questionnaireAppRef = doc(db, COLLECTION_NAME, uid);
  return getDoc(questionnaireAppRef);
};

export const onAnswersSnapshot = async (callback) => {
  const answersRef = collection(db, '/answers');
  const q = query(answersRef, orderBy('submittedAt'));
  onSnapshot(q, callback);
};
