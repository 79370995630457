import PropTypes from 'prop-types';
import React from 'react';

import './Exploitation.scss';
const Subtitle = ({ h2, span }) => {
  return (
    <div className="subtitle">
      <h2>{h2}</h2>
      <span>{span}</span>
    </div>
  );
};

Subtitle.propTypes = {
  h2: PropTypes.string.isRequired,
  span: PropTypes.string.isRequired,
};

export default Subtitle;
