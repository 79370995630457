import { combineReducers } from 'redux';

import authStore from './authReducer';
import bookingStore from './bookingReducer';
import commonStore from './commonReducer';
import companyStore from './companyReducer';
import modalStore from './modalReducer';
import userStore from './userReducer';

const rootReducer = combineReducers({
  userStore,
  authStore,
  commonStore,
  modalStore,
  companyStore,
  bookingStore,
});

export default rootReducer;
