import PropTypes from 'prop-types';
import React from 'react';

import { staticSection4 } from '../../static/static';
import './Section4.scss';

const Card = ({ id }) => {
  return (
    <div className="card">
      <img
        src={require(`../../../../assets/images/exploitation/sections/section4/icons/icon${id}.png`)}
        alt=""
      />
      <h4 className="title">{staticSection4[id - 1].title}</h4>
      <span className="content">{staticSection4[id - 1].content}</span>
    </div>
  );
};

Card.propTypes = {
  id: PropTypes.number.isRequired,
};

export default Card;
