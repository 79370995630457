import Alert from '@mui/material/Alert';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ErrorIcon from '../../assets/svg/error-icon.svg';
import Button from '../../components/Button';
import { GlobalUnits, ROOT_URL } from '../../constants';
import { userActions } from '../../store/actions';
import './Profile.scss';
import {
  careerpathOptions,
  clusterOptions,
  genderOptions,
  leadingLeadersOptions,
  unitOptions,
} from './ProfileOptions';

const useStyles = makeStyles(() => ({
  bigFormControl: {
    width: 450,
    marginBottom: 26,
  },
  mediumFormControl: {
    width: 250,
    marginBottom: 26,
  },
  smallFormControl: {
    width: 150,
    marginBottom: 26,
  },
  lastFormControl: {
    width: 450,
    marginBottom: 32,
  },
  error: {
    marginBottom: 32,
    border: 1,
    borderColor: '#DC3149',
    borderStyle: 'solid',
    backgroundColor: '#FCE5EB',
    width: 416,
  },
}));

export const Profile = ({ history, authUser, actions }) => {
  const classes = useStyles();
  const [t] = useTranslation();

  const [showPopupError, setShowPopupError] = useState(false);

  const [gender, setGender] = useState('');
  const [unit, setUnit] = useState('');
  const [cluster, setCluster] = useState('');
  const [careerpath, setCareerpath] = useState('');
  const [leadingLeaders, setLeadingLeaders] = useState('');

  const [genderError, setGenderError] = useState(false);
  const [unitError, setUnitError] = useState(false);
  const [clusterError, setClusterError] = useState(false);
  const [careerpathError, setCareerpathError] = useState(false);
  const [leadingLeadersError, setLeadingLeadersError] = useState(false);

  const handleGender = (event) => {
    setGender(event.target.value);
    setShowPopupError(false);
    setGenderError(false);
  };
  const handleUnit = (event) => {
    setUnit(event.target.value);
    setShowPopupError(false);
    setUnitError(false);
  };
  const handleCluster = (event) => {
    setCluster(event.target.value);
    setShowPopupError(false);
    setClusterError(false);
  };
  const handleCareerpath = (event) => {
    setCareerpath(event.target.value);
    setShowPopupError(false);
    setCareerpathError(false);
  };
  const handleLeadingLeaders = (event) => {
    setLeadingLeaders(event.target.value);
    setShowPopupError(false);
    setLeadingLeadersError(false);
  };

  const isUnitCorrect = !GlobalUnits.includes(unit);

  const onSubmit = async () => {
    setGenderError(!gender);
    setUnitError(!unit);
    setClusterError(!cluster);
    if (isUnitCorrect) {
      setCareerpathError(!careerpath);
      setLeadingLeadersError(!leadingLeaders);
    }
    if (!gender || !unit || !cluster) {
      setShowPopupError(true);
      return;
    }
    if (isUnitCorrect && (!careerpath || !leadingLeaders)) {
      setShowPopupError(true);
      return;
    }
    const userData = {
      gender,
      unit,
      cluster,
      ...(isUnitCorrect && { careerpath, leadingLeaders }),
    };
    await actions.registerUser(userData, authUser.uid);
    history.push(ROOT_URL);
  };

  const renderError = () => {
    if (showPopupError) {
      return (
        <Alert
          className={classes.error}
          icon={<img src={ErrorIcon} alt="" />}
          severity="error"
        >
          <div className="paragraph">
            <span className="paragraph-highlight">
              {t('profile.error.title')}
            </span>{' '}
            {t('profile.error.text')}
          </div>
        </Alert>
      );
    }
  };

  return (
    <div className="profile">
      <div className="profile-image" />
      <div className="profile-container">
        <h1 className="profile-title h1-bold">{t('profile.title')}</h1>
        <div className="paragraph">{t('profile.sub.title')}</div>
        <div className="profile-form">
          <div className="profile-form-selects-wrapper">
            <FormControl
              variant="standard"
              className={classes.bigFormControl}
              error={genderError}
            >
              <InputLabel>{t('profile.gender.label')}</InputLabel>
              <Select value={gender} onChange={handleGender}>
                {genderOptions(t).map((option) => (
                  <MenuItem key={option.label} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          <FormControl
            variant="standard"
            className={classes.bigFormControl}
            error={unitError}
          >
            <InputLabel>{t('profile.unit.label')}</InputLabel>
            <Select name="unit" value={unit} onChange={handleUnit}>
              {unitOptions
                .sort((a, b) => (a.toLowerCase() > b.toLowerCase() ? 1 : -1))
                .map((option) => (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl
            variant="standard"
            className={classes.bigFormControl}
            error={clusterError}
          >
            <InputLabel>{t('profile.cluster.label')}</InputLabel>
            <Select value={cluster} onChange={handleCluster}>
              {clusterOptions.map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          {isUnitCorrect && (
            <>
              <FormControl
                variant="standard"
                className={classes.bigFormControl}
                error={careerpathError}
                name="careerpath"
              >
                <InputLabel>{t('profile.careerpath.label')}</InputLabel>
                <Select value={careerpath} onChange={handleCareerpath}>
                  {careerpathOptions(t).map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl
                variant="standard"
                className={classes.lastFormControl}
                error={leadingLeadersError}
                name="leadingLeaders"
              >
                <InputLabel>{t('profile.leadingLeaders.label')}</InputLabel>
                <Select value={leadingLeaders} onChange={handleLeadingLeaders}>
                  {leadingLeadersOptions(t).map((option) => (
                    <MenuItem key={option.label} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </>
          )}
        </div>
        <div className="profile-privacy-container paragraph-small">
          {t('profile.privacy.text.1')}
          <a
            rel="noopener noreferrer"
            target="_blank"
            className="profile-privacy-link"
            href={t('profile.privacy.text.url')}
          >
            &nbsp;{t('profile.privacy.text.2')}&nbsp;
          </a>
          {t('profile.privacy.text.3')}
        </div>
        {renderError()}
        <Button onClick={onSubmit} color="blue">
          {t('profile.button')}
        </Button>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  authUser: state.authStore.user,
});

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        ...userActions,
      },
      dispatch,
    ),
  },
});

Profile.propTypes = {
  history: PropTypes.object.isRequired,
};

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
