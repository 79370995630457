import * as Sentry from '@sentry/react';
import { eventChannel } from 'redux-saga';
import { call, fork, put, take, select } from 'redux-saga/effects';

import {
  getQuestionnaireData,
  registerUser,
  logout,
  getUserData,
  onQuestionnaireDocSnapshot,
} from '../../api';
import { userActions } from '../actions';
import { userTypes } from '../actionTypes';

function* fetchUser(uid) {
  try {
    const user = yield call(getQuestionnaireData, uid);
    yield put(userActions.runWatchUserData());
    const userData = user.data();
    if (!userData) {
      yield put(userActions.setEmptyUserData());
      return;
    }
    yield put(userActions.fetchUserFulfilled(userData));
  } catch (err) {
    Sentry.captureException(err);
  }
}

function* fetchUserForCoache(uid) {
  try {
    const user = yield call(getQuestionnaireData, uid);
    const userData = user.data();
    yield put(userActions.fetchUserFulfilled(userData));
  } catch (err) {
    Sentry.captureException(err);
  }
}

function* fetchSolutionsUser(uid) {
  try {
    const userDataSnapshot = yield call(getUserData, uid);
    const userData = userDataSnapshot.data();
    yield put(userActions.fetchSolutionsUserFulfilled(userData));
  } catch (err) {
    Sentry.captureException(err);
  }
}

function* registerOfferInFirebase({ uid, offers }) {
  try {
    yield call(registerUser, { uid, userData: { savedOffers: offers } });
  } catch (err) {
    Sentry.captureException(err);
  }
}

function* registerUserInFirebase(userData, uid) {
  try {
    yield call(registerUser, { userData, uid });
    const {
      userStore: { emptyUserData },
    } = yield select();
    if (emptyUserData) {
      yield put(userActions.runWatchUserData());
      yield put(userActions.unsetEmptyUserData());
    }
  } catch (err) {
    Sentry.captureException(err);
  }
}

function* logoutUser() {
  try {
    yield call(logout);
  } catch (err) {
    Sentry.captureException(err);
  }
}

function getUserDataChannel(uid) {
  return eventChannel((emit) => {
    const callback = (snap) =>
      emit(userActions.getUserFulfilled(snap.data() || {}));
    const unsubscribe = onQuestionnaireDocSnapshot(uid, callback);
    return unsubscribe;
  });
}

// =====================================
//  WATCHERS
// -------------------------------------

function* watchFetchUser() {
  while (true) {
    const {
      payload: { uid },
    } = yield take(userTypes.FETCH_USER_DATA);
    yield fork(fetchUser, uid);
  }
}

function* watchFetchSolutionUser() {
  while (true) {
    const {
      payload: { uid },
    } = yield take(userTypes.FETCH_SOLUTIONS_USER_DATA);
    yield fork(fetchSolutionsUser, uid);
  }
}

function* watchFetchUserForCoache() {
  while (true) {
    const {
      payload: { uid },
    } = yield take(userTypes.FETCH_USER_DATA_FOR_COACHE);
    yield fork(fetchUserForCoache, uid);
  }
}

function* watchRegisterOffer() {
  while (true) {
    const {
      payload: { uid, offers },
    } = yield take(userTypes.REGISTER_TO_OFFERS);
    yield fork(registerOfferInFirebase, { uid, offers });
  }
}

function* watchRegisterUser() {
  while (true) {
    const {
      payload: { userData, uid },
    } = yield take(userTypes.REGISTER_USER);
    yield fork(registerUserInFirebase, userData, uid);
  }
}

function* watchLogoutUser() {
  while (true) {
    yield take(userTypes.LOGOUT);
    yield fork(logoutUser);
  }
}

function* watchForUserData() {
  yield take(userTypes.RUN_WATCH_USER_DATA);
  const {
    authStore: {
      user: { uid },
    },
  } = yield select();
  const channel = yield call(getUserDataChannel, uid);
  while (true) {
    let action = yield take(channel);
    yield put(action);
  }
}

// =====================================
//  INIT SAGAS
// -------------------------------------

const userSaga = [
  fork(watchFetchUser),
  fork(watchFetchSolutionUser),
  fork(watchRegisterOffer),
  fork(watchForUserData),
  fork(watchRegisterUser),
  fork(watchLogoutUser),
  fork(watchFetchUserForCoache),
];
export default userSaga;
