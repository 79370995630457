/* eslint-disable import/prefer-default-export */
import Digital from '../assets/svg/journey-digital.svg';
import Presence from '../assets/svg/journey-presence.svg';

export const WORKSHOPS_TYPES = {
  PRESENCE: {
    title: 'Präsenz',
    img: Presence,
    participantsCount: 9,
    raumnummerText:
      'Bitte buchen Sie den Raum für den Workshop und geben die Raumnummer an',
  },
  DIGITAL: {
    title: 'Digital',
    img: Digital,
    participantsCount: 9,
    raumnummerText:
      'Bitte buchen Sie den Raum für den Workshop und geben die Raumnummer an',
  },
  HYBRID: {
    title: 'Hybrid',
    img: Digital,
    participantsCount: 25,
    raumnummerText:
      'Für eine Durchführung in Präsenz buchen Sie bitte einen Raum ' +
      'und geben die Raumnummer an. Für eine rein virtuelle Durchführung bitte “virtuell” eintragen.',
  },
  VIRTUAL: {
    title: 'Virtuell',
    img: Digital,
    participantsCount: 25,
  },
};
