import { ThemeProvider } from '@mui/material/styles';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import React from 'react';
import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import ReactDOM from 'react-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { Provider } from 'react-redux';

import './global.scss';
import App from './routes';
import createStore from './store';
import theme from './theme';

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
  environment: process.env.NODE_ENV,

  // Called for message and error events
  beforeSend(event) {
    if (event.user) {
      // Set Firebase Auth UID as Sentry User ID
      if (event.user.uid) {
        event.user.id = event.user.uid;
      }

      // Before sending, we anonymize the user object
      event.user.username = event.user.firstname;
      delete event.user.lastname;
      delete event.user.email;
    }
    return event;
  },
});

const queryClient = new QueryClient({
  defaultOptions: {
    mutations: {
      onError: (error) => {
        Sentry.captureMessage(error);
      },
    },
    queries: {
      onError: (error) => {
        Sentry.captureMessage(error);
      },
      retry: (failureCount, error) => {
        if (error?.status && [400, 404, 500].includes(error.status)) {
          return false;
        }
        return failureCount < 2;
      },
      refetchOnReconnect: false,
      refetchOnWindowFocus: false,
    },
  },
});

const { store } = createStore();

ReactDOM.render(
  <QueryClientProvider client={queryClient}>
    <ThemeProvider theme={theme}>
      <Provider store={store}>
        <App />
        <ReactQueryDevtools initialIsOpen={false} />
      </Provider>
    </ThemeProvider>
  </QueryClientProvider>,
  document.getElementById('root'),
);
