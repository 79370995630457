import PropTypes from 'prop-types';

import './Exploitation.scss';

const List = ({ content }) => {
  return (
    <ul className="list">
      {content.map((item) => (
        <li key={item}>{item}</li>
      ))}
    </ul>
  );
};
List.propTypes = {
  content: PropTypes.array.isRequired,
};

export default List;
