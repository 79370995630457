import { Box, Typography, Link } from '@mui/material';
import { func } from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';

import closeIcon from '../../assets/icons/X.png';
import { GlobalUnits } from '../../constants/Units';

export const ContactModal = ({ onClose, storeUser }) => {
  const [t] = useTranslation();
  const isAllianzSEUnit = GlobalUnits.includes(storeUser?.unit);
  const contactEmailAddress = isAllianzSEUnit
    ? 'SE-Academy@allianz.com'
    : 'lead-azd@allianz.de';

  return (
    <Box
      sx={{
        position: 'absolute',
        bottom: '64px',
        left: 0,
        zIndex: 100,
        backgroundColor: 'common.white',
        border: '1px solid',
        borderColor: 'grey.6',
        cursor: 'auto',
      }}
      tabIndex="-1"
    >
      <Box
        tabIndex="0"
        sx={{
          display: 'flex',
          flexDirection: 'column',
          width: '490px',
          height: '201px',
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: '32px',
            height: '32px',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            top: '-1px',
            right: '-1px',
            cursor: 'pointer',
            border: '1px solid',
            borderColor: 'grey.10',
          }}
          onClick={onClose}
        >
          <img src={closeIcon} alt="" />
        </Box>
        <Typography
          sx={{ margin: '33px 64px 0 32px' }}
          variant="offerHeadlineBlue"
        >
          {t('contact.modal.title')}
        </Typography>
        <Typography sx={{ margin: '33px 64px 0 32px' }} variant="paragraph">
          {t('contact.modal.text')}{' '}
          <Link
            color="text.primary"
            sx={{ textDecoration: 'none', fontWeight: '700' }}
            href={`mailto:${contactEmailAddress}`}
          >
            {contactEmailAddress}
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

ContactModal.propTypes = {
  onClose: func.isRequired,
};

const mapStateToProps = (state) => ({
  storeUser: state.userStore.user,
});

export default connect(mapStateToProps)(ContactModal);
