import { Typography } from '@mui/material';
import { useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import ZusammenarbeitImTeam4hrs from '../../assets/images/offers/160x200/ZusammenarbeitImTeam4hrs.png';
import DurationHalfDay from '../../assets/svg/journey_duration_half_day.svg';
import BookingWizard from '../../components/BookingWizard/BookingWizard';
import OfferCard from '../../components/OfferCard';
import OfferCardContainer from '../../components/OfferCard/components/OfferCardContainer';
import { GermanyUnits, WORKSHOPS_TYPES } from '../../constants';
import useFetchSolutionsUser from '../../effects/useFetchSolutionsUser';
import { userActions, modalActions } from '../../store/actions';
import './Exploitation.scss';

export const visibleForUnits = GermanyUnits;

export const ExploitationOffers = ({ solutionsUser, authUser, actions }) => {
  useFetchSolutionsUser({ ...actions, solutionsUser, authUser });
  const [isWizardOpen, setIsWizardOpen] = useState(false);

  return (
    <div className="exploitation-container">
      <div className="journey-recommendations-container themself-recomendations">
        <Typography component="h1" variant="h1Bold" sx={{ marginBottom: 6 }}>
          Die Learning Angebote im Überblick
        </Typography>
        <Typography variant="body1">
          Hier sehen Sie die komplette Auswahl an Workshops und
          Weiterbildungsangeboten, die Ihnen im Rahmen der Initiative
          agile@betrieb&schaden zur Verfügung steht.
        </Typography>
        <div className="journey-recommendations-all">
          <OfferCardContainer>
            <OfferCard
              item={{
                offerName: 'Zusammenarbeit',
                title: 'Zusammenarbeit im Team',
                available_for: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_cluster: ['AGE', 'ASE', 'AE', 'ASA'],
                visible_for_unit: GermanyUnits,
                visible_for_leadingLeaders: '',
                image_small: ZusammenarbeitImTeam4hrs,
                text: `Buchen Sie Ihren Workshop “Zusammenarbeit im Team” für sich und Ihr Team.`,
                firstButton: 'verfügbar',
                duration: {
                  text: '1/2 Tag',
                  icon: DurationHalfDay,
                },
                workshopTypes: [
                  WORKSHOPS_TYPES.PRESENCE,
                  WORKSHOPS_TYPES.VIRTUAL,
                ],
              }}
              handleOpenOffer={() => setIsWizardOpen(true)}
            />

            <BookingWizard
              wizard="ZIT"
              open={isWizardOpen}
              onClose={() => setIsWizardOpen(false)}
            />
          </OfferCardContainer>
        </div>
      </div>
    </div>
  );
};

const mapDispatchToProps = (dispatch) => ({
  actions: {
    ...bindActionCreators(
      {
        ...userActions,
        ...modalActions,
      },
      dispatch,
    ),
  },
});

const mapStateToProps = (state) => ({
  storeUser: state.userStore.user,
  authUser: state.authStore.user,
  solutionsUser: state.userStore.solutionsUser,
});

export default connect(mapStateToProps, mapDispatchToProps)(ExploitationOffers);
