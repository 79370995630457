import * as Sentry from '@sentry/react';
import { Component } from 'react';

import FullPageMessage from '..//FullPageMessage';

class WizardErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false, error: null };
  }

  static getDerivedStateFromError(error) {
    if (Object.values(customErrorMessages).includes(error?.message)) {
      return {
        hasError: true,
        error: error?.message,
      };
    }
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    Sentry.captureException(error, {
      method: 'boundary',
      errorInfo,
    });
  }

  render() {
    if (this.state.hasError) {
      return (
        <FullPageMessage
          title="Es ist ein Fehler aufgetreten"
          message={
            this.state.error ??
            'Wir wurden bereits über den Fehler informiert und arbeiten an einer Lösung.'
          }
        />
      );
    }

    return this.props.children;
  }
}

export const customErrorMessages = {
  loadingAppointments: 'Beim Laden der Termine ist ein Fehler aufgetreten',
};

export default WizardErrorBoundary;
