import { userTypes } from '../actionTypes';

const initialState = {
  user: {},
  solutionsUser: {},
  emptyUserData: false,
};

function userReducer(state = initialState, { type, payload }) {
  switch (type) {
    case userTypes.FETCH_USER_DATA_FULFILLED:
      return {
        ...state,
        user: payload.user,
      };
    case userTypes.FETCH_SOLUTIONS_USER_DATA_FULFILLED:
      return {
        ...state,
        solutionsUser: payload.user,
      };
    case userTypes.GET_USER_DATA_FULFILLED:
      return {
        ...state,
        user: payload.user,
      };
    case userTypes.SET_EMPTY_USER_DATA:
      return {
        ...state,
        emptyUserData: true,
      };
    case userTypes.UNSET_EMPTY_USER_DATA:
      return {
        ...state,
        emptyUserData: false,
      };
    default:
      return state;
  }
}

export default userReducer;
