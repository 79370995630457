import * as Sentry from '@sentry/react';
import { all, call } from 'redux-saga/effects';

import authSaga from './authSaga';
import bookingSaga from './bookingSaga';
import commonSaga from './commonSaga';
import companySaga from './companySaga';
import userSaga from './userSaga';

const autoRestart = (generator) =>
  function* autoRestarting(...args) {
    while (true) {
      try {
        yield call(generator, ...args);
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  };

const rootSaga = autoRestart(function* root() {
  yield all([
    ...userSaga,
    ...authSaga,
    ...companySaga,
    ...bookingSaga,
    ...commonSaga,
  ]);
});

export default rootSaga;
