import { Typography, Stack, Button } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';

const BookingSummary = ({ data }) => (
  <Stack width="100%" spacing={3}>
    {data.map(({ label, value, onEdit }) => (
      <Stack key={value} direction="row" justifyContent="space-between">
        <Stack>
          <Typography>{label}</Typography>
          <Typography variant="paragraphHighlight">{value}</Typography>
        </Stack>
        {onEdit && (
          <Button
            sx={{ textTransform: 'none', fontWeight: '600' }}
            onClick={onEdit}
          >
            Bearbeiten
          </Button>
        )}
      </Stack>
    ))}
  </Stack>
);

BookingSummary.propTypes = {
  /**
   * Each object in this array represents one line in the booking summary:
   *
   * - `label`: The label of this data point displayed as a headline on the left.
   * - `value`: The value of this data point right beneath the label.
   * - `onEdit`: If present, an edit button is displayed that triggers the function defined here.
   */
  data: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      onEdit: PropTypes.func,
    }),
  ).isRequired,
};

export default BookingSummary;
