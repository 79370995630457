import React from 'react';

import List from '../../List';
import {
  staticSection3_cell2,
  staticSection3_cell3,
} from '../../static/static';
import './Section3.scss';

const Section3 = () => {
  return (
    <div className="section3">
      <div className="section3-cell"></div>
      <div className="section3-cell">
        <span className="title">Warum Veränderung?</span>
        <h2 className="title">Zeit für einen mutigen Schritt in die Zukunft</h2>
        <span className="sub-title">Wir wollen</span>
        <List content={staticSection3_cell2.content} />
      </div>
      <div className="section3-cell">
        <h2 className="title">Was ist Agilität?</h2>
        <List content={staticSection3_cell3.content} />
      </div>
      <div className="section3-cell"></div>
    </div>
  );
};

export default Section3;
