import { ThemeProvider } from '@mui/material';
import { Box, Stack } from '@mui/material';
import { Typography } from '@mui/material';
import PropTypes from 'prop-types';

import group from '../../../../../assets/images/BookingWizard/Group.svg';
import theme from '../../../../../theme/index';
import Button from '../../../../Button';
import StatusWrapper from '../../../components/StatusWrapper';

/**
 * @param onNext {(city: string) => void}
 * @param cities {string[] | null}
 * @return {JSX.Element}
 * @constructor
 */
export const Page1 = ({ onNext, cities, loadingStatus }) => {
  return (
    <ThemeProvider theme={theme}>
      <Stack>
        <Box
          sx={{
            maxWidth: 634,
            textAlign: 'center',
          }}
        >
          <Typography variant="h2HeadlineGrey">
            Bitte wählen Sie Ihren Allianz-Standort, an dem der Workshop
            stattfinden soll
          </Typography>
        </Box>
        <Box
          sx={{
            position: 'absolute',
            top: '180px',
            display: 'flex',
            height: '75%',
            width: '382px',
            paddingLeft: '50px',
            justifyContent: 'left',
            alignContent: 'flex-start',
            gap: '10px',
            flexWrap: 'wrap',
          }}
        >
          <StatusWrapper status={loadingStatus}>
            {cities?.length ? (
              cities.map((city, index) => (
                <Button
                  key={index}
                  onClick={() => onNext(city)}
                  color="blue"
                  sx={{
                    minWidth: '185px',
                    width: '185px',
                    height: '50px',
                  }}
                >
                  {city}
                </Button>
              ))
            ) : (
              <Box>Aktuell sind keine Termine verfügbar.</Box>
            )}
          </StatusWrapper>
        </Box>
        <Box>
          <Box
            component="img"
            src={group}
            alt=""
            sx={{
              minWidth: '185px',
              width: '348px',
              height: '471px',
              position: 'absolute',
              left: '741px',
              top: '120px',
            }}
          ></Box>
        </Box>
      </Stack>
    </ThemeProvider>
  );
};

Page1.propTypes = {
  onNext: PropTypes.func.isRequired,
  cities: PropTypes.arrayOf(PropTypes.string), // nullable
  loadingStatus: PropTypes.string.isRequired,
};
export default Page1;
