import PropTypes from 'prop-types';
import { useEffect, useMemo, useState } from 'react';
import { connect } from 'react-redux';

import { useGetWorkshopsData } from '../../../../../api';
import Page1 from '../Page1';
import Page2 from '../Page2';
import Page3 from '../Page3';
import Page4 from '../Page4';
import Page5 from '../Page5';
import Page6 from '../Page6';
import PageSuccess from '../PageSuccess';
import { fetchUserData, getUniqueCities } from './utils/utils';

const getInitCityState = () => {
  const workshop = localStorage.getItem('workShop');
  if (workshop) {
    return JSON.parse(workshop)?.[0] ?? null;
  }
  return null;
};

export const WizardZIT = ({ authUser, onFinish }) => {
  const [selectedCity, setSelectedCity] = useState(getInitCityState());

  const [currentPage, setCurrentPage] = useState(
    JSON.parse(localStorage?.getItem('workShop'))?.length + 1 || 1,
  );

  const workshopDataQuery = useGetWorkshopsData({ categoryId: 1 });

  const cities = useMemo(() => {
    if (!workshopDataQuery.data?.length) {
      return null;
    }
    return getUniqueCities(workshopDataQuery.data);
  }, [workshopDataQuery.data]);

  useEffect(() => {
    if (!authUser?.uid) return;
    fetchUserData();
  }, [authUser]);

  const availableDates = useMemo(() => {
    if (
      !workshopDataQuery.data?.length ||
      (!selectedCity?.length && !JSON.parse(localStorage?.getItem('workShop')))
    ) {
      return null;
    }
    if (selectedCity?.length) {
      return workshopDataQuery.data.filter(
        (item) => item.location === selectedCity,
      );
    }
  }, [selectedCity, workshopDataQuery.data]);

  const pageToRender = () => {
    switch (currentPage) {
      case 1:
        return (
          <Page1
            onNext={(city) => {
              setCurrentPage(currentPage + 1);
              setSelectedCity(city);
              localStorage.setItem('workShop', JSON.stringify([city]));
            }}
            cities={cities}
            loadingStatus={workshopDataQuery.status}
          />
        );
      case 2:
        return (
          <Page2
            onBack={() => setCurrentPage(currentPage - 1)}
            onNext={() => setCurrentPage(currentPage + 1)}
            availableDates={availableDates}
          />
        );
      case 3:
        return (
          <Page3
            onBack={() => setCurrentPage(currentPage - 1)}
            onNext={() => setCurrentPage(currentPage + 1)}
          />
        );
      case 4:
        return (
          <Page4
            onBack={() => setCurrentPage(currentPage - 1)}
            onNext={() => setCurrentPage(currentPage + 1)}
          />
        );
      case 5:
        return (
          <Page5
            onBack={() => setCurrentPage(currentPage - 1)}
            onNext={() => setCurrentPage(currentPage + 1)}
          />
        );
      case 6:
        return (
          <Page6
            onBack={({ page }) => setCurrentPage(page)}
            onNext={() => setCurrentPage(currentPage + 1)}
          />
        );
      case 7:
        return <PageSuccess onFinish={onFinish} />;
      default:
        throw new Error('Invalid page specified');
    }
  };
  return <>{pageToRender()}</>;
};

WizardZIT.propTypes = {
  onFinish: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  authUser: state.authStore.user,
});
export default connect(mapStateToProps)(WizardZIT);
