import Tooltip from '@mui/material/Tooltip';
import React from 'react';

import './ShiftPage.scss';

const ShiftPage = () => {
  return (
    <div className="static-page">
      <h1 className="h1-bold">Der Shift</h1>
      <div className="static-page-info paragraph">
        Wir entwickeln unsere Leadership Kultur mit #lead in zwei Richtungen
        weiter​. Auf einem gemeinsamen Weg wollen wir die Führungskultur der
        Allianz Deutschland weiterentwickeln und unsere bisherigen Stärken in
        zwei Richtungen ausbauen:
      </div>
      <div className="shift-container">
        <div className="shift-item">
          <div className="shift-item-title">
            <h3 className="shift-item-subtitle h3-dark-blue-bold-caps">
              DYNAMISCH
            </h3>
            <Tooltip
              id="shift-page-dinamic-tooltip"
              title="Wir wollen die Anpassungs- und Veränderungs- fähigkeit der Allianz Deutschland stärken."
              arrow
            >
              <div tabIndex="0" className="info-btn">
                i
              </div>
            </Tooltip>
          </div>
          <div className="shift-item-text paragraph">
            <span>Agilität & Innovation | Flexibilität & Vereinfachung</span>
            <span>
              Ergebnisorientierung & Umsetzungskraft | Eigenverantwortung &
              Empowerment
            </span>
          </div>
        </div>
        <div className="shift-container-center-block">
          <div className="shift-item">
            <h3 className="shift-item-subtitle h3-dark-blue-bold-caps">
              SACHLICH
            </h3>
            <div className="shift-item-text paragraph">
              <span>Rationalität | Regelorientierung</span>
              <span>Reputation | Kompetenz</span>
            </div>
          </div>
          <div className="line-arrow-block"></div>
          <div className="shift-item">
            <div className="shift-item-title">
              <h3 className="shift-item-subtitle h3-dark-blue-bold-caps">
                PERSÖNLICH
              </h3>
              <Tooltip
                id="shift-page-peronally-tooltip"
                title={`Wir wollen, dass sich die individuellen Stärken und Potenziale der Leader, 
										Mitarbeitenden und Teams besser entfalten können.`}
                arrow
              >
                <div tabIndex="0" className="info-btn">
                  i
                </div>
              </Tooltip>
            </div>
            <div className="shift-item-text paragraph">
              <span>Resilienz & Selfcare</span>
              <span>Teamgeist & Wertschätzung</span>
              <span>Feedback & Lernkultur</span>
              <span>Motivation & Überzeugung</span>
            </div>
          </div>
        </div>
        <div className="shift-item">
          <h3 className="shift-item-subtitle h3-dark-blue-bold-caps">STABIL</h3>
          <div className="shift-item-text paragraph">
            Hierachie | Ordnung | Prinzipien | Verlässlichkeit
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShiftPage;
