import { Box, Typography } from '@mui/material';
import React from 'react';

import imageAgile from '../../../assets/images/main-menu-picture-3.png';
import Button from '../../../components/Button';
import GlobalLink from '../../../components/GlobalLink';
import {
  AGILEMASTERCOACHOFFERS_URL,
  EXPLOITATION_OFFERS_URL,
  EXPLOITATION_URL,
} from '../../../constants';
import { visibleForUnits as agileMasterCoachOffers_visibleForUnits } from '../../AgileMasterCoachOffers/AgileMasterCoachOffers';
import { visibleForUnits as exploitationOffers_visibleForUnits } from '../../ExploitationOffers/ExploitationOffers';

/**
 * @type {WorkshopOffer['isVisible']}
 */
export const isVisible = (userUnit) => {
  return [
    ...agileMasterCoachOffers_visibleForUnits,
    ...exploitationOffers_visibleForUnits,
  ].includes(userUnit);
};

const InitiativeAgile = () => (
  <Box>
    <Box
      component={'img'}
      src={imageAgile}
      alt="INITIATIVE #LEAD / NEUES ARBEITEN"
      sx={{ width: '100%' }}
    />
    <Typography
      component="h1"
      variant="DarkBlueBoldCaps"
      sx={{ marginTop: 3 }}
      gutterBottom
    >
      Initiative agile@betrieb&schaden (exklusiv für Betrieb & Schaden)
    </Typography>
    <Typography variant="body1">
      Hier erfahren Sie Hintergründe zur Initiative agile@betrieb&schaden:
      Worauf sie abzielt, welche Schwerpunkte sie setzt und welche Angebote sie
      Ihnen für Ihre Learning Journey macht.
    </Typography>
    <GlobalLink
      text="Inititative agile@betrieb&schaden"
      to={EXPLOITATION_URL}
      active
    />
    <Typography
      component="h2"
      variant="IndexHeadlineLinkBlue"
      sx={{ marginTop: 3 }}
      gutterBottom
    >
      Extern moderierten Workshop buchen
    </Typography>
    <Typography variant="body1" gutterBottom>
      Buchen Sie hier einen Workshop im Rahmen der Initiative
      agile@betrieb&amp;schaden, der von einem oder einer externen Moderator/in
      moderiert wird.
    </Typography>
    <Button color="blue-grey" to={EXPLOITATION_OFFERS_URL}>
      Extern moderiertes Angebot
    </Button>
    <Typography
      component="h2"
      variant="IndexHeadlineLinkBlue"
      sx={{ marginTop: 3 }}
      gutterBottom
    >
      Workshop mit Agile Coach oder Agile Master der Allianz buchen
    </Typography>
    <Typography variant="body1" gutterBottom>
      Buchen Sie hier alle Workshops im Rahmen der Initiative
      agile@betrieb&amp;schaden, die von einem Agile Coach oder Agile Master der
      Allianz moderiert werden.
    </Typography>
    <Button color="blue-grey" to={AGILEMASTERCOACHOFFERS_URL}>
      Allianz-Moderiertes Angebot
    </Button>
  </Box>
);

/**
 * @type {WorkshopOffer}
 */
export const workshopOffer = { component: InitiativeAgile, isVisible };

export default InitiativeAgile;
