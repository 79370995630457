import { Typography, Stack, Link } from '@mui/material';
import React from 'react';

import { OFFER_TYPES } from '../../../../constants';
import BookingInfo from '../BookingInfo';
import ModalDescriptionText from './ModalDescriptionText';

const disableWeekends = (date) => {
  return date.getDay() === 0 || date.getDay() === 6;
};

const Description = ({
  formData,
  offerType,
  isDatesLoading,
  handleChange,
  workshopTypes,
  workshopDates,
  handleDate,
  minBookingNotice,
  locationSelector,
  handleLocationSelectorChange,
  customLocation,
}) => {
  const renderCulturizerDescription = () => (
    <>
      <Typography marginTop={3} variant="h2HeadlineGrey" gutterBottom>
        Erfahren Sie hier mehr zum Culturizer
      </Typography>
      <Stack
        marginBottom={3}
        direction="row"
        width="100%"
        justifyContent="space-around"
      >
        <Link href="https://connect.allianz.com/videos/74597" target="_blank">
          Video Culturizer Agile 6
        </Link>
        <Link href="https://connect.allianz.com/videos/74599" target="_blank">
          Video Culturizer Kulurdimensionen
        </Link>
      </Stack>
      <Typography variant="h2HeadlineGrey" gutterBottom>
        Wenn Sie die Anmeldung zum Culturizer abschließen möchten, klicken Sie
        bitte auf "Jetzt anmelden"
      </Typography>
      <Typography gutterBottom>
        Wir werden dann die oben genannten Daten an die Culturizer GmbH
        übermitteln. Von dort erhalten Sie in wenigen Minuten Zugangsdaten per
        E-Mail, um den Culturizer durchzuführen.
      </Typography>
      <Typography gutterBottom>
        Direkt nach dem Login sehen Sie auf das Team-Dashboard. Hier finden Sie
        neben{' '}
        <strong>
          den Onboarding-Videos, den FAQs und dem PDF-Leitfaden auch eine Demo
          Version des Culturizers.
        </strong>{' '}
        Wir empfehlen Ihnen,{' '}
        <strong>
          diese Informationen zum Culturizer vor Ihrer Durchführung Ihres
          Culturizers einmal durchzuarbeiten.
        </strong>
      </Typography>
      <Typography>
        Bei technischen Fragen zum Culturizer oder Problemen während der
        Durchführung setzen Sie sich bitte direkt mit dem Culturizer-Team in
        Verbindung.
      </Typography>
    </>
  );

  const renderDefaultDescription = () => (
    <>
      <div className="h2-headline-grey">
        {offerType !== OFFER_TYPES.WORKSHOP_OPEN
          ? 'Für die Planung Ihres Workshops benötigen wir noch einige Informationen'
          : 'Bitte wählen Sie untenstehend einen passenden Termin'}
      </div>
      <div className="offer-modal-content-statement paragraph">
        <ModalDescriptionText workshopTypes={workshopTypes} />
      </div>
      <BookingInfo
        onChange={handleChange}
        workshopTypes={workshopTypes}
        offerType={offerType}
        workshopDates={workshopDates}
        handleDate={handleDate}
        disableWeekends={disableWeekends}
        minBookingNotice={minBookingNotice}
        locationSelector={locationSelector}
        handleLocationSelectorChange={handleLocationSelectorChange}
        customLocation={customLocation}
        formData={formData}
        isDatesLoading={isDatesLoading}
      />
    </>
  );

  const renderDescription = () => {
    switch (offerType) {
      case OFFER_TYPES.WORKSHOP_CULTURIZER:
        return renderCulturizerDescription();
      default:
        return renderDefaultDescription();
    }
  };

  return renderDescription();
};

export default Description;
