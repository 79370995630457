import { Link as LinkMUI } from '@mui/material';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import blueNavArrow from '../../assets/icons/blue-nav-arrow.png';
import greyNavArrow from '../../assets/icons/grey-nav-arrow.png';
import { KeyCodes } from '../../constants';
import './GlobalLink.scss';

/**
 * Renders a Link.
 *
 * This components needs
 * - some better documentation,
 * - probably some refactoring to use MUI Link and reduce duplication,
 * - thorough tests to confirm it is working as intended.
 *
 * @param {String} props.target Passed through to regular html A tag
 * @param {String} props.href Rendered as an external link that opens in a window defined by {target}
 * @returns
 */

const GlobalLink = ({ active, text, isLink, onClick, to, href, target }) => {
  const getLinkStatus = (active) => {
    switch (!!active) {
      case true:
        return 'navigation-link';
      case false:
        return 'navigation-link-inactive';
      default:
        return 'navigation-link';
    }
  };

  const getLink = (active) => {
    switch (!!active) {
      case true:
        return to;
      case false:
        return '#';
      default:
        return to;
    }
  };

  const getLinkIcon = (active) => {
    switch (!!active) {
      case true:
        return blueNavArrow;
      case false:
        return greyNavArrow;
      default:
        return blueNavArrow;
    }
  };

  const openModalOnEnter = (event) => {
    if (event.keyCode === KeyCodes.ENTER) {
      onClick();
    }
  };

  if (href) {
    return (
      <LinkMUI
        className="global-link"
        href={href}
        target={target}
        underline="none"
      >
        <img className="global-link-icon" alt="" src={getLinkIcon(active)} />
        <div className={`${getLinkStatus(active)}`}>{text}</div>
      </LinkMUI>
    );
  }

  return isLink ? (
    <Link className="global-link" to={getLink(active)}>
      <img className="global-link-icon" alt="" src={getLinkIcon(active)} />
      <div className={`${getLinkStatus(active)}`}>{text}</div>
    </Link>
  ) : (
    <div
      onKeyDown={openModalOnEnter}
      tabIndex="0"
      className="global-link"
      onClick={onClick}
    >
      <img className="global-link-icon" alt="" src={getLinkIcon(active)} />
      <div className={`${getLinkStatus(active)}`}>{text}</div>
    </div>
  );
};

GlobalLink.defaultProps = {
  to: '/',
  onClick: () => {},
  isLink: true,
};

GlobalLink.propTypes = {
  onClick: PropTypes.func,
  active: PropTypes.bool,
  text: PropTypes.string.isRequired,
  to: PropTypes.string,
  href: PropTypes.string,
  target: PropTypes.string,
  isLink: PropTypes.bool,
};

export default GlobalLink;
