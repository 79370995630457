import { Box } from '@mui/material';
import { useTheme } from '@mui/styles';
import React from 'react';

export const TableCellWithError = ({
  children,
  invalid = false,
  isTouched,
  isDirty,
  error,
  ...props
}) => {
  const theme = useTheme();

  return (
    <Box
      aria-invalid={!!invalid}
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        padding: '2px',
        borderBottom: `1px solid ${theme.palette.grey[6]}`,
        '& select, input': invalid
          ? {
              color: theme.palette.common.red,
            }
          : { color: theme.palette.grey.navyGrey },
        '& path': invalid
          ? {
              fill: theme.palette.common.red,
            }
          : {
              fill: theme.palette.grey.navyGrey,
            },
      }}
      {...props}
    >
      {children}
    </Box>
  );
};

export const TextInput = React.forwardRef((props, ref) => (
  <Box
    sx={{
      fontFamily: 'AllianzNeo-Bold, Arial, Helvetica, sans-serif',
      fontWeight: '700',
      fontSize: '14px',
      border: 'none',
      outline: 'none',
      height: '100%',
      background: 'none',
    }}
    type="text"
    component="input"
    {...props}
    ref={ref}
  />
));
